/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import { useHistory } from "react-router-dom";
import Contact from "../components/Contact.js";
import { auth, socialAuth } from "../firebase";
import RatesTable from "../components/RatesTable";
//import { Link } from 'react-router-dom';

var HomeScreen = function HomeScreen() {
  var { value } = useAuth();
  var { fetchUserInfo } = value;
  var history = useHistory();

  //console.log(value);
  useEffect(
    function () {
      // console.log("Go to dashboard: " + value.loggedIn);
      // console.log("and role is : " + value.userRolef);
      //when a user is signed in this method is executed automatically
      var unsubscribe = auth.onAuthStateChanged(function (user) {
        //update the state for current user to the user logged in
        //console.log("about to set current user");
        //console.log(user);
        //var userInfo = fetchUserInfo();
        //const payload = {currentUser : user, loading: false, userInfo: userInfo}
        var signonStatus = false;
        if (user !== null) {
          signonStatus =
            user.uid !== null && user.uid !== undefined ? true : false;

          var payload = {
            ...value,
            currentUser: user,
            loading: false,
            loggedIn: signonStatus,
          };
          if (value.userInfo.email === "") {
            fetchUserDetails(payload);
            //  .then(function(res){
            //     if(!res){
            //         //console.log('Unable to fetch user data at this time');
            //     }
            // });
          }
        }
      });

      //   var unsubscribe2 = socialAuth.onAuthStateChanged(function (user) {
      //     //update the state for current user to the user logged in
      //     console.log("about to set current user google");
      //     //console.log(user);
      //     //var userInfo = fetchUserInfo();
      //     //var payload = {currentUser : user, loading: false, userInfo: userInfo}
      //     var signonStatus = false;
      //     if (user !== null) {
      //       signonStatus =
      //         user.uid !== null && user.uid !== undefined ? true : false;
      //       var payload = {
      //         ...value,
      //         currentUser: user,
      //         loading: false,
      //         loggedIn: signonStatus,
      //       };
      //       if (value.userInfo.email === "") {
      //         fetchUserDetails(payload);
      //         // .then(function(res){
      //         //     if(!res){
      //         //         //console.log('Unable to fetch user data at this time');
      //         //     }
      //         // });
      //       }
      //     }
      //   });

      if (value.loggedIn && value.userRolef === "Customer") {
        // console.log("About to customer navigate to dashboard.");
        history.push("/Dashboard");
      } else if (value.loggedIn && value.userRolef === "Staff") {
        //console.log("About to navigate to staff dashboard.");
        history.push("/AdminDashboard");
      } else if (value.loggedIn && value.userRolef === "Admin") {
        //console.log("About to navigate to staff dashboard.");
        history.push("/AdminDashboard");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value.userRolef]
  );

  var fetchUserDetails = function fetchUserDetails(payload) {
    //console.log("Is current user null");
    //console.log(payload);
    if (payload.currentUser !== null && payload.currentUser !== undefined) {
      if (
        payload.currentUser.uid !== null &&
        payload.currentUser.uid !== undefined
      ) {
        //console.log("Fetching user info");
        return fetchUserInfo(payload.currentUser.uid, payload, null);
      }
    }
    return false;
  };

  return (
    <span>
      {/* Slider Start */}
      <section className="p-0 no-transition cursor-light" id="home">
        <h2 className="d-none">hidden</h2>
        <div
          className="rev_slider_wrapper fullscreen-container"
          data-alias="megaone-agency-1"
          data-source="gallery"
          id="rev_slider_17_1_wrapper"
          style={{ background: "transparent", padding: "0px" }}
        >
          <div
            className="rev_slider fullscreenbanner"
            data-version="5.4.8.1"
            id="rev_slider_17_1"
            style={{ display: "none" }}
          >
            {/* START REVOLUTION SLIDER 5.4.8.1 fullscreen mode */}
            <ul>
              {" "}
              {/* SLIDE */}
              <li
                data-description=""
                data-easein="default"
                data-easeout="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-index="rs-43"
                data-masterspeed="default"
                data-param1=""
                data-param10=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-rotate="0"
                data-saveperformance="off"
                data-slotamount="default"
                data-title="Slide"
                data-transition="fade"
              >
                {/* OVERLAY */}
                <div className="gradient-bg1 bg-overlay"></div>

                {/* LAYER NR. 3 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-frames='[{"delay":220,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;rZ:339;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['-26','-26','83','83']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-type="image"
                  data-voffset="['98','98','60','60']"
                  data-whitespace="nowrap"
                  data-width="none"
                  data-x="['left','left','left','left']"
                  data-y="['bottom','bottom','bottom','bottom']"
                  id="slide-43-layer-5"
                  style={{ zIndex: 7 }}
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing=""
                    data-speed="5"
                    data-xe="0"
                    data-xs="0"
                    data-ye="15"
                    data-ys="-15"
                  >
                    <img
                      alt=""
                      data-hh="['87px','87px','87px','87px']"
                      data-no-retina
                      data-ww="['44px','44px','44px','44px']"
                      src="agency/img/shape-6.png"
                    />
                  </div>
                </div>

                {/* LAYER NR. 4 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-basealign="slide"
                  data-frames='[{"delay":210,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;rZ:358;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['700','700','700','530']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-type="image"
                  data-voffset="['148','148','148','148']"
                  data-whitespace="nowrap"
                  data-width="none"
                  data-x="['center','center','center','center']"
                  data-y="['middle','middle','middle','middle']"
                  id="slide-43-layer-6"
                  style={{ zIndex: 8 }}
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing="Power0.easeIn"
                    data-speed="5"
                    data-xe="0"
                    data-xs="0"
                    data-ye="20"
                    data-ys="0"
                  >
                    <img
                      alt=""
                      data-hh="['87px','87px','87px','87px']"
                      data-no-retina
                      data-ww="['24px','24px','24px','24px']"
                      src="agency/img/shape-5.png"
                    />
                  </div>
                </div>

                {/* LAYER NR. 5 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-fontsize="['20','20','20','20']"
                  data-frames='[{"delay":220,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['275','275','171','120']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-type="image"
                  data-voffset="['270','270','261','190']"
                  data-whitespace="nowrap"
                  data-width="none"
                  data-x="['center','center','center','center']"
                  data-y="['middle','middle','middle','middle']"
                  id="slide-43-layer-7"
                  style={{ zIndex: 9 }}
                >
                  <div
                    className="rs-looped rs-wave"
                    data-angle="0"
                    data-origin="50% 50%"
                    data-radius="15px"
                    data-speed="12"
                  >
                    <img
                      alt=""
                      data-hh="['67px','67px','67px','67px']"
                      data-no-retina
                      data-ww="['68px','68px','68px','68px']"
                      src="agency/img/shape-4.png"
                    />
                  </div>
                </div>

                {/* LAYER NR. 6 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-frames='[{"delay":190,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['413','413','268','204']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-type="image"
                  data-voffset="['-205','-205','-259','-145']"
                  data-whitespace="nowrap"
                  data-width="none"
                  data-x="['center','center','center','center']"
                  data-y="['middle','middle','middle','middle']"
                  id="slide-43-layer-8"
                  style={{ zIndex: 10 }}
                >
                  <div
                    className="rs-looped rs-rotate"
                    data-easing="Power0.easeIn"
                    data-enddeg="360"
                    data-origin="50% 50%"
                    data-speed="15"
                    data-startdeg="0"
                  >
                    <img
                      alt=""
                      data-hh="['69px','69px','69px','69px']"
                      data-no-retina
                      data-ww="['67px','67px','67px','67px']"
                      src="agency/img/shape-3.png"
                    />
                  </div>
                </div>

                {/* LAYER NR. 7 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-frames='[{"delay":190,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['436','436','259','145']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-type="image"
                  data-voffset="['204','204','96','73']"
                  data-whitespace="nowrap"
                  data-width="none"
                  data-x="['left','left','left','left']"
                  data-y="['top','top','top','top']"
                  id="slide-43-layer-9"
                  style={{ zIndex: 11 }}
                >
                  <div
                    className="rs-looped rs-pendulum"
                    data-easing=""
                    data-enddeg="20"
                    data-origin="50% 50%"
                    data-speed="12"
                    data-startdeg="-20"
                  >
                    <img
                      alt=""
                      data-hh="['52px','52px','52px','52px']"
                      data-no-retina
                      data-ww="['51px','51px','51px','51px']"
                      src="agency/img/shape-1.png"
                    />
                  </div>
                </div>

                {/* LAYER NR. 8 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-basealign="slide"
                  data-frames='[{"delay":170,"speed":500,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['39','39','94','58']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-type="image"
                  data-voffset="['109','109','64','69']"
                  data-whitespace="nowrap"
                  data-width="none"
                  data-x="['right','right','right','right']"
                  data-y="['top','top','top','top']"
                  id="slide-43-layer-10"
                  style={{ zIndex: 12 }}
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing=""
                    data-speed="2"
                    data-xe="15"
                    data-xs="0"
                    data-ye="0"
                    data-ys="0"
                  >
                    <img
                      alt=""
                      data-hh="['14px','14px','14px','14px']"
                      data-no-retina
                      data-ww="['50px','50px','50px','50px']"
                      src="agency/img/shape-2.png"
                    />
                  </div>
                </div>

                {/* LAYER_NR.9 */}
                <div
                  className="tp-caption tp-resizeme gradient-text1"
                  data-fontsize="['70','65','60','50']"
                  data-frames='[{"delay":660,"speed":1500,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['320','250','0','0']"
                  data-lineheight="['80','75','70','60']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['left','left','center','center']"
                  data-type="text"
                  data-voffset="['-125','-125','-125','-110']"
                  data-whitespace="nowrap"
                  data-width="['650','650','600','500']"
                  data-x="['center','center','center','center']"
                  data-y="['middle','middle','middle','middle']"
                  id="slide-43-layer-13"
                  style={{
                    zIndex: 13,
                    minWidth: "650px",
                    maxWidth: "650px",
                    whiteSpace: "nowrap",
                    fontSize: "70px",
                    lineHeight: "80px",
                    fontWeight: 800,
                    color: "#ffffff",
                    letterSpacing: "0px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Fast
                </div>

                {/* LAYER_NR._10 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-fontsize="['70','65','60','50']"
                  data-frames='[{"delay":1840,"split":"chars","splitdelay":0.1,"speed":1000,"split_direction":"forward","frame":"0","from":"sX:0.8;sY:0.8;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                  data-height="none"
                  data-hoffset="['320','250','0','0']"
                  data-lineheight="['70','75','70','60']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['left','left','center','center']"
                  data-type="text"
                  data-voffset="['-40','-40','-40','-40']"
                  data-whitespace="nowrap"
                  data-width="['650','650','600','500']"
                  data-x="['center','center','center','center']"
                  data-y="['middle','middle','middle','middle']"
                  id="slide-43-layer-11"
                  style={{
                    zIndex: 14,
                    minWidth: "650px",
                    maxWidth: "650px",
                    whiteSpace: "nowrap",
                    fontSize: "70px",
                    lineHeight: "70px",
                    fontWeight: 700,
                    color: "#ffffff",
                    letterSpacing: "0px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Shipping Agency
                </div>

                {/* LAYER_NR._11 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-fontsize="['20','20','18','17']"
                  data-frames='[{"delay":2360,"speed":1500,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['325','210','0','0']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['left','left','center','center']"
                  data-type="text"
                  data-voffset="['80','75','65','57']"
                  data-whitespace="normal"
                  data-width="['651','550','600','500']"
                  data-x="['center','center','center','center']"
                  data-y="['middle','middle','middle','middle']"
                  id="slide-43-layer-14"
                  style={{
                    zIndex: 15,
                    minWidth: "651px",
                    maxWidth: "651px",
                    whiteSpace: "normal",
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: 300,
                    color: "#ffffff",
                    letterSpacing: "0px",
                    fontFamily: "Roboto",
                  }}
                >
                  We offer package forwarding using both Air and Sea to better
                  meet the needs of you our customers.
                </div>

                {/* LAYER_NR._12 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-frames='[{"delay":2970,"speed":1500,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['325','260','0','0']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['left','left','center','center']"
                  data-type="text"
                  data-voffset="['200','186','176','156']"
                  data-whitespace="nowrap"
                  data-width="['650','650','600','500']"
                  data-x="['center','center','center','center']"
                  data-y="['middle','middle','middle','middle']"
                  id="slide-43-layer-15"
                  style={{ zIndex: 16, whiteSpace: "nowrap" }}
                >
                  <a
                    className="btn btn-slider btn-rounded btn-blue btn-hvr-white link-scroll"
                    data-toggle="modal"
                    data-target="#SignUpFormModal"
                    href="#about-us"
                  >
                    Sign Up
                    <div className="btn-hvr-setting">
                      <ul className="btn-hvr-setting-inner">
                        <li className="btn-hvr-effect"></li>
                        <li className="btn-hvr-effect"></li>
                        <li className="btn-hvr-effect"></li>
                        <li className="btn-hvr-effect"></li>
                      </ul>
                    </div>
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    className="btn btn-slider btn-rounded btn-blue btn-hvr-white link-scroll"
                    data-toggle="modal"
                    data-target="#LoginFormModal"
                    href="#about-us"
                  >
                    &nbsp;&nbsp;Login&nbsp;&nbsp;
                    <div className="btn-hvr-setting">
                      <ul className="btn-hvr-setting-inner">
                        <li className="btn-hvr-effect"></li>
                        <li className="btn-hvr-effect"></li>
                        <li className="btn-hvr-effect"></li>
                        <li className="btn-hvr-effect"></li>
                      </ul>
                    </div>
                  </a>
                </div>

                {/* LAYER_NR._13 */}
                <div
                  className="tp-caption   tp-resizeme"
                  data-frames='[{"delay":990,"speed":1500,"frame":"0","from":"x:-50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['0','0','-412','-412']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-type="image"
                  data-visibility="['on','on','off','off']"
                  data-voffset="['-1','-1','72','72']"
                  data-whitespace="nowrap"
                  data-width="none"
                  data-x="['left','left','left','left']"
                  data-y="['middle','middle','middle','middle']"
                  id="slide-43-layer-16"
                  style={{ zIndex: 17 }}
                >
                  <div
                    className="rs-looped rs-slideloop"
                    data-easing=""
                    data-speed="5"
                    data-xe="15"
                    data-xs="-10"
                    data-ye="0"
                    data-ys="0"
                  >
                    <img
                      alt=""
                      data-hh="['604px','510','510','510']"
                      data-no-retina
                      data-ww="['434px','350','350','350']"
                      src="agency/img/mgk-ad.png"
                    />
                  </div>
                </div>

                {/* LAYER_NR._14 */}
                <div
                  className="tp-caption tp-resizeme hide-cursor"
                  data-actions='[{"event":"click","action":"scrollbelow","offset":"-50px","delay":"","speed":"1200","ease":"Power3.easeInOut"}]'
                  data-fontsize="['20','20','20','17']"
                  data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-height="none"
                  data-hoffset="['0','0','0','0']"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingtop="[0,0,0,0]"
                  data-responsive_offset="on"
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-type="text"
                  data-voffset="['25','25','5','5']"
                  data-whitespace="nowrap"
                  data-width="none"
                  data-x="['center','center','center','center']"
                  data-y="['bottom','bottom','bottom','bottom']"
                  id="slide-43-layer-17"
                  style={{
                    zIndex: 18,
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: 400,
                    color: "#ffffff",
                    letterSpacing: "0px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Scroll Down{" "}
                  <i className="ml-2 fas fa-long-arrow-alt-down"></i>
                </div>
              </li>
            </ul>
            <div
              className="tp-bannertimer tp-bottom"
              style={{ visibility: "hidden !important" }}
            ></div>
          </div>
        </div>
        {/* END REVOLUTION SLIDER */}
        {/* Slider End */}
      </section>
      {/* Slider End */}
      {/* About Us */}
      <section className="pb-0" id="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow fadeInLeft">
              <div className="heading-area">
                <span className="sub-title">We are MGK Express</span>
                <h2 className="title">
                  We are making{" "}
                  <span className="alt-color js-rotating">
                    the shipping experience, the cost of shipping
                  </span>{" "}
                  better for everyone
                </h2>
                <p className="para">
                  MGK Express established in November 2020 out of the need to
                  provide fast, reliable and affordable shipping services to you
                  purchasing your favourite brands overseas. Our aim is to take
                  the hassle out of shopping online, by providing you with a US
                  address. We understand that your shipping needs may vary
                  therefore we offer both air and sea freight. Sea freight is
                  mainly geared towards our customers who are buying in bulk, or
                  purchasing larger items.
                </p>
                {/* <a className="btn btn-large btn-rounded btn-pink btn-hvr-blue mt-3" href="#">Learn
                                    More
                                    <div className="btn-hvr-setting">
                                        <ul className="btn-hvr-setting-inner">
                                            <li className="btn-hvr-effect"></li>
                                            <li className="btn-hvr-effect"></li>
                                            <li className="btn-hvr-effect"></li>
                                            <li className="btn-hvr-effect"></li>
                                        </ul>
                                    </div>
                                </a> */}
              </div>
            </div>
            <div className="col-lg-6 wow fadeInRight">
              <div className="half-img mt-5 pt-4 mt-lg-0 pt-lg-0">
                <img alt="vector" src="agency/img/aboutUs.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*About Us End*/}
      {/* Services Start */}
      {/* <section id="services">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="process-wrapp">
                                <span className="pro-step blue"><i aria-hidden="true" className="fas fa-folder-open"></i></span>
                                <h4 className="service-heading">World leader in consulting and finance</h4>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="process-wrapp">
                                <span className="pro-step midnight"><i aria-hidden="true" className="fas fa-code"></i></span>
                                <h4 className="service-heading">A focused team with a specialized skill</h4>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="process-wrapp">
                                <span className="pro-step purple"><i aria-hidden="true" className="fas fa-cog"></i></span>
                                <h4 className="service-heading">Trusted and professional advisors for you</h4>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="process-wrapp">
                                <span className="pro-step pink"><i aria-hidden="true" className="fas fa-edit"></i></span>
                                <h4 className="service-heading">Experience to give you a better results</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* Services End */}
      {/* Counters Start */}
      <section className="gradient-bg1" id="portfolio">
        <div className="container" style={{ textAlign: "center" }}>
          <div className="row">
            <div className="col-md-12">
              <div
                className="heading-area mx-570 mb-3"
                style={{ textAlign: "center" }}
              >
                <h2 className="title" style={{ color: "white" }}>
                  Our Rates
                </h2>
              </div>
            </div>
          </div>
          {/* Row */}
          <div className="row wow fadeInUp">
            <div className="col-md-12">
              <RatesTable />
            </div>
          </div>
        </div>
      </section>
      {/* Counters End */}
      {/* Team Start */}
      <section className="text-center gradient-bg2" id="team">
        <div className="container">
          {/* Row */}
          <div className="row">
            <div className="col-md-12">
              <div className="heading-area mx-570 mb-5">
                <h2 className="title" style={{ color: "white" }}>
                  Our{" "}
                  <span
                    className="alt-color js-rotating"
                    style={{ color: "#4B3CDD" }}
                  >
                    valuable, happy
                  </span>{" "}
                  customers
                </h2>
              </div>
            </div>
          </div>
          {/* Row */}
          <div className="row wow fadeInUp">
            <div className="col-md-4">
              <div className="team-item">
                {/* Team Image */}
                <img
                  alt=""
                  className="team-image"
                  src="agency/img/customer1.jpg"
                />
                {/* Name */}
                <div className="name">
                  <img alt="shape" src="agency/img/shape-9.png" />
                  <h6 style={{ color: "white" }}>Happy Customer</h6>
                </div>
                {/* Designation */}
                {/* <p className="designation mb-2"></p> */}
                {/* Team Social */}
                <div className="team-social social-icon-bg-hvr">
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <a
                    href="https://www.instagram.com/p/CJLlAPVBO9L/?utm_source=ig_web_copy_link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i
                      aria-hidden="true"
                      className="fab fa-instagram"
                      style={{ color: "white" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team-item">
                {/* Team Image */}
                <img
                  alt=""
                  className="team-image"
                  src="agency/img/customer2.jpg"
                />
                {/* Name */}
                <div className="name">
                  <img alt="shape" src="agency/img/shape-11.png" />
                  <h6 style={{ color: "white" }}>Happy Customer</h6>
                </div>
                {/* Designation */}
                {/* <p className="designation mb-2">Happy Customer</p> */}

                {/* Team Social */}
                <div className="team-social social-icon-bg-hvr">
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <a
                    href="https://www.instagram.com/p/CJL-2gUB5Wi/?utm_source=ig_web_copy_link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i
                      aria-hidden="true"
                      className="fab fa-instagram"
                      style={{ color: "white" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team-item">
                {/* Team Image */}
                <img
                  alt=""
                  className="team-image"
                  src="agency/img/customer3.jpg"
                />
                {/* Name */}
                {/* Name */}
                <div className="name">
                  <img alt="shape" src="agency/img/shape-9.png" />
                  <h6 style={{ color: "white" }}>Happy Customer</h6>
                </div>
                {/* Designation */}
                {/* <p className="designation mb-2">Happy Customer</p> */}

                {/* Team Social */}
                <div className="team-social social-icon-bg-hvr">
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <a
                    href="https://www.instagram.com/p/CJLtRVChjpG/?utm_source=ig_web_copy_link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i
                      aria-hidden="true"
                      className="fab fa-instagram"
                      style={{ color: "white" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Team End */}
      {/* Parallax Start */}
      {/* <section className="bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 wow fadeInLeft">
                            <div className="heading-area">
                                <span className="sub-title">We are megaone company</span>
                                <h2 className="title"><span className="main-color js-rotating">Robust Design, Creative Idea</span> and
                                    Development Solutions</h2>
                                <p className="para">There are many variations of passages of Lorem Ipsum available, but the majority
                                    have suffered alteration in some form, by injected humour, or randomised words which don't look
                                    even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
                                <a className="btn btn-large btn-rounded btn-blue btn-hvr-pink mt-3" href="#">Learn
                                    More
                                    <div className="btn-hvr-setting">
                                        <ul className="btn-hvr-setting-inner">
                                            <li className="btn-hvr-effect"></li>
                                            <li className="btn-hvr-effect"></li>
                                            <li className="btn-hvr-effect"></li>
                                            <li className="btn-hvr-effect"></li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInRight">
                            <div className="half-img mt-5 pt-4 mt-lg-0 pt-lg-0">
                                <img alt="" src="agency/img/vector-art-4.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* Parallax End */}
      {/* Portfolio Start */}
      {/* <section className="cube-portfolio1 text-center no-transition" id="portfolio">
                <div className="container"> */}
      {/* Row */}
      {/* <div className="row">
                        <div className="col-md-12">
                            <div className="heading-area mx-570 mb-lg-4 mb-3">
                                <span className="sub-title">We are megaone company</span>
                                <h2 className="title">We have <span className="alt-color js-rotating">designed, developed</span> some great
                                    projects</h2>
                                <p className="para">There are many variations of passages of Lorem Ipsum available, but the majority
                                    have suffered alteration in some form, by injected.</p>
                            </div>
                        </div>
                    </div> */}
      {/* Row */}
      {/* <div className="row wow fadeIn">
                        <div className="col-md-12"> */}

      {/* Portfolio Filters */}
      {/* <div className="cbp-l-filters-button" id="js-filters-mosaic-flat">

                                <div className="cbp-filter-item-active cbp-filter-item" data-filter="*">All</div>
                                <span className="text-blue">/</span>
                                <div className="cbp-filter-item" data-filter=".graphic">Graphic Design</div>
                                <span className="text-blue"> / </span>
                                <div className="cbp-filter-item" data-filter=".web-design">Web design</div>
                                <span className="text-blue"> / </span>
                                <div className="cbp-filter-item" data-filter=".graphic">SEO</div>
                                <span className="text-blue"> / </span>
                                <div className="cbp-filter-item" data-filter=".marketing">Marketing</div>
                            </div> */}

      {/* Portfolio Items */}
      {/* <div className="cbp cbp-l-grid-mosaic-flat" id="js-grid-mosaic-flat">

                                <div className="cbp-item web-design graphic">
                                    <a className="cbp-caption cbp-lightbox" href="agency/img/work-1.jpg">
                                        <div className="cbp-caption-defaultWrap">
                                            <img alt="port-1" src="agency/img/work-1.jpg" />
                                        </div>
                                        <div className="cbp-caption-activeWrap"></div>
                                        <div className="cbp-l-caption-alignCenter center-block">
                                            <div className="cbp-l-caption-body">
                                                <div className="plus"></div>
                                                <h5 className="text-white mb-1">Latest Work</h5>
                                                <p className="text-white">See Our Amazing Work</p>
                                            </div>
                                        </div>

                                    </a>
                                </div>
                                <div className="cbp-item seo marketing">
                                    <a className="cbp-caption cbp-lightbox" href="agency/img/work-2.jpg">
                                        <div className="cbp-caption-defaultWrap">
                                            <img alt="port-2" src="agency/img/work-2.jpg" />
                                        </div>
                                        <div className="cbp-caption-activeWrap"></div>
                                        <div className="cbp-l-caption-alignCenter center-block">
                                            <div className="cbp-l-caption-body">
                                                <div className="plus"></div>
                                                <h5 className="text-white mb-1">Latest Work</h5>
                                                <p className="text-white">See Our Amazing Work</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="cbp-item seo marketing">
                                    <a className="cbp-caption cbp-lightbox" href="agency/img/work-3.jpg">
                                        <div className="cbp-caption-defaultWrap">
                                            <img alt="port-4" src="agency/img/work-3.jpg" />
                                        </div>
                                        <div className="cbp-caption-activeWrap"></div>
                                        <div className="cbp-l-caption-alignCenter center-block">
                                            <div className="cbp-l-caption-body">
                                                <div className="plus"></div>
                                                <h5 className="text-white mb-1">Latest Work</h5>
                                                <p className="text-white">See Our Amazing Work</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="cbp-item graphic seo marketing">
                                    <a className="cbp-caption cbp-lightbox" href="agency/img/work-4.jpg">
                                        <div className="cbp-caption-defaultWrap">
                                            <img alt="port-3" src="agency/img/work-4.jpg" />
                                        </div>
                                        <div className="cbp-caption-activeWrap"></div>
                                        <div className="cbp-l-caption-alignCenter center-block">
                                            <div className="cbp-l-caption-body">
                                                <div className="plus"></div>
                                                <h5 className="text-white mb-1">Latest Work</h5>
                                                <p className="text-white">See Our Amazing Work</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="cbp-item web-design graphic">
                                    <a className="cbp-caption cbp-lightbox" href="agency/img/work-5.jpg">
                                        <div className="cbp-caption-defaultWrap">
                                            <img alt="port-5" src="agency/img/work-5.jpg" />
                                        </div>
                                        <div className="cbp-caption-activeWrap"></div>
                                        <div className="cbp-l-caption-alignCenter center-block">
                                            <div className="cbp-l-caption-body">
                                                <div className="plus"></div>
                                                <h5 className="text-white mb-1">Latest Work</h5>
                                                <p className="text-white">See Our Amazing Work</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="cbp-item seo marketing graphic ">
                                    <a className="cbp-caption cbp-lightbox" href="agency/img/work-6.jpg">
                                        <div className="cbp-caption-defaultWrap">
                                            <img alt="port-6" src="agency/img/work-6.jpg" />
                                        </div>
                                        <div className="cbp-caption-activeWrap"></div>
                                        <div className="cbp-l-caption-alignCenter center-block">
                                            <div className="cbp-l-caption-body">
                                                <div className="plus"></div>
                                                <h5 className="text-white mb-1">Latest Work</h5>
                                                <p className="text-white">See Our Amazing Work</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="cbp-item web-design seo">
                                    <a className="cbp-caption cbp-lightbox" href="agency/img/work-7.jpg">
                                        <div className="cbp-caption-defaultWrap">
                                            <img alt="port-7" src="agency/img/work-7.jpg" />
                                        </div>
                                        <div className="cbp-caption-activeWrap"></div>
                                        <div className="cbp-l-caption-alignCenter center-block">
                                            <div className="cbp-l-caption-body">
                                                <div className="plus"></div>
                                                <h5 className="text-white mb-1">Latest Work</h5>
                                                <p className="text-white">See Our Amazing Work</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="cbp-item web-design graphic">
                                    <a className="cbp-caption cbp-lightbox" href="agency/img/work-8.jpg">
                                        <div className="cbp-caption-defaultWrap">
                                            <img alt="port-8" src="agency/img/work-8.jpg" />
                                        </div>
                                        <div className="cbp-caption-activeWrap"></div>
                                        <div className="cbp-l-caption-alignCenter center-block">
                                            <div className="cbp-l-caption-body">
                                                <div className="plus"></div>
                                                <h5 className="text-white mb-1">Latest Work</h5>
                                                <p className="text-white">See Our Amazing Work</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div> */}
      {/* </div>
            </section> */}
      {/* Portfolio End */}
      {/* Testimonial Start */}
      {/* <section className="gradient-bg1 text-center" id="clients">
                <div className="container"> */}

      {/* Row */}
      {/* <div className="row">
                        <div className="col-md-12">
                            <div className="heading-area mx-570 mb-5">
                                <h2 className="title text-white m-0">Some <span className="js-rotating">great, ideal</span> words from
                                    our clients</h2>
                            </div>
                        </div>
                    </div> */}

      {/* Row */}
      {/* <div className="row">
                        <div className="col-md-12">
                            <div className="owl-carousel wow zoomIn" id="testimonial-slider">
                                <div className="item">
                                    <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt
                                        egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                                        ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                                    <h5 className="name gradient-text1">David Villas</h5>
                                    <span className="designation">Designer, Company Name</span>
                                    <ul className="ratings list-unstyled">
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt
                                        egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                                        ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                                    <h5 className="name gradient-text1">David Villas</h5>
                                    <span className="designation">Designer, Company Name</span>
                                    <ul className="ratings list-unstyled">
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt
                                        egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                                        ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                                    <h5 className="name gradient-text1">David Villas</h5>
                                    <span className="designation">Designer, Company Name</span>
                                    <ul className="ratings list-unstyled">
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt
                                        egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                                        ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                                    <h5 className="name gradient-text1">David Villas</h5>
                                    <span className="designation">Designer, Company Name</span>
                                    <ul className="ratings list-unstyled">
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt
                                        egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                                        ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                                    <h5 className="name gradient-text1">David Villas</h5>
                                    <span className="designation">Designer, Company Name</span>
                                    <ul className="ratings list-unstyled">
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt
                                        egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                                        ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                                    <h5 className="name gradient-text1">David Villas</h5>
                                    <span className="designation">Designer, Company Name</span>
                                    <ul className="ratings list-unstyled">
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt
                                        egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                                        ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                                    <h5 className="name gradient-text1">David Villas</h5>
                                    <span className="designation">Designer, Company Name</span>
                                    <ul className="ratings list-unstyled">
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div className="item">
                                    <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt
                                        egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                                        ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur adipiscing
                                        elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                                    <h5 className="name gradient-text1">David Villas</h5>
                                    <span className="designation">Designer, Company Name</span>
                                    <ul className="ratings list-unstyled">
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                        <li><i aria-hidden="true" className="fas fa-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}

      {/* Testimonial Thumbs */}
      {/* <div className="owl-dots" id="testimonials-avatar"> */}
      {/* data-position[top,right,bottom,left] */}
      {/* <button className="owl-dot active" data-position="22%,auto,auto,5%">
                            <img alt="" src="agency/img/avatar-1.png" /></button>
                        <button className="owl-dot active" data-position="30%,auto,auto,16%">
                            <img alt="" src="agency/img/avatar-2.png" /></button>
                        <button className="owl-dot active" data-position="auto,auto,38%,7%">
                            <img alt="" src="agency/img/avatar-3.png" /></button>
                        <button className="owl-dot active" data-position="auto,auto,23%,18%">
                            <img alt="" src="agency/img/avatar-7.png" /></button> */}
      {/* data-position[top,right,bottom,left] */}
      {/* <button className="owl-dot active" data-position="20%,19%,auto,auto">
                            <img alt="" src="agency/img/avatar-5.png" /></button>
                        <button className="owl-dot active" data-position="28%,6%,auto,auto">
                            <img alt="" src="agency/img/avatar-6.png" /></button>
                        <button className="owl-dot active" data-position="40%,15%,auto,auto">
                            <img alt="" src="agency/img/avatar-4.png" /></button>
                        <button className="owl-dot active" data-position="auto,21%,22%,auto">
                            <img alt="" src="agency/img/avatar-2.png" /></button>
                    </div>
                </div>
            </section> */}
      {/* Testimonial End */}
      {/* Contact Start */}
      <section className="contact-us" id="contact-us">
        <div className="container">
          <div className="row align-items-top">
            <div className="col-lg-5 order-lg-2 wow fadeInRight">
              <div className="contact-detail">
                <div className="contact-dots" data-dots=""></div>
                {/* Heading */}
                <div className="heading-area pb-5">
                  <h2 className="title mt-0 pb-1">Our Location</h2>
                  <p className="para">Get In Touch With Us.</p>
                </div>

                {/* Address */}
                <ul className="address list-unstyled">
                  <li>
                    <span className="address-icon gradient-text2">
                      <i
                        aria-hidden="true"
                        className="fas fa-map-marker-alt"
                      ></i>
                    </span>
                    <span className="address-text">
                      18 Dumbarton Avenue, Kingston 10
                    </span>
                  </li>
                  <li>
                    <span className="address-icon gradient-text2">
                      <i aria-hidden="true" className="fas fa-phone-volume"></i>
                    </span>
                    <span className="address-text">
                      Office #:{" "}
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        className="alt-color"
                        href="tel:8766790192"
                      >
                        876-679-0192
                      </a>
                    </span>
                  </li>
                  <li>
                    <span className="address-icon gradient-text2">
                      <i aria-hidden="true" className="fas fa-mobile"></i>
                    </span>
                    <span className="address-text">
                      Whatsapp #:{" "}
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        className="alt-color"
                        href="http://wa.me/8768021709"
                      >
                        8768021709
                      </a>{" "}
                      &nbsp;{" "}
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        className="alt-color"
                        href="http://wa.me/8762388576"
                      >
                        8762388576
                      </a>
                    </span>
                  </li>
                  <li>
                    <span className="address-icon gradient-text2">
                      <i aria-hidden="true" className="fas fa-paper-plane"></i>
                    </span>
                    <span className="address-text">
                      <a
                        className="alt-color"
                        href="mailto:info@mgkexpress.com"
                      >
                        info@mgkexpress.com
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-7 mt-4 pt-3 mt-lg-0 pt-lg-0 wow fadeInLeft">
              {/* Heading */}
              <div className="heading-area pb-2">
                <h2 className="title mt-0">Get In Touch</h2>
              </div>
              {/* Contact Form */}
              <Contact />
            </div>
          </div>
        </div>
      </section>
      {/* Contact End */}
    </span>
  );
};

export default HomeScreen;
