import PackageCardDisplay from "./PackageCardDisplay";
import { useAuth } from "../Context/AuthContext";

export default function InTransitCardDisplay() {
  var { value } = useAuth();
  var { inTransPack } = value;

  return (
    <>
      {/* <PackageCardDisplay color="dash-card" text="In Transit" number={inTransPack === undefined? "Loading" : inTransPack} icon="fas fa-plane-departure" />  */}
      <PackageCardDisplay
        color="#007bff"
        text="In Transit"
        number={inTransPack === undefined ? "Loading" : inTransPack}
        icon="fas fa-plane-departure"
        centering="InTransCardbox-content"
      />
    </>
  );
}
