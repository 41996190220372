/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/heading-has-content */
import { useEffect, useState } from "react";
//import { Alert } from "react-bootstrap";
//import { Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from "../Context/AuthContext";
//import { useHistory } from 'react-router-dom';
import ShippingAddress from "../components/ShippingAddress";
import PackageCardDisplay from "../components/PackageCardDisplay";
import ReadyCardDisplay from "../components/ReadyCardDisplay";
import InTransitcardDisplay from "../components/InTransitCardDisplay";
import DashboardPackagesTable from "../components/DashboardPackagesTable";
import { Link } from "react-router-dom";
import VerifyAccount from "../components/VerifyAccount";

var DashboardScreen = function DashboardScreen() {
  //var [ error, setError ] = useState("");
  var { value } = useAuth();
  var { userInfo, loggedIn, points, balance, calcUserRefferalPoints } = value;

  const onePointIsValued = 50;
  //currentUser,
  //fetchPackages,
  //readyPack,
  //inTransPack,
  //fetchShippersInLastSixMonths,
  //addUserInfo,
  //var history = useHistory();

  {
    /* <Card>
                <Card.Body className="text-center">
                <p>This is the dashboard</p>
                {error && <Alert variant="danger">{error}</Alert> }
                <div><strong>Email: </strong>{currentUser.email}</div>
                <Button className="btn btn-primary" onClick={() => history.push("/UpdateProfile")}>Update Profile</Button>
                </Card.Body>
                <Button variant="link" onClick={handleLogout}>Log Out</Button>
            </Card> */
  }

  // var onload = async function onload(){
  //   if(currentUser !== null){
  //     try{
  //         //console.log("What is in readyPack");
  //         //console.log(readyPack);
  //         //console.log("Fething packages");
  //         await fetchPackages(currentUser.uid, value);

  //     }catch{
  //       //console.log("unable to fetch packages at this time for user: ");
  //     }
  //   }

  // }
  useEffect(function () {
    if (!loggedIn) {
      //console.log("Dashboard LoggedIn Value" + loggedIn);
      //window.location.reload(false);
      //history.push("/");
    }

    // console.log("calling addUserInfo function");
    // addUserInfo()
    try {
      //console.log("calling fetchShippersInLast6Months function");
      //fetchShippersInLastSixMonths()
      // calcUserRefferalPoints("RL9dwIOffcZ21sIpYEulVA5xCOC3");
    } catch (err) {
      //console.log(err);
    }

    //console.log("We are  at the dashboard and user email sent is : " + userInfo.verifiedemailsent)

    //onload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper pageHeight">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark"></h1>
              </div>
              {/* col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
              {/* col */}
            </div>
            {/* row */}
          </div>
          {/* container-fluid */}
        </div>
        {/* content-header */}

        {/* Main content */}
        <section className="content">
          <div className="container-fluid dashboard-container-mobile">
            <VerifyAccount />
            {/* Info boxes */}
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3">
                <ReadyCardDisplay />
                {/* <!-- /.info-box --> */}
              </div>
              {/* info-box */}
              {/* col */}
              <div className="col-12 col-sm-6 col-md-3">
                {/* <!-- /.info-box --> */}
                <InTransitcardDisplay />
              </div>
              {/* col */}

              {/* fix for small devices only */}
              <div className="clearfix hidden-md-up"></div>

              <div className="col-12 col-sm-6 col-md-3">
                {/* <!-- /.info-box --> */}
                {/* <PackageCardDisplay color="dash-card" text="Balance" number={"$" + balance.toString()} icon="fas fa-money-check-alt" /> */}
                <PackageCardDisplay
                  color="#007bff"
                  text="Balance"
                  number={"$" + balance.toString()}
                  icon="fas fa-money-check-alt"
                />
                {/* <!-- /.info-box --> */}
              </div>
              {/* col */}
              <div className="col-12 col-sm-6 col-md-3">
                {/* <PackageCardDisplay color="dash-card" text="Points" number={balance} icon="far fa-star" /> */}
                <PackageCardDisplay
                  color="#007bff"
                  text="At Warehouse"
                  number={points}
                  icon="far fa-object-group"
                />
                {/* info-box */}
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                {/* <PackageCardDisplay color="dash-card" text="Points" number={balance} icon="far fa-star" /> */}
                <PackageCardDisplay
                  color="#007bff"
                  text="Points"
                  number={`${
                    userInfo.rPoints !== undefined ||
                    (userInfo.rPoints !== null &&
                      userInfo.rPoints !== "0" &&
                      userInfo.rPoints !== "")
                      ? `${userInfo.rPoints} : $${
                          parseInt(userInfo.rPoints) * onePointIsValued
                        }`
                      : `0 : $0`
                  }`}
                  icon="far fa-star"
                />
                {/* info-box */}
              </div>
              {/* col */}
            </div>

            {/* Main row */}
            <div className="row">
              {/* Left col */}
              <div className="col-md-8">
                {/* <!-- TABLE: LATEST ORDERS --> */}
                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Latest Orders</h3>

                    <div className="card-tools">
                      {/* <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                <i className="fas fa-minus"></i>
                              </button>
                              <button type="button" className="btn btn-tool" data-card-widget="remove">
                                <i className="fas fa-times"></i>
                              </button> */}
                    </div>
                  </div>
                  {/* <!-- /.card-header --> */}
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <DashboardPackagesTable />
                    </div>
                    {/* <!-- /.table-responsive --> */}
                  </div>
                  {/* <!-- /.card-body --> */}
                  <div className="card-footer clearfix dashboard-table-buttons-large">
                    <Link
                      to="/PreAlert"
                      style={{ backgroundColor: "#C83D73" }}
                      className="btn btn-sm btn-info float-left"
                    >
                      Create Pre Alert
                    </Link>
                    <Link
                      style={{ backgroundColor: "#007bff" }}
                      className="btn btn-sm btn-secondary float-right"
                      to="/ViewPackages"
                    >
                      View All Packages
                    </Link>
                  </div>
                  <div className="card-footer clearfix dashboard-table-buttons-small">
                    <div>
                      <Link
                        to="/PreAlert"
                        style={{ backgroundColor: "#C83D73" }}
                        className="btn btn-sm btn-info float-center"
                      >
                        Create Pre Alert
                      </Link>
                    </div>{" "}
                    <br />
                    <div>
                      <Link
                        style={{ backgroundColor: "#5E22CE" }}
                        className="btn btn-sm btn-secondary float-center"
                        to="/ViewPackages"
                      >
                        View All Packages
                      </Link>
                    </div>
                  </div>
                  {/* <!-- /.card-footer --> */}
                </div>
                {/* <!-- /.card --> */}
                {/* card */}
              </div>
              {/* <!-- /.col --> */}

              <div className="col-md-4">
                <div className="card">
                  <div className="card-header">
                    <h3
                      className="card-title"
                      style={{ color: "#007bff", fontWeight: "bold" }}
                    >
                      US Shipping Address
                    </h3>

                    <div className="card-tools">
                      {/* <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i>
                              </button>
                              <button type="button" className="btn btn-tool" data-card-widget="remove"><i className="fas fa-times"></i>
                              </button> */}
                    </div>
                  </div>
                  {/* <!-- /.card-header --> */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8"></div>
                      {/* <!-- /.col --> */}
                      <div className="col-md-12" style={{ textAlign: "left" }}>
                        <ShippingAddress />
                      </div>
                      {/* <!-- /.col --> */}
                    </div>
                    {/* <!-- /.row --> */}
                  </div>
                  {/* <!-- /.card-body --> */}
                  <div className="card-footer bg-white p-0"></div>
                  {/* <!-- /.footer --> */}
                </div>

                {/* <!-- /.card --> */}
              </div>
              {/* <!-- /.col --> */}
            </div>
            {/* <!-- /.row --> */}
          </div>
          {/* <!--/. container-fluid --> */}
        </section>
        {/* <!-- /.content --> */}
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default DashboardScreen;
