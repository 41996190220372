export default function PackageStatus({status}) {
    
    
            if(status === "Ready") 
                return (
                        <span className={"badge badge-success"}>{status}</span>
                );

            if(status === "In Transit")
                return (
                    <span className={"badge badge-warning"}>{status}</span>
                ); 

            if(status === "Processing At Customs") 
                return (
                    <span className={"badge badge-info"}>{status}</span>
                ); 
            
            if(status === "Received At Warehouse") 
                return (
                    <span className={"badge badge-primary"}>{status}</span>
                ); 
            
            if(status === "Delivered" )
                return (
                    <span className={"badge badge-danger"}>{status}</span>
                );
            
            if(status === "Courier Delivering" )
                return (
                    <span className={"badge badge-light text-dark"}>{status}</span>
                );
            
            if(status === "Held By Customs" )
                return (
                    <span className={"badge badge-dark"}>{status}</span>
                );
            
            if(status === "Invoice Uploaded" )
                return (
                    <span className={"badge badge-secondary"}>{status}</span>
                );

            if(status === "" || null )
                return (
                    <span className={"badge badge-danger"}>Loading</span>
                );

        
}
