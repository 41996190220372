import React, { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";

//import provider
import AuthProvider from "../Context/AuthContext";
//Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import PrivateRoute from "../components/PrivateRoute";

//Screens
import HomeScreen from "../screens/homeScreen";
import dashboardScreen from "../screens/DashboardScreen";
import staffDashboardScreen from "../screens/StaffDashboardScreen";
import AdminDashboardScreen from "../screens/AdminDashboardScreen";
import updateProfileScreen from "../screens/UpdateProfileScreen";
import RatesScreen from "../screens/RatesScreen";
import UpdateRatesScreen from "../screens/UpdateRatesScreen";
//import GenarateNumbers from '../screens/GenarateNumbers';
import ViewPackagesScreen from "../screens/ViewPackagesScreen";
import AddPackageScreen from "../screens/AddPackageScreen";
//import AddOrUploadPackageScreen from "../screens/AddOrUploadPackageScreen";
import PackageSearchScreen from "../screens/PackageSearchScreen";
import ContactScreen from "../screens/ContactScreen";
//import PreAlertScreen from "../screens/PreAlertScreen";
import FindPackageScreen from "../screens/FindPackageScreen";
//import EditPackageScreen from "../screens/EditPackageScreen";
import EditPackageFromRangeSearch from "../screens/EditPackageFromRangeSearch";
import LaunchEmailScreen from "../screens/LaunchEmailScreen";
//import ScheduleDeliveryScreen from "../screens/ScheduleDeliveryScreen";
import ViewUsersScreen from "../screens/ViewUsersScreen";
import ConsistentUsersScreen from "../screens/ConsistentUsersScreen";
import UploadInvoiceScreen from "../screens/UploadInvoiceScreen";
import FindUserScreen from "../screens/FindUserScreen";
//import ReferralSignUp from "../screens/ReferralSignUp";
import ManageStaffScreen from "../screens/ManageStaffScreen";
import Spinner from "./Spinner";
//const AuthProvider = React.lazy(() => import('../Context/AuthContext'));
const AddOrUploadPackageScreen = React.lazy(() =>
  import("../screens/AddOrUploadPackageScreen")
);
const EditPackageScreen = React.lazy(() =>
  import("../screens/EditPackageScreen")
);
const ReferralSignUp = React.lazy(() => import("../screens/ReferralSignUp"));
const ScheduleDeliveryScreen = React.lazy(() =>
  import("../screens/ScheduleDeliveryScreen")
);
const PreAlertScreen = React.lazy(() => import("../screens/PreAlertScreen"));
// const AuthProvider = React.lazy(() => import(''));
// const AuthProvider = React.lazy(() => import(''));
// const AuthProvider = React.lazy(() => import(''));

var App = function App() {
  return (
    <div className="">
      <BrowserRouter>
        <div>
          <Header />
          <Route path="/" exact component={HomeScreen} />
          <Route path="/ReferralSignUp/:id" exact component={ReferralSignUp} />
          <PrivateRoute path="/PreAlert" exact component={PreAlertScreen} />
          {/* <Route path="/" exact component={GenarateNumbers} /> */}
          <PrivateRoute path="/Dashboard" exact component={dashboardScreen} />
          <PrivateRoute
            path="/UpdateProfile"
            exact
            component={updateProfileScreen}
          />
          <PrivateRoute path="/Rates" exact component={RatesScreen} />
          <PrivateRoute
            path="/ViewPackages"
            exact
            component={ViewPackagesScreen}
          />
          <PrivateRoute
            path="/StaffDashboard"
            exact
            component={staffDashboardScreen}
          />
          <PrivateRoute path="/AddPackage" exact component={AddPackageScreen} />
          <PrivateRoute
            path="/AddOrUploadPackages"
            exact
            component={AddOrUploadPackageScreen}
          />
          <PrivateRoute
            path="/PackageSearch"
            exact
            component={PackageSearchScreen}
          />
          <PrivateRoute path="/Contact" exact component={ContactScreen} />
          <PrivateRoute
            path="/FindAPackage"
            exact
            component={FindPackageScreen}
          />
          <PrivateRoute
            path="/EditPackageStaff/:id"
            component={EditPackageScreen}
          />
          <PrivateRoute
            path="/EditPackageFromRangeSearch/:id"
            component={EditPackageFromRangeSearch}
          />
          <PrivateRoute
            path="/LaunchEmails"
            exact
            component={LaunchEmailScreen}
          />
          <PrivateRoute
            path="/ScheduleDelivery"
            exact
            component={ScheduleDeliveryScreen}
          />
          <PrivateRoute
            path="/AdminDashboard"
            exact
            component={AdminDashboardScreen}
          />
          <PrivateRoute path="/FindUser" exact component={FindUserScreen} />
          <PrivateRoute path="/ViewUsers" exact component={ViewUsersScreen} />
          <PrivateRoute
            path="/ConsistentUsers"
            exact
            component={ConsistentUsersScreen}
          />
          <PrivateRoute
            path="/StaffMembers"
            exact
            component={ManageStaffScreen}
          />
          <PrivateRoute
            path="/UpdateRates"
            exact
            component={UpdateRatesScreen}
          />
          <PrivateRoute
            path="/UploadInvoice/:id"
            exact
            component={UploadInvoiceScreen}
          />
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return (
    <Suspense fallback={<Spinner />}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Suspense>
  );
}
