import { useAuth } from '../Context/AuthContext';



var Footer = function Footer() {
    //declare context variables
    var { value } = useAuth();
    if (!value.loggedIn) {
        return (
            <span>
                {/* Footer Start */}
                <footer className="footer-style-1 bg-light">
                    <div className="container">
                        <div className="row align-items-center">
                            {/* Social */}
                            <div className="col-md-6">
                                <div className="footer-social">
                                    <ul className="list-unstyled">
                                        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                        <li><a className="wow fadeInUp" href="https://www.facebook.com/themgkexpress" rel="noreferrer" target="_blank"><i aria-hidden="true"
                                                                                                className="fab fa-facebook-f"></i></a></li>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                        <li><a className="wow fadeInDown" href="https://twitter.com/themgkexpress" rel="noreferrer" target="_blank"><i aria-hidden="true"
                                                                                                    className="fab fa-twitter"></i></a></li>
                                        {/* <li><a className="wow fadeInDown" href="#" target="_blank"><i aria-hidden="true"
                                                                                                    className="fab fa-linkedin-in"></i></a></li> */}
                                        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                        <li><a className="wow fadeInUp" href="https://www.instagram.com/themgkexpress/" rel="noreferrer" target="_blank"><i aria-hidden="true"
                                                                                                className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* Text */}
                            <div className="col-md-6 text-md-right">
                                <p className="company-about fadeIn">© 2021 MGK EXPRESS.
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* Footer End */}
            </span>
        );
    }else{
        return (
            <div className="footer-location">
                <aside className="control-sidebar control-sidebar-dark" >
                
                </aside>

                <footer className="main-footer gradient-bg1 ">
                    <div className="d-none d-sm-inline-block" style={{ position: "relative", paddingLeft: "27%", color:"white"}}>
                        <strong>Copyright &copy; MGK Express.  </strong>
                        All rights reserved.
                    </div>
                </footer>
            </ div>
        )
    }
}


export default Footer;