/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import Moment from "moment";
import { useAuth } from "../Context/AuthContext";
// Re-export with a default theme
//import { makeStyles } from '@material-ui/core/styles';
import { makeStyles, createStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: "0% 0px 0% 0px",
      borderRadius: "22px",
      "& .MuiInputBase-root": {
        color: "#9B9B9B ",
        borderColor: "#888888",
        border: "3px solid black",
      },
      "& .MuiSelect-select:$focus": {
        backgroundColor: "inherit",
        color: "black",
      },
      "& .MuiSelect-select": {
        border: "2px dotted black",
      },
      "& .MuiFormLabel-root": {
        fontWeight: 700,
        fontSize: "1.2rem",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#9B9B9B",
      },
      "& .MuiPaper-root": {
        width: "100%",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      marginLeft: "0px",
    },
  })
);

export default function CustomDatatable({
  users,
  columns,
  isManageStaff,
  isManageCustomers,
  setuserRes,
  count,
}) {
  var { value } = useAuth();
  const classes = useStyles();
  var { userRolef } = value;

  var history = useHistory();
  var location = history.location;
  var referralPath = location.pathname;
  // var [datatable, setDatatable] = useState({
  //     columns: [
  //       {
  //           label: 'MailBox #',
  //           field: 'MailBox_Num',
  //           width: 100,
  //       },{
  //         label: 'Email',
  //         field: 'Email',
  //         width: 250,
  //         attributes: {
  //           'aria-controls': 'DataTable',
  //           'aria-label': 'Name',
  //         },
  //       },{
  //         label: 'Name',
  //         field: 'Name',
  //         width: 200,
  //         attributes: {
  //           'aria-controls': 'DataTable',
  //           'aria-label': 'Name',
  //         },
  //       },
  //       {
  //         label: 'Address',
  //         field: 'Address',
  //         width: 200,
  //       },
  //       {
  //         label: 'Contact #',
  //         field: 'Contact_Number',
  //         width: 100,
  //       },
  //     ],
  //     rows: [],
  //   });

  const [rows, setRows] = useState([]);

  const fetchUserClick = function (e) {
    let index = e.target.id !== "newuser" ? parseInt(e.target.id) : "newuser";
    if (index !== null && index !== undefined && index !== "newuser") {
      //console.log("there is an index");
      //console.log(users[index]);
      // console.log(users);
      let user = users[index];
      user.index = index;
      setuserRes(user);
    } else if (index === "newuser") {
      let user = {
        Position: "Select Role",
        addressLine1: "",
        addressLine2: "",
        city: "",
        contactNumber: "",
        dateCreated: "",
        email: "",
        fullName: "",
        index: -1,
        postalCode: "",
        stateOrparish: "",
        uid: "",
        verified: true,
        verifiedemailsent: true,
      };

      setuserRes(user);
    }
  };

  useEffect(
    function () {
      try {
        // console.log("page load again");
        if (users !== null && users !== undefined && users.length > 0) {
          var newrows = [];
          //console.log("Whats inside packages");
          //console.log(packages);
          var numRows = users.length;
          if (!isManageStaff) {
            for (var i = 0; i < numRows; ) {
              //console.log("test res", users[i].dateCreated);

              var address =
                users[i].addressLine1 !== null &&
                users[i].addressLine1 !== undefined
                  ? users[i].addressLine1
                  : "";
              address =
                users[i].addressLine2 !== null &&
                users[i].addressLine2 !== undefined
                  ? address + "," + users[i].addressLine2
                  : address;
              address =
                users[i].city !== null && users[i].city !== undefined
                  ? address + "," + users[i].city
                  : address;
              address =
                users[i].stateOrparish !== null &&
                users[i].stateOrparish !== undefined
                  ? address + "," + users[i].stateOrparish
                  : address;
              address =
                users[i].postalCode !== null &&
                users[i].postalCode !== undefined
                  ? address + "," + users[i].postalCode
                  : address;

              let timestamp = users[i].dateCreated;
              var newrow = {
                Email: users[i].email,
                Name: users[i].fullName,
                Update: (
                  <>
                    <a
                      href={referralPath}
                      data-toggle="modal"
                      onClick={(e) => fetchUserClick(e)}
                      data-target="#ViewUserDetailsModal"
                      id={i}
                      name={i}
                    >
                      <i
                        id={i}
                        name={i}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit User"
                        style={{
                          color: "#3264f5",
                          cursor: "pointer",
                          marginRight: "3%",
                          fontSize: "25px",
                        }}
                        className="fas fa-user"
                      ></i>
                    </a>
                  </>
                ),
                Address: address,
                Contact_Number: users[i].contactNumber,
                MailBox_Num:
                  users[i].mailbox_Num !== null &&
                  users[i].mailbox_Num !== undefined
                    ? users[i].mailbox_Num
                    : "",
                dateCreated:
                  timestamp !== null && timestamp !== undefined
                    ? Moment(timestamp.toDate()).format("YYYY-MM-DD HH:MM:SS")
                    : "",
                rPoints:
                  users[i].rPoints !== undefined && users[i].rPoints !== null
                    ? users[i].rPoints
                    : "0",
              };

              newrows.push(newrow);
              // if (newrow.Email == "jammeljohnson@yahoo.com") {
              //   console.log(newrow.Address);
              // }
              i = i + 1;
            }
            // setDatatable({...datatable, rows: newrows})
          } else {
            for (var r = 0; r < numRows; ) {
              //console.log(r);
              var newrow2 = {
                Email: users[r].email,
                Name: users[r].fullName,
                Update: (
                  <>
                    <a
                      href={referralPath}
                      data-toggle="modal"
                      onClick={(e) => fetchUserClick(e)}
                      data-target="#ViewUserDetailsModal"
                      id={r}
                      name={r}
                    >
                      <i
                        id={r}
                        name={r}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit User"
                        style={{
                          color: "#3264f5",
                          cursor: "pointer",
                          marginRight: "3%",
                          fontSize: "25px",
                        }}
                        className="fas fa-user"
                      ></i>
                    </a>
                  </>
                ),
                Contact_Number: users[r].contactNumber,
                Position:
                  users[r].Position !== null && users[r].Position !== undefined
                    ? users[r].Position.toUpperCase()
                    : "",
                dateCreated:
                  users[r].dateCreated !== null &&
                  users[r].dateCreated !== undefined
                    ? users[r].dateCreated
                    : "",
              };

              newrows.push(newrow2);
              r = r + 1;
            }
            // setDatatable({...datatable, rows: newrows})
          }

          setRows(newrows);
          //console.log(rows)
        }
      } catch (err) {
        //console.log(err)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users, count]
  );

  //console.log("Datatable is?")
  //console.log(datatable);

  const options = {
    filterType: "dropdown",
    search: true,
    selectableRows: "none",
    download: userRolef === "Admin" ? true : false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: false,
      },
    },
    print: userRolef === "Admin" ? true : false,
    sortOrder: {
      name: "dateCreated",
      direction: "desc",
    },
  };

  if (users != null) {
    return (
      <div className="card-body p-0">
        <div
          className={clsx("row viewPackage-div-style", classes.root)}
          style={{ color: "black" }}
        >
          {/* <MDBDataTableV5
                                scrollX 
                                hover
                                striped
                                entriesOptions={[10,20,30]}
                                entries={10}
                                pagesAmount={4}
                                data={datatable}
                                pagingTop
                                searchTop
                                searchBottom={false}
                                className="table-responsive"
                                fullPagination
                                materialSearch  
                                exportToCSV 
                            /> */}

          <MUIDataTable
            title={
              //|| isManageCustomers
              isManageStaff ? (
                <>
                  <button
                    type="button"
                    data-toggle="modal"
                    onClick={(e) => fetchUserClick(e)}
                    data-target="#ViewUserDetailsModal"
                    id="newuser"
                    name="newuser"
                    className="btn btn-large btn-rounded btn-purple btn-hvr-pink modal_contact_btn ml-2 mr-2"
                  >
                    {!isManageCustomers ? "Add New Member" : "Add New Customer"}
                    <div className="btn-hvr-setting">
                      <ul className="btn-hvr-setting-inner">
                        <li className="btn-hvr-effect"></li>
                        <li className="btn-hvr-effect"></li>
                        <li className="btn-hvr-effect"></li>
                        <li className="btn-hvr-effect"></li>
                      </ul>
                    </div>
                  </button>
                </>
              ) : (
                <></>
              )
            }
            data={rows}
            columns={columns}
            options={options}
          />
          {/* <!-- /.table-responsive --> */}
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{ color: "black", textAlign: "center", width: "10% !important" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
