export default function PackageCardDisplay({icon, number, text, color, centering}) {
  //console.log("color is: " + color);
  var classes = centering !== null && centering !== undefined ? centering: ""
  if(text === "Balance"){
    classes = "BalanceCardbox-content";
  }else if(text ===  "Points"){
    classes = "PointsCardbox-content";
  }else if(text ===  "At Warehouse"){
    classes = "PointsCardbox-content";
  }
  
  if(color.includes("#")){
    return (
      <div className={"info-box mb-3 "} style={{backgroundColor: color, color: "white"}}>
          <span className="info-box-icon"><i className={icon}></i></span>
          <div className={`info-box-content ${classes}`}>
            <span className="info-box-text">{text}</span>
            <span className="info-box-number">{number}</span>
          </div>
          {/* <!-- /.info-box-content --> */}
      </div>
    )
  }else if(text === "At Warehouse"){
    return (
      <div className={"info-box mb-3 " + color}>
          <span className="info-box-icon"><i className={icon}></i></span>
          <div className={`info-box-content ${classes}`}>
            <span className="info-box-text">{text}</span>
            <span className="info-box-number">{number}</span>
          </div>
          {/* <!-- /.info-box-content --> */}
      </div>
    )
  }else{
    return (
      <div className={"info-box mb-3 " + color}>
          <span className="info-box-icon"><i className={icon}></i></span>
          <div className={`info-box-content ${classes}`}>
            <span className="info-box-text">{text}</span>
            <span className="info-box-number">{number}</span>
          </div>
          {/* <!-- /.info-box-content --> */}
      </div>
    )
  }
    
}
