/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
import { Alert, Card } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import Feild from "../components/Feild";
import { useHistory } from "react-router-dom";
import Select from "react-dropdown-select";

import FindUserResult from "../components/FindUserResult";
import FindUserForm from "../components/FindUserForm";

export default function FindUserScreen() {
  //Initialize state
  var { value } = useAuth();
  var { checkIfPackageExistStaff, users, clearPackageResultStaff, findUser } =
    value;
  var [error, setError] = useState("");
  var [success, setSuccess] = useState("");
  var [loading, setLoading] = useState(false);
  var ddl1 = useRef([]);

  //var history = useHistory();

  if (users != null) {
    return (
      <div className="content-wrapper" style={{ minHeight: "500px" }}>
        <section className="content" style={{ height: "151vh" }}>
          <div
            className="container-fluid"
            style={{ marginTop: "5%", marginBottom: "5%" }}
          >
            <div className="card-body p-0">
              <div className="row">
                <Card className="update-form-style">
                  <Card.Body>
                    <h2
                      className="text-center mb-4"
                      style={{ color: "#007bff" }}
                    >
                      Find User
                    </h2>
                    <FindUserForm />
                    <div>
                      <br />
                      <FindUserResult users={users} />
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className="content-wrapper" style={{ minHeight: "500px" }}>
        <section className="content" style={{ height: "151vh" }}>
          <div
            className="container-fluid"
            style={{ marginTop: "5%", marginBottom: "5%" }}
          >
            <div className="card-body p-0">
              <div className="row">
                <Card className="update-form-style">
                  <Card.Body>
                    <h2
                      className="text-center mb-4"
                      style={{ color: "#007bff" }}
                    >
                      Find User
                    </h2>
                    <FindUserForm />
                    <FindUserResult />
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
