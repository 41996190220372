import { useState, useEffect } from 'react';
import { useAuth } from '../Context/AuthContext';
import { Alert } from 'react-bootstrap';

export default function VerifyAccount() {
    var { value }  = useAuth();
    var { userInfo,sendCostomerVerificationEmail, mailbox_Num } = value;
    var [verified, setVerified] = useState(userInfo.verified === false ? 'Please check your email inbox, spam and junk to verify account': null);

    var onload = async function onload(){
      //console.log("What the hell is in value");
      //console.log(value);
        if(value.userInfo.verfied) {
            setVerified('');
        }
        
        //console.log("Email was sent? " + value.userInfo.verifiedemailsent);
        //console.log("User name is: " + value.userInfo.fullName);
        if(value.userInfo.verifiedemailsent !== true && userInfo.fullName != null && userInfo.fullName !== undefined){
          //console.log("About to send verification email.")
          if(mailbox_Num !== null && mailbox_Num !== undefined && mailbox_Num !== ""){
            await sendCostomerVerificationEmail(userInfo.email,  value.userInfo.fullName, value);
          }
          
        }
        
      }
      useEffect(function(){
          onload();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [mailbox_Num])
    return (
        <>
           {verified && <Alert variant="danger">{verified}</Alert> }
           {/* <Alert variant="danger">
              <h5 style={{color: "#FFF", fontWeight: "900"}}>
                A New Air Address was added to your Dashboard. 
                Please update address on your Ecommerce 
                Websites(Amazon, Ebay, Shein Etc...) before shipping.
              </h5>
            </Alert>  */}
        </>
    )
}
