import Contact from "../components/Contact.js";

export default function ContactScreen() {
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div
        data-testid="contact-screen"
        className="content-wrapper"
        style={{ height: "100vh" }}
      >
        {/* Contact Start */}
        <section className="contact-us" id="contact-us">
          <div className="container">
            <div className="row align-items-top">
              <div className="col-lg-5 order-lg-2 wow fadeInRight">
                <div className="contact-detail">
                  <div className="contact-dots" data-dots=""></div>
                  {/* Heading */}
                  <div className="heading-area pb-5">
                    <h2 className="title mt-0 pb-1">Our Location</h2>
                    <p className="para">Get In Touch With Us.</p>
                  </div>

                  {/* Address */}
                  <ul className="address list-unstyled">
                    <li>
                      <span className="address-icon gradient-text2">
                        <i
                          aria-hidden="true"
                          className="fas fa-map-marker-alt"
                        ></i>
                      </span>
                      <span className="address-text">
                        18 Dumbarton Avenue, Kingston 10
                      </span>
                    </li>
                    <li>
                      <span className="address-icon gradient-text2">
                        <i
                          aria-hidden="true"
                          className="fas fa-phone-volume"
                        ></i>
                      </span>
                      <span className="address-text">
                        Office #:{" "}
                        <a
                          target={"_blank"}
                          rel="noreferrer"
                          className="alt-color"
                          href="tel:8766790192"
                        >
                          876-679-0192
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="address-icon gradient-text2">
                        <i aria-hidden="true" className="fas fa-mobile"></i>
                      </span>
                      <span className="address-text">
                        Whatsapp #:{" "}
                        <a
                          target={"_blank"}
                          rel="noreferrer"
                          className="alt-color"
                          href="http://wa.me/8768021709"
                        >
                          8768021709
                        </a>{" "}
                        &nbsp;{" "}
                        <a
                          target={"_blank"}
                          rel="noreferrer"
                          className="alt-color"
                          href="http://wa.me/8762388576"
                        >
                          8762388576
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="address-icon gradient-text2">
                        <i
                          aria-hidden="true"
                          className="fas fa-paper-plane"
                        ></i>
                      </span>
                      <span className="address-text">
                        <a
                          target={"_blank"}
                          rel="noreferrer"
                          className="mr-3 alt-color"
                          href="mailto:info@mgkexpress.com"
                        >
                          info@mgkexpress.com
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-7 mt-4 pt-3 mt-lg-0 pt-lg-0 wow fadeInLeft">
                {/* Heading */}
                <div className="heading-area pb-2">
                  <h2 className="title mt-0">Get In Touch</h2>
                </div>
                {/* Contact Form */}
                <Contact />
              </div>
            </div>
          </div>
        </section>
        {/* Contact End */}
      </div>
    </>
  );
}
