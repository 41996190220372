/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";
//import { useHistory } from 'react-router-dom';

var ForgotPassword = function ForgotPassword() {
  var emailRef = useRef();
  var { value } = useAuth();
  var { resetPassword } = value;
  var { currentUser } = value;
  var [error, setError] = useState("");
  var [message, setMessage] = useState("");
  var [loading, setLoading] = useState(false);
  //var history = useHistory();

  var handleSubmit = async function handleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside password reset fuction");
    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value).then(function (res) {
        setMessage(
          "Check your email inbox/spam folder for further instructions."
        );
      });
    } catch {
      setError("Failed to reset password");
    }
    setLoading(false);
  };

  var switchModal = function switchModal() {
    try {
      //console.log("switchModdal")
      window.$("#FPasswordFormModal").modal("hide");
      window.$("body").removeClass("modal-open");
      window.$(".modal-backdrop").remove();
      window.$("#LoginFormModal").modal("show");
    } catch {
      //console.log("failed to switch to login modal");
    }
  };

  // var switchFPasswordModal = () => {
  //     try{
  //         //console.log("switchModdal")
  //         window.$('#LoginFormModal').modal('hide');
  //         window.$('body').removeClass('modal-open');
  //         window.$('.modal-backdrop').remove();
  //         window.$('#FPasswordFormModal').modal('show');
  //     }catch {
  //         console.log("failed to switch to login modal");
  //     }

  // }

  // var closeModal = () => {
  //     try{
  //         //console.log("switchModdal")
  //         window.$('#LoginFormModal').modal('hide');
  //         window.$('body').removeClass('modal-open');
  //         window.$('.modal-backdrop').remove();
  //     }catch {
  //         console.log("failed to switch to close login modal");
  //     }

  // }

  //console.log(currentUser)
  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success">{message}</Alert>}
      <form
        onSubmit={handleSubmit}
        className="contact-form text-center"
        id="modal-contact-form-data5"
      >
        <div className="form-group" id="email">
          <input
            type="email"
            name="email"
            placeholder="Enter Email Address"
            ref={emailRef}
            className="form-control"
            required
          />
        </div>
        <p className="w-100 text-center mt-2 form-link">
          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          Already have an account? <a onClick={switchModal}>Login</a>
        </p>
        <button
          disabled={loading}
          className="btn btn-large btn-rounded btn-blue btn-hvr-pink modal_contact_btn text-center"
          id="forgotpassword_submit_btn"
          type="submit"
        >
          Reset Password
          <div className="btn-hvr-setting">
            <ul className="btn-hvr-setting-inner">
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
            </ul>
          </div>
        </button>
      </form>
    </>
  );
};

export default ForgotPassword;
