import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  spinner: {
    paddingTop: 0,
    paddingBottom: 0,
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function Spinner() {
  const classes = useStyles();
  //@typescript-eslint/no-unused-vars
  const [open] = React.useState(true);

  const handleClose = () => {
    //setOpen3(false);
  };
  return (
    <div className={classes.spinner}>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
