/* eslint-disable no-unused-vars */

import { Component, Fragment } from "react";
//import "./App.css";
import "react-dates/initialize";
import moment from 'moment';
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import MediaQuery  from "react-responsive";
import isInclusivelyAfterDay from './isInclusivelyAfterDay';
import {
    START_DATE,
    END_DATE,
    HORIZONTAL_ORIENTATION,
    ANCHOR_LEFT,
    NAV_POSITION_TOP,
  } from 'react-dates/lib/constants';
  import momentPropTypes from 'react-moment-proptypes';
  import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { DateRangePickerPhrases } from 'react-dates/lib/defaultPhrases';
import DateRangePickerShape from 'react-dates/src/shapes/DateRangePickerShape';

const propTypes = {
    // example props for the demo
    autoFocus: PropTypes.bool,
    autoFocusEndDate: PropTypes.bool,
    stateDateWrapper: PropTypes.func,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,
  
    ...omit(DateRangePickerShape, [
      'startDate',
      'endDate',
      'onDatesChange',
      'focusedInput',
      'onFocusChange',
    ]),

    onInputChange5: PropTypes.func,
  };
  
  const defaultProps = {
    // example props for the demo
    autoFocus: false,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,
  
    // input related props
    startDateId: START_DATE,
    startDatePlaceholderText: 'Start Date',
    endDateId: END_DATE,
    endDatePlaceholderText: 'End Date',
    disabled: false,
    required: false,
    screenReaderInputMessage: '',
    showClearDates: false,
    showDefaultInputIcon: false,
    customInputIcon: null,
    customArrowIcon: null,
    customCloseIcon: null,
    block: false,
    small: false,
    regular: false,
  
    // calendar presentation and interaction related props
    renderMonthText: null,
    orientation: HORIZONTAL_ORIENTATION,
    anchorDirection: ANCHOR_LEFT,
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDates: false,
    isRTL: false,
  
    // navigation related props
    navPosition: NAV_POSITION_TOP,
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {},
    onNextMonthClick() {},
    onClose() {},
  
    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 1,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
    isDayHighlighted: () => false,
  
    // internationalization
    displayFormat: () => moment.localeData().longDateFormat('L'),
    monthFormat: 'MMMM YYYY',
    phrases: DateRangePickerPhrases,
  
    stateDateWrapper: date => date,
    onInputChange5: (date1, date2) => undefined,
  };
  
class DateRangePickerWrapper extends Component {
    constructor(props) {
        super(props);
    
        let focusedInput = null;
        if (props.autoFocus) {
          focusedInput = START_DATE;
        } else if (props.autoFocusEndDate) {
          focusedInput = END_DATE;
        }
    
        this.state = {
          focusedInput,
          startDate: props.initialStartDate,
          endDate: props.initialEndDate,
        };
    
        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
      }

      onDatesChange({ startDate, endDate }) {
        const { stateDateWrapper, onInputChange5 } = this.props;
        var formattedStart = startDate !== null? startDate.format("Y-MM-D") : endDate;
        var formattedEnd = endDate !== null? endDate.format("Y-MM-D") : endDate
        console.log(endDate);
        onInputChange5(formattedStart, formattedEnd);
        this.setState({
          startDate: startDate && stateDateWrapper(startDate),
          endDate: endDate && stateDateWrapper(endDate),
        });
      }

    
      onFocusChange(focusedInput) {
        this.setState({ focusedInput });
      }

  isToolate(day) {
    var hour = this.props.getCurrentDate();
    if(hour >= 14){
      return !isInclusivelyAfterDay(day, moment().add('days', 1));
    }else{
      return !isInclusivelyAfterDay(day, moment());
    }
    
  }
  

  
  render() {
    const { focusedInput, startDate, endDate } = this.state;

    // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
    // example wrapper but are not props on the SingleDatePicker itself and
    // thus, have to be omitted.
    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'stateDateWrapper',
      'onInputChange5',
    ]);
    return (
      <Fragment>
        <Mobile>
          <DateRangePicker
            {...props}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            focusedInput={focusedInput}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            numberOfMonths={1}
            block={true}
            hideKeyboardShortcutsPanel={true}
            required={true}
            isDayBlocked={() => false}
            isOutsideRange={() => false}
            //orientation="HORIZONTAL_ORIENTATION"
            //openDirection= "open_down"
            //placeholder={this.state.placeholder}
          />
        </Mobile>

        <Default>
          <DateRangePicker
            {...props}
            onDatesChange={this.onDatesChange}
            onFocusChange={this.onFocusChange}
            focusedInput={focusedInput}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            numberOfMonths={2}
            block={true}
            hideKeyboardShortcutsPanel={true}
            required={true}
            isDayBlocked={() => false}
            isOutsideRange={() => false}
            //orientation="HORIZONTAL_ORIENTATION"
            //openDirection= "open_down"
            //placeholder={this.state.placeholder}
          />
        </Default>
      </Fragment>
    );
  }
}

DateRangePickerWrapper.propTypes = propTypes;
DateRangePickerWrapper.defaultProps = defaultProps;

export default DateRangePickerWrapper;

export var Mobile = function Mobile(props){ return <MediaQuery  {...props} maxWidth={767} />};
export var Default = function (props){ return <MediaQuery  {...props} minWidth={768} />};
//export var Retina = function (props){ return <MediaQuery  {...props} minResolution="2dppx" />};