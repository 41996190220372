/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useAuth } from '../Context/AuthContext';
import { Link } from 'react-router-dom';
import Feild from '../components/Feild';
import { useHistory } from 'react-router-dom';

var initialValues = { email: "",
                     password: "", passwordconf: "",
                    contact: "", fullname: "",
                    address_line_1: "", address_line_2: "",
                    city: "", state_or_parish: "", postal_code: "" };
var UpdateProfileScreen = function UpdateProfileScreen(){
    //Initialize state
    var { value }  = useAuth();
    var { currentUser, userInfo, updateUserInfo } = value;
    var [error, setError] = useState('');
    var [success, setSuccess] = useState('');
    var [loading, setLoading] = useState(false);
    initialValues.email = userInfo.email;
    initialValues.fullname = userInfo.fullName;
    initialValues.contact = userInfo.contactNumber;
    initialValues.address_line_1 = userInfo.addressLine1;
    initialValues.address_line_2 = userInfo.addressLine2;
    initialValues.city = userInfo.city;
    initialValues.postal_code = userInfo.postalCode;
    initialValues.state_or_parish = userInfo.stateOrparish;
    var history = useHistory();

    var [state, setState] = useState(initialValues);

    var handleSubmit = async function handleSubmit(event){
        event.preventDefault();
        //prevents default form refresh
        //console.log("I am inside fuction");
        // if(state.password !== state.passwordconf){
        //     return setError('Passwords do not match');
        // }
        try{
            setError('');
            setSuccess('');
            setLoading(true);
            await updateUserInfo(value.currentUser.uid, value, state).then(function(res){
                setSuccess('Profile updated successfully.');
                setTimeout(() => {
                    setSuccess('');
                },4000)
                
                setState({...state, password: "", passwordconf: ""});
            });
            
        }catch(err){
            console.log(err)
            setError('Failed to update profile info.');
        }
        setLoading(false);
    }

    var onInputChange2 = function onInputChange2(event){
        //console.log(event.target.value);
        let { checked,value, name } = event.target;
        //let { email, password} = state;
        if(name === "Email"){
            setState({...state,[name.toLowerCase()]:value});
        }else if(name === "Terms"){
            setState({...state,[name.toLowerCase()]: checked})
        }else{
            setState({...state,[name.toLowerCase()]: value})
        }

    };


    //console.log(currentUser);
    var location = history.location;
    var sidebarHeight = "100vh";
    if(location.pathname === "/UpdateProfile"){
        sidebarHeight = "160.5vh";
    }
    //console.log("location is: " + history.location);
    return (
        <div className="content-wrapper" style={{minHeight: "500px"}}>
            <section className="content" style={{height: "151vh"}}>
                <div className="container-fluid mobile-card-margin" style={{ marginBottom: "5%"}}>
                    <div className="card-body p-0">
                        <div className="row">
                            <Card className="update-form-style">
                            <Card.Body>
                                <h2 style={{color:"#3264f5"}} className="text-center mb-2">Update Profile</h2>
                            <form onSubmit={handleSubmit} className="contact-form text-center" id="modal-contact-form-data6">
                                    <div className="form-group" id="email" style={{visibility: "hidden", marginBottom: 0, height: "4px"}}>
                                        <Feild  name="Email" placeholder="Email" value={state.email} 
                                        onChange={onInputChange2}  type="email" required="required"/>
                                    </div>
                                    <div className="form-group" id="fullname">
                                        <Feild  name="fullname" placeholder="Full Name" value={state.fullname} 
                                        onChange={onInputChange2}  type="text" required="required"/>
                                    </div>
                                    <div className="form-group" id="contact">
                                        <Feild  name="contact" placeholder="Contact eg. 876-123-1234" value={state.contact} 
                                        onChange={onInputChange2}  type="text" required="required"/>
                                    </div>
                                    <div className="form-group" id="address_line_1">
                                        <Feild  name="address_line_1" placeholder="Adddress Line 1 eg: 3 partrick Drive" value={state.address_line_1} 
                                        onChange={onInputChange2}  type="text" required="required"/>
                                    </div>
                                    <div className="form-group" id="address_line_2">
                                        <Feild  name="address_line_2" placeholder="Address Line 2 Optional" value={state.address_line_2} 
                                        onChange={onInputChange2}  type="text"/>
                                    </div>
                                    <div className="form-group" id="state_or_parish">
                                        <Feild  name="state_or_parish" placeholder="State or Parish eg: Kingston 8" value={state.state_or_parish} 
                                        onChange={onInputChange2}  type="text" required="required"/>
                                    </div>
                                    <div className="form-group" id="city">
                                        <Feild  name="city" placeholder="City" value={state.city} 
                                        onChange={onInputChange2}  type="text" required="required"/>
                                    </div>
                                    <div className="form-group" id="postal_code">
                                        <Feild  name="postal_code" placeholder="Postal Code" value={state.postal_code} 
                                        onChange={onInputChange2}  type="text"/>
                                    </div>
                                    {/* <div className="form-group" id="password">
                                        <Feild  name="password" placeholder="Password blank to keep the same" value={state.password} 
                                        onChange={onInputChange2}  type="password" />
                                    </div>
                                    <div className="form-group" id="password-confirm">
                                        <Feild  name="passwordconf" placeholder="Password Conf: blank to keep the same" value={state.passwordconf} 
                                        onChange={onInputChange2}  type="password" />
                                    </div> */}
                                    {error && <Alert variant="danger">{error}</Alert> }
                                    {success && <Alert variant="success">{success}</Alert> }
                                    <button disabled={loading} className="btn btn-large btn-rounded btn-blue btn-hvr-pink modal_contact_btn"
                                                id="updateprofile_submit_btn" type="submit">
                                                    Update
                                                    <div className="btn-hvr-setting">
                                                        <ul className="btn-hvr-setting-inner">
                                                            <li className="btn-hvr-effect"></li>
                                                            <li className="btn-hvr-effect"></li>
                                                            <li className="btn-hvr-effect"></li>
                                                            <li className="btn-hvr-effect"></li>
                                                        </ul>
                                                    </div>
                                    </button>
                                    <div className="w-100 text-center mt-2 form-link">
                                        <Link to="/Dashboard">Cancel</Link>
                                    </div>
                                </form>
                            </Card.Body>
                        </Card>
                        </div>
                    </div>
                </div>
            </section>
        </div>
            
    )
}

export default UpdateProfileScreen;