/* eslint-disable no-unused-vars */
import { useState, useRef } from "react";
import { Alert, Card } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import Feild from "../components/Feild";
import { Form } from "react-bootstrap";
import Select from "react-dropdown-select";
import DatePickerDefualt2 from "../components/DatePickerDefault2";

var initialValues = {
  cost: "",
  item_name: "",
  mailbox_number: "",
  merchant: "",
  order_date: "",
  status: null,
  tracking_number: "",
  weight: "",
  courier: null,
  fcost: 0,
  house_Num: "",
  duty: 0,
};

export default function AddPackageScreen() {
  //Initialize state
  var { value } = useAuth();
  var { addPackageStaff } = value;
  var [error, setError] = useState("");
  var [success, setSuccess] = useState("");
  var [loading, setLoading] = useState(false);
  var ddl1 = useRef([]);
  var ddl2 = useRef([]);

  var [state, setState] = useState(initialValues);
  //console.log('here');
  var handleSubmit = async function handleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    ////console.log("I am inside fuction");
    if (state.order_date.length < 3) {
      return setError("Please select date received");
    } else {
      try {
        setError("");
        setSuccess("");
        setLoading(true);
        var dateFeildDesktop = document.getElementById("your_unique_id_desk");
        var dateFeildMobile = document.getElementById("your_unique_id_mobile");
        console.log("here");
        await addPackageStaff(state).then(function (res) {
          if (
            res === state.tracking_number ||
            res === `Tracking number exist. ${state.tracking_number}`
          ) {
            setError(
              `A package with the tracking number ${res} already exists.`
            );
            setTimeout(() => {
              setError("");
            }, 4000);
          } else if (res === true) {
            setSuccess("Package added successfully.");
            console.log(res);
            ddl1.current.clearAll();
            ddl2.current.clearAll();
            setState(initialValues);
            setTimeout(() => {
              setSuccess("");
            }, 4000);
            if (dateFeildDesktop !== null) {
              dateFeildDesktop.value = "";
            }

            if (dateFeildMobile !== null) {
              dateFeildMobile.value = "";
            }
          } else if (res === "Mailbox doesnot exist") {
            setError("Please enter a valid mailbox number.");
            setTimeout(() => {
              setError("");
            }, 4000);
          } else if (res === false) {
            setError("Unable to add Package at this time");
            setTimeout(() => {
              setError("");
            }, 4000);
          }
        });
      } catch (err) {
        //console.log(err);
        setError("Failed to add package.");
        setTimeout(() => {
          setError("");
        }, 4000);
      }
      setLoading(false);
    }
  };

  var onInputChange1 = function onInputChange1(event) {
    //console.log(event[0].value);
    if (event[0] !== undefined && event[0] !== null) {
      let { value } = event[0];
      setState({ ...state, status: value });
    }
  };

  var onInputChange3 = function onInputChange3(event) {
    //console.log(event[0].value);
    if (event[0] !== undefined && event[0] !== null) {
      let { value } = event[0];
      setState({ ...state, courier: value });
    }
  };

  var onInputChange2 = function onInputChange2(event) {
    //console.log(event.target.value);
    let { checked, value, name } = event.target;
    //console.log('name');
    if (name === "tracking_number" || name === "house_Num") {
      let trackcheck = value.trimStart();
      trackcheck = value.trimEnd();
      //console.log('trimed', trackcheck)
      setState({ ...state, [name]: trackcheck });
    } else {
      setState({ ...state, [name.toLowerCase()]: value });
    }
  };

  var onInputChange4 = function onInputChange4(event) {
    //console.log(event.target.value);
    let { checked, value, name } = event.target;
    setState({ ...state, [name.toLowerCase()]: parseFloat(value) });
  };

  var onInputChange5 = function onInputChange5(selection) {
    //console.log(selection);
    setState({ ...state, order_date: selection });
  };

  var options2 = [
    { value: "UPS", label: "UPS" },
    { value: "US Postage", label: "US Postage" },
    { value: "DHL", label: "DHL" },
    { value: "USPS", label: "USPS" },
    { value: "Amazon Logistics", label: "Amazon Logistics" },
    { value: "China Post", label: "China Post" },
    { value: "FedEx", label: "FedEx" },
    { value: "Hong Kong Post", label: "Hong Kong Post" },
    { value: "IBC", label: "IBC" },
    { value: "Korea Post", label: "Korea Post" },
    { value: "Lasership", label: "Lasership" },
    { value: "Royal Mail", label: "Royal Mail" },
    { value: "Major Express", label: "Major Express" },
    { value: "TRX", label: "TRX" },
    { value: "DSG", label: "DSG" },
    { value: "Other", label: "Other" },
  ];

  var options = [
    { value: "Delivered", label: "Delivered" },
    { value: "Ready", label: "Ready" },
    { value: "In Transit", label: "In Transit" },
    { value: "Processing At Customs", label: "Processing At Customs" },
    { value: "Held By Customs", label: "Held By Customs" },
    { value: "Received At Warehouse", label: "Received At Warehouse" },
    { value: "Courier Delivering", label: "Courier Delivering" },
  ];
  //console.log(state);
  return (
    <div className="content-wrapper" style={{ minHeight: "500px" }}>
      <section className="content" style={{ height: "calc(100% - 1%)" }}>
        <div
          className="container-fluid"
          style={{ marginTop: "5%", marginBottom: "5%" }}
        >
          <div className="card-body p-0">
            <div className="row">
              <Card
                className="update-form-style"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  overflow: "auto",
                }}
              >
                <Card.Body>
                  <h2 className="text-center mb-4" style={{ color: "#3264f5" }}>
                    Add New Package
                  </h2>
                  <form
                    onSubmit={handleSubmit}
                    className="contact-form text-center"
                    id="modal-contact-form-data6"
                  >
                    <div className="form-group" id="courier">
                      <Select
                        name="courier"
                        ref={ddl1}
                        clearOnSelect={true}
                        options={options2}
                        onChange={onInputChange3}
                        searchable={true}
                        className={"form-control"}
                        placeholder="Select Courier"
                        required={true}
                      />
                    </div>
                    <div className="form-group" id="status">
                      <Select
                        name="status"
                        ref={ddl2}
                        clearOnSelect={true}
                        options={options}
                        onChange={onInputChange1}
                        searchable={true}
                        className={"form-control"}
                        placeholder="Select Status"
                        required={true}
                      />
                    </div>
                    <div className="form-group" id="order_date">
                      <DatePickerDefualt2 onInputChange5={onInputChange5} />
                    </div>
                    <div className="form-group" id="house_Num">
                      <Feild
                        name="house_Num"
                        placeholder="House Number"
                        value={state.house_Num}
                        onChange={onInputChange2}
                        type="text"
                      />
                    </div>
                    <div className="form-group" id="tracking_number">
                      <Feild
                        name="tracking_number"
                        placeholder="Tracking Number"
                        value={state.tracking_number}
                        onChange={onInputChange2}
                        type="text"
                      />
                    </div>
                    <div className="form-group" id="item_name">
                      <Feild
                        name="item_name"
                        placeholder="Item Name"
                        value={state.item_name}
                        onChange={onInputChange2}
                        type="text"
                      />
                    </div>
                    <div className="form-group" id="mailbox_number">
                      <Feild
                        name="mailbox_number"
                        placeholder="Mailbox Number"
                        value={state.mailbox_number}
                        onChange={onInputChange2}
                        type="text"
                      />
                    </div>
                    {/* <div className="form-group" id="cost">
                                        <Feild  name="cost" placeholder="Enter Cost" value={state.cost} 
                                        onChange={onInputChange2}  type="text"/>
                                    </div> */}
                    <div className="form-group" id="merchant">
                      <Feild
                        name="merchant"
                        placeholder="Enter Merchant"
                        value={state.merchant}
                        onChange={onInputChange2}
                        type="text"
                      />
                    </div>
                    <div
                      className="form-group"
                      style={{ textAlign: "left" }}
                      id="weight"
                    >
                      <label>Weight</label>
                      <Feild
                        name="weight"
                        placeholder="Enter Weight"
                        value={state.weight}
                        onChange={onInputChange2}
                        type="text"
                      />
                    </div>
                    <div
                      className="form-group"
                      style={{ textAlign: "left" }}
                      id="fcost"
                    >
                      <label>Final Cost</label>
                      <Feild
                        name="fcost"
                        placeholder="Enter Final Cost"
                        value={state.fcost}
                        onChange={onInputChange4}
                        type="number"
                      />
                    </div>
                    <div
                      className="form-group"
                      style={{ textAlign: "left" }}
                      id="duty"
                    >
                      <label>Duty</label>
                      <Feild
                        name="duty"
                        placeholder="Enter Duty Cost"
                        value={state.duty}
                        onChange={onInputChange4}
                        type="number"
                      />
                    </div>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <button
                      disabled={loading}
                      className="btn btn-large btn-rounded btn-purple btn-hvr-pink modal_contact_btn"
                      id="updateprofile_submit_btn"
                      type="submit"
                    >
                      Add
                      <div className="btn-hvr-setting">
                        <ul className="btn-hvr-setting-inner">
                          <li className="btn-hvr-effect"></li>
                          <li className="btn-hvr-effect"></li>
                          <li className="btn-hvr-effect"></li>
                          <li className="btn-hvr-effect"></li>
                        </ul>
                      </div>
                    </button>
                    <div className="w-100 text-center mt-2 form-link">
                      <Link to="/AddOrUploadPackages">Cancel</Link>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
