//webpack stuff
//require('file-loader?name=[name].[ext]!./index.html');
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/es/map";
import "core-js/es/set";
import "core-js/es/function";
import "core-js/es/object";
import "core-js/es/regexp";
import "core-js/es/string";
import "core-js/es/number";
import "core-js/es/symbol";
import "core-js/es/typed-array";
import "core-js/es/array";

import { StrictMode } from "react";
import ReactDOM from "react-dom";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//import '@fortawesome/fontawesome-free/css/all.min.css';

import App from "./components/App";

//import assets
//webpack stuff
//import './assets/vendor/css/bundle.min.css'
// import './assets/vendor/css/revolution-settings.min.css'
// import './assets/favicon.ico';
// import './assets/MGK192.png';
// import './assets/MGK512.png'
// import './assets/serviceworker';
// import './assets/plugins/bootstrap/js/bootstrap.bundle.min.js';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
