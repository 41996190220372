import React, { useState } from "react";
import { useAuth } from "../Context/AuthContext";
import { Alert } from "react-bootstrap";
//import Reaptcha from "reaptcha";
import ReCAPTCHA from "react-google-recaptcha";
//const useAuth = React.lazy(() => import('../Context/AuthContext'));

var initialValues = {
  user_email: "",
  user_subject: "",
  message: "",
  from_name: "",
};
export default function Contact() {
  var { value } = useAuth();
  var { sendUserContactEmail } = value;
  var [error, setError] = useState("");
  var [success, setSuccess] = useState("");

  var [state, setState] = useState(initialValues);
  const [verified, setVerified] = useState(false);

  var sendContactEmail = async function sendContactEmail(e) {
    e.preventDefault();
    //console.log("About to enter sendUserContactEmail");
    setError("");
    setSuccess("");
    if (verified) {
      setSuccess("You are verified.");
    } else {
      return setSuccess("Please verify that you are human.");
    }
    await sendUserContactEmail(e.target).then(
      function (result) {
        //console.log(result);
        if (result) {
          //console.log("About to clear form");
          setState(initialValues);
          setSuccess("Thank you for contacting us.");
        }
      },
      function (error) {
        //console.log(error);
        setError("Unable to make contact at this time.");
      }
    );
  };

  var onInputChange2 = function onInputChange2(event) {
    //console.log(event.target.value);
    let { value, name } = event.target;
    setState({ ...state, [name.toLowerCase()]: value });
  };

  const onChange = function onChange(value) {
    setVerified(true);
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <form
        data-testid="contact-comp"
        className="contact-form"
        id="contact-form-data"
        onSubmit={sendContactEmail}
      >
        <div className="row">
          {/* Result */}
          <div className="col-12" id="result"></div>
          {/* Left Column */}
          <div className="col-md-5">
            <div className="form-group">
              <input
                className="form-control"
                id="your_name"
                name="from_name"
                value={state.from_name}
                placeholder="Name"
                required=""
                type="text"
                onChange={onInputChange2}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                id="your_email"
                name="user_email"
                value={state.user_email}
                placeholder="Email"
                required=""
                type="email"
                onChange={onInputChange2}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                id="subject"
                name="user_subject"
                value={state.user_subject}
                placeholder="Subject"
                required=""
                type="text"
                onChange={onInputChange2}
              />
            </div>
          </div>
          {/* Right Column */}
          <div className="col-md-7">
            <div className="form-group">
              <textarea
                className="form-control"
                id="message"
                name="message"
                value={state.message}
                placeholder="Message"
                onChange={onInputChange2}
              ></textarea>
            </div>
          </div>

          {/* Button */}
          <div className="col-md-12 mt-3">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_V2}
              onChange={onChange}
            />
            <button
              className="btn btn-large btn-rounded btn-purple btn-hvr-blue d-block mt-4 contact_btn"
              type="submit"
              disabled={!verified}
              id="submit_btn_contact"
              style={{ width: "100%" }}
            >
              <i
                className="fa fa-spinner fa-spin mr-2 d-none"
                aria-hidden="true"
              ></i>
              <b>Send Message</b>
              <div className="btn-hvr-setting">
                <ul className="btn-hvr-setting-inner">
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
                </ul>
              </div>
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
