/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
//import { Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from "../Context/AuthContext";
import { useHistory } from "react-router-dom";
import { Alert, Card } from "react-bootstrap";
import DateRangePickerWrapper from "../components/DateRangePickerWrapper";
//import FindPackagesByDateRangeResult from "../components/FindPackagesByDateRangeResult";
//import { Link } from 'react-router-dom';
import Moment from "moment";
const FindPackagesByDateRangeResult = React.lazy(() =>
  import("../components/FindPackagesByDateRangeResult")
);

var initialValues = {
  cost: "",
  item_name: "",
  mailbox_number: "",
  merchant: "",
  order_date: "",
  start_date: "",
  end_date: "",
  status: null,
  tracking_number: "",
  weight: "",
  courier: null,
  fcost: 0,
};
var AdminDashboardScreen = function AdminDashboardScreen() {
  //var [ error, setError ] = useState("");
  var { value } = useAuth();
  var { loggedIn, userRolef, rangeOfPackages, findPackagesByDateRange } = value;
  var history = useHistory();
  var [error, setError] = useState("");
  var [success, setSuccess] = useState("");
  var [loading, setLoading] = useState(false);
  var [state, setState] = useState(initialValues);

  // eslint-disable-next-line no-lone-blocks
  {
    /* <Card>
              <Card.Body className="text-center">
              <p>This is the dashboard</p>
              {error && <Alert variant="danger">{error}</Alert> }
              <div><strong>Email: </strong>{currentUser.email}</div>
              <Button className="btn btn-primary" onClick={() => history.push("/UpdateProfile")}>Update Profile</Button>
              </Card.Body>
              <Button variant="link" onClick={handleLogout}>Log Out</Button>
          </Card> */
  }

  var handleSubmit = async function handleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    ////console.log("I am inside fuction");
    console.log(state.end_date);
    console.log(state.start_date);
    if (state.start_date.length < 3 || state.end_date.length < 3) {
      return setError("Please select a proper date range");
    } else {
      try {
        setError("");
        setSuccess("");
        setLoading(true);
        var start = state.start_date;
        var end = state.end_date;
        findPackagesByDateRange(start, end, value);
      } catch {
        setError("Failed to add package.");
      }
      setLoading(false);
    }
  };

  useEffect(function () {
    //console.log(userRolef);
    if (!loggedIn && (userRolef !== "Admin" || userRolef !== "Staff")) {
      //console.log("Dashboard LoggedIn Value" + loggedIn);
      history.push("/");
    }
    var start = Moment()
      .clone()
      .startOf("month")
      .subtract(3, "months")
      .format("Y-MM-D");
    var end = Moment().clone().endOf("month").format("Y-MM-D");

    try {
      findPackagesByDateRange(start, end, value);
    } catch (err) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange5 = function onInputChange5(start, end) {
    console.log(start, end);
    setState({ ...state, start_date: start, end_date: end });
  };

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper" style={{ minHeight: "94vh" }}>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card-body p-0">
              <div id="PackagesTrackerPage" className="row">
                <Card
                  style={{
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    overflow: "auto",
                    minHeight: "87vh",
                  }}
                >
                  <Card.Body>
                    <h2
                      className="text-center mb-4"
                      style={{ color: "#007bff" }}
                    >
                      Packages Tracker
                    </h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <form
                      onSubmit={handleSubmit}
                      className="contact-form text-center"
                      id="modal-contact-form-data6"
                    >
                      <div
                        className="form-group"
                        id="order_date"
                        style={{ marginBottom: "2%" }}
                      >
                        <DateRangePickerWrapper
                          onInputChange5={onInputChange5}
                          initialEndDate={Moment().clone().endOf("month")}
                          initialStartDate={Moment()
                            .clone()
                            .startOf("month")
                            .subtract(3, "months")}
                        />
                      </div>
                      <div
                        style={{
                          display: "-webkit-inline-box",
                          marginBottom: "2%",
                        }}
                      >
                        <button
                          disabled={loading}
                          className="btn btn-large btn-rounded btn-purple btn-hvr-pink modal_contact_btn ml-2 mr-2"
                          id="updateprofile_submit_btn"
                          type="submit"
                          style={{ zIndex: 0 }}
                        >
                          Search
                          <div className="btn-hvr-setting">
                            <ul className="btn-hvr-setting-inner">
                              <li className="btn-hvr-effect"></li>
                              <li className="btn-hvr-effect"></li>
                              <li className="btn-hvr-effect"></li>
                              <li className="btn-hvr-effect"></li>
                            </ul>
                          </div>
                        </button>
                        <div className="ml-2 text-center mt-2 form-link">
                          <a href="#">Clear</a>
                        </div>
                      </div>
                    </form>
                    <FindPackagesByDateRangeResult
                      rangeOfPackages={rangeOfPackages}
                    />
                  </Card.Body>
                </Card>
                {/* Welcome To Admin Dashoard */}
              </div>
            </div>
          </div>
          {/* <!--/. container-fluid --> */}
        </section>
        {/* <!-- /.content --> */}
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default AdminDashboardScreen;
