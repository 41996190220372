import { useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
// import Tippy from "@tippy.js/react";
// import "tippy.js/dist/tippy.css";

export default function ShippingAddress() {
  var { value } = useAuth();
  var { seaFreightAdd, mailbox_Num, airFreightAdd, fetchAddress, currentUser } =
    value;

  var onload = async function onload() {
    //console.log("calling fetch address");
    if (
      mailbox_Num === null ||
      mailbox_Num === undefined ||
      mailbox_Num === ""
    ) {
      await fetchAddress(currentUser.uid, value);
    }
  };

  useEffect(function () {
    if (currentUser != null) {
      try {
        //console.log("about to enter fetch adddress");
        onload();
      } catch {
        //console.log("unable to fetch address");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const Message = function () {
  //   return (
  //     <div className="sea-freight-info">
  //       <h6>
  //         Please note carefully the below conditions which should be met when
  //         using our Sea Freight:
  //       </h6>
  //       1. Invoices valued US$50 and over and the items are shipped in the same
  //       package.
  //       <br />
  //       <br />
  //       2. If Item is not over US$50, ensure it is over 40lbs.
  //       <br />
  //       <br />
  //       3. Ideal for Phones, Laptop, Tablets, TVs.
  //       <br />
  //       <br />
  //       4. Heavy and Expensive items.
  //       <br />
  //       <br />
  //       5. Barrels
  //       <br />
  //       <br />
  //       Once Shipped via the Sea Freight Address, charges will be applied
  //       whether prerequisite conditions were met or not.
  //     </div>
  //   );
  // };
  var Firstname = "";

  if (
    value.userInfo.fullName == null &&
    value.userInfo.fullName === undefined &&
    value.userInfo.fullName === ""
  ) {
    Firstname = "Display Name";
  } else {
    Firstname = value.userInfo.fullName;
  }

  if (seaFreightAdd == null && airFreightAdd == null) {
    return <>Loading</>;
  } else {
    return (
      <>
        <h5 style={{ marginBottom: "15px", fontWeight: "bold" }}>
          MailBox Number:
          <span style={{ color: "#007bff", fontWeight: "bold" }}>
            {" "}
            {mailbox_Num}
          </span>
        </h5>

        <h5 style={{ fontWeight: "bold" }}>US Air Freight Address</h5>
        {/* <h5>Shipping Address</h5> */}
        <span className="right badge badge-success">New</span>
        <p style={{ marginBottom: "0px" }}>
          <b>Your Name: </b>
          {Firstname?.toUpperCase()} MGK
        </p>
        <p style={{ marginBottom: "0px" }}>
          <b>Address Line 1: </b>
          {airFreightAdd.addressLine1}
        </p>
        <p style={{ marginTop: "0px", marginBottom: "0px" }}>
          <b>Address Line 2: </b>
          {airFreightAdd.addressLine2 + mailbox_Num}
        </p>
        <p style={{ marginTop: "0px", marginBottom: "0px" }}>
          <b>City: </b>
          {airFreightAdd.city}
        </p>
        <p style={{ marginTop: "0px", marginBottom: "0px" }}>
          <b>State: </b>
          {airFreightAdd.state}
        </p>
        <p style={{ marginTop: "0px" }}>
          <b>Zip Code: </b>
          {airFreightAdd.zipCode}
        </p>
        <p></p>
        {/* <h5>US Sea Freight Address</h5> */}
        {/* <h5 style={{fontWeight: "bold"}}>Sea Address</h5>
          <p style={{marginBottom: "0px"}}>Contact Us For Sea Address &amp; Quote<br></br>
          Email: <a className="dashboard-invoice" href="mailto:info@mgkexpress.com">
            info@mgkexpress.com
          </a>
          <br />Call:  <a className="dashboard-invoice" href="tel:8766790192">876 679 0192</a><br />
            <span className="address-text">
              WhatsApp: <a target={'_blank'} rel="noreferrer"  className="dashboard-invoice" href="http://wa.me/8768021709">8768021709</a> &nbsp;  <a target={'_blank'} rel="noreferrer"  className="dashboard-invoice" href="http://wa.me/8762388576">8762388576</a>
            </span>
          </p> */}
        {/* <p style={{marginBottom: "0px"}}><b>Your Name: </b>{Firstname.toUpperCase()} MGK</p>
          <p style={{marginBottom: "0px"}}><b>Address Line 1: </b>{seaFreightAdd.addressLine1}</p>
          <p style={{marginTop: "0px",marginBottom: "0px"}}><b>Address Line 2: </b>{seaFreightAdd.addressLine2 + mailbox_Num}</p>
          <p style={{marginTop: "0px",marginBottom: "0px"}}><b>City: </b>{seaFreightAdd.city}</p>
          <p style={{marginTop: "0px",marginBottom: "0px"}}><b>State: </b>{seaFreightAdd.state}</p>
          <p style={{marginTop: "0px",marginBottom: "0px"}}><b>Zip Code: </b>{seaFreightAdd.zipCode}</p> */}
        <h5>
          {/* For Sea Freight <br /> */}
          {/* <Tippy content={<Message />}>
            <div className="circle pulse">
              <i className="fas fa-info-circle info-icon"></i>
            </div>
          </Tippy> */}
        </h5>
      </>
    );
  }
}
