/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

//Context
import { useAuth } from "../Context/AuthContext";
import SignUp from "./SignUp";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import { dev_logo_location } from "../usefulVariables";
import RefferalModal from "./RefferalModal";
import TrackPackageModal from "./TrackPackageModal";
var Header = function Header() {
  //declare context variables
  var { value } = useAuth();
  var { fetchUserRole } = value;
  var { logout, userRolef } = value;
  // console.log("Value contains:");
  // console.log(value);
  var history = useHistory();

  var handleLogout = function handleLogout() {
    try {
      //////console.log("attempting to go to logout user");
      logout(value);
      //value.loggedIn = false;
      //history.push("/");
    } catch (err) {
      console.log("Failed to logout.", err);
    }
  };

  var location = history.location;
  var referralPath = location.pathname;
  // console.log("pathname is:" + location.pathname);
  // if(value.loggedIn && location.pathname === "/"){
  //   console.log("going to dashboard");
  //   history.push("/Dashboard");
  // }

  useEffect(() => {
    try {
      // console.log("inside useEffect");
      if (value !== undefined && value !== null) {
        if (
          value.loggedIn !== undefined &&
          value.loggedIn !== null &&
          value.loggedIn !== ""
        ) {
          if (
            value.loggedIn &&
            (value.userRolef === "" ||
              value.userRolef === undefined ||
              value.userRolef === null)
          ) {
            fetchUserRole(value.currentUser.uid, value);
          }
        }
      }
    } catch (err) {
      // console.log(err);
    }
  }, [fetchUserRole, value]);

  const handleRedirect = (value) => {
    history.push(value);
  };

  try {
    var sidebarHeight = "100vh";
    if (location.pathname === "/UpdateProfile") {
      sidebarHeight = "160.5vh";
    } else if (location.pathname === "/Dashboard") {
      sidebarHeight = "117vh";
    } else if (referralPath.includes("/ReferralSignUp")) {
      return (
        /*Header Start*/
        <header className="cursor-light">
          <nav className="navbar navbar-top-default navbar-expand-lg navbar-gradient nav-icon alt-font">
            <div className="container">
              <a className="logo link scroll " href="#home" title="Logo">
                <img
                  alt="logo"
                  className="logo-dark default"
                  src={dev_logo_location}
                  style={{ height: "130px" }}
                  id=""
                />
              </a>

              {/* <img alt="logo" className="logo-dark default at-large" src='/agency/img/MKXPRESS-Final-Edited.png' style={{height: "130px"}} id="" /> */}

              <div className="collapse navbar-collapse" id="agency">
                <div className="navbar-nav ml-auto">
                  {/* <a className="nav-link link scroll active" href="#home">Home</a>
                                <a className="nav-link link scroll" href="#about-us">About Us</a>
                                <a className="nav-link link scroll" href="#portfolio">Rates</a>
                                <a className="nav-link link scroll" href="#contact-us">Contact Us</a>
                                <span className="menu-line"><i aria-hidden="true" className="fa fa-angle-down"></i></span> */}
                </div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {/* <a className="btn btn-medium btn-rounded btn-transparent-white btn-hvr-white ml-3" data-toggle="modal" data-target="#SignUpFormModal"
                              href="#">Sign Up
                                <div className="btn-hvr-setting">
                                    <ul className="btn-hvr-setting-inner">
                                        <li className="btn-hvr-effect"></li>
                                        <li className="btn-hvr-effect"></li>
                                        <li className="btn-hvr-effect"></li>
                                        <li className="btn-hvr-effect"></li>
                                    </ul>
                                </div>
                            </a> */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {/* <a className="btn btn-medium btn-rounded btn-transparent-white btn-hvr-white ml-3" data-toggle="modal" data-target="#LoginFormModal"
                              href="#">Login
                                <div className="btn-hvr-setting">
                                    <ul className="btn-hvr-setting-inner">
                                        <li className="btn-hvr-effect"></li>
                                        <li className="btn-hvr-effect"></li>
                                        <li className="btn-hvr-effect"></li>
                                        <li className="btn-hvr-effect"></li>
                                    </ul>
                                </div>
                            </a> */}
              </div>

              {/* <button className="fullnav-toggler link" id="full-menu-1" type="button">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </button> */}

              <div className="slider-social">
                <ul className="list-unstyled">
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <li className="animated-wrap">
                    <a
                      className="animated-element"
                      href="https://www.facebook.com/themgkexpress"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i aria-hidden="true" className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <li className="animated-wrap">
                    <a
                      className="animated-element"
                      href="https://www.instagram.com/themgkexpress/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i aria-hidden="true" className="fab fa-instagram"></i>
                    </a>
                  </li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <li className="animated-wrap">
                    <a
                      className="animated-element"
                      href="https://twitter.com/themgkexpress"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i aria-hidden="true" className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className="nav-holder main style-2 alt-font">
            <button className="fullnav-close link" type="button">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </button>

            <div className="container">
              <div className="shape-left">
                <nav className="navbar full-menu-navigation left">
                  <ul className="list-unstyled">
                    {/* <li><a className="link scroll" href="#home">
                                        <span className="anchor-circle"></span>
                                        <span className="anchor-text">Home</span>
                                    </a></li>
                                    <li><a className="link scroll" href="#about-us">
                                        <span className="anchor-circle"></span>
                                        <span className="anchor-text">About</span>
                                    </a></li>
                                    <li><a className="link scroll" href="#portfolio">
                                        <span className="anchor-circle"></span>
                                        <span className="anchor-text">Rates</span>
                                    </a></li>
                                    <li><a className="link scroll" href="#contact-us">
                                        <span className="anchor-circle"></span>
                                        <span className="anchor-text">Contact</span>
                                    </a></li>
                                    <li>
                                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid 
                                      <a className="link scroll" data-animation-duration="500" data-toggle="modal" data-target="#SignUpFormModal" href="#">
                                        <span className="anchor-circle"></span>
                                        <span className="anchor-text">SignUp</span>
                                      </a>
                                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid 
                                      <a className="link scroll" data-animation-duration="500" data-toggle="modal" data-target="#LoginFormModal"href="#">
                                        <span className="anchor-circle"></span>
                                        <span className="anchor-text">Login</span>
                                      </a>
                                    </li> */}
                  </ul>
                  <span
                    className="full-menu-dot"
                    style={{ transform: `scale(0)` }}
                  ></span>
                </nav>
                <img alt="shape" src="agency/img/shape-8.png" />
              </div>
              <div className="shape-right">
                <div className="full-menu-detail hide-cursor">
                  {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                  <h6 className="title"></h6>
                  <p>
                    <i className="fas fa-user-alt"></i>MGK Express
                  </p>
                  <p>
                    <i className="fas fa-mobile-alt"></i>
                    <a
                      className="mr-3"
                      style={{ color: "#4941E0" }}
                      href="tel:8768021709"
                    >
                      876 238 8576
                    </a>
                  </p>
                  <p>
                    <i className="fas fa-envelope"></i>
                    <a
                      className="mr-3 alt-color"
                      style={{ color: "#4941E0" }}
                      href="mailto:info@mgkexpress.com"
                    >
                      info@mgkexpress.com
                    </a>
                  </p>
                </div>
                <img alt="shape" src="agency/img/shape-7.png" />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="SignUpFormModal"
            tabIndex="-1"
            aria-labelledby="SignUpFormLabel"
            aria-hidden="true"
          >
            {/* Heading */}
            <div className="modal-dialog">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ padding: "1rem 1rem 0 1rem" }}
                >
                  <span className="modal-title text-center">
                    <span className="sub-title">
                      <h5>We are MGK Express</h5>
                    </span>
                    <span>
                      <h2 className="title mt-2">
                        Lets start your{" "}
                        <span className="alt-color js-rotating">
                          shipping, journey.
                        </span>
                      </h2>
                    </span>
                  </span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <SignUp />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="LoginFormModal"
            tabIndex="-1"
            aria-labelledby="LoginUpFormLabel"
            aria-hidden="true"
          >
            {/* Heading */}
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title text-center">
                    <span className="sub-title">
                      <h5>We are MGK Express</h5>
                    </span>
                    <span>
                      <h2 className="title mt-2">
                        Lets start your{" "}
                        <span className="alt-color js-rotating">
                          shipping, journey.
                        </span>
                      </h2>
                    </span>
                  </span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Login />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="FPasswordFormModal"
            tabIndex="-1"
            aria-labelledby="LoginUpFormLabel"
            aria-hidden="true"
          >
            {/* Heading */}
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title text-center">
                    <span className="sub-title">
                      <h5>We are MGK Express</h5>
                    </span>
                    <span>
                      <h2 className="title mt-2">
                        Lets start your{" "}
                        <span className="alt-color js-rotating">
                          shipping, journey.
                        </span>
                      </h2>
                    </span>
                  </span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ForgotPassword />
                </div>
              </div>
            </div>
          </div>
        </header>
      );
    }

    if (!value.loggedIn) {
      // console.log("not logged in.")
      return (
        /*Header Start*/
        <header className="cursor-light">
          <nav className="navbar navbar-top-default navbar-expand-lg navbar-gradient nav-icon alt-font">
            <div className="container">
              <a className="logo link scroll " href="#home" title="Logo">
                <img
                  alt="logo"
                  className="logo-dark default"
                  src="agency/img/MKXPRESS-Final-Edited-2.png"
                  style={{ height: "130px" }}
                  id=""
                />
              </a>
              {/* <Link className="logo link scroll" to="#" title="Logo">
                          <img alt="logo" className="logo-dark default at-large" src='agency/img/MKXPRESS-Final-Edited.png' style={{height: "130px"}} id="" />
                        </Link> */}

              <div className="collapse navbar-collapse" id="agency">
                <div className="navbar-nav ml-auto">
                  <a className="nav-link link scroll active" href="#home">
                    Home
                  </a>
                  <a className="nav-link link scroll" href="#about-us">
                    About Us
                  </a>
                  <a className="nav-link link scroll" href="#portfolio">
                    Rates
                  </a>
                  <a className="nav-link link scroll" href="#contact-us">
                    Contact Us
                  </a>
                  <span className="menu-line">
                    <i aria-hidden="true" className="fa fa-angle-down"></i>
                  </span>
                </div>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="btn btn-medium btn-rounded btn-transparent-white btn-hvr-white ml-3"
                  data-toggle="modal"
                  data-target="#SignUpFormModal"
                  href="#"
                >
                  Sign Up
                  <div className="btn-hvr-setting">
                    <ul className="btn-hvr-setting-inner">
                      <li className="btn-hvr-effect"></li>
                      <li className="btn-hvr-effect"></li>
                      <li className="btn-hvr-effect"></li>
                      <li className="btn-hvr-effect"></li>
                    </ul>
                  </div>
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="btn btn-medium btn-rounded btn-transparent-white btn-hvr-white ml-3"
                  data-toggle="modal"
                  data-target="#LoginFormModal"
                  href="#"
                >
                  Login
                  <div className="btn-hvr-setting">
                    <ul className="btn-hvr-setting-inner">
                      <li className="btn-hvr-effect"></li>
                      <li className="btn-hvr-effect"></li>
                      <li className="btn-hvr-effect"></li>
                      <li className="btn-hvr-effect"></li>
                    </ul>
                  </div>
                </a>
              </div>

              <button
                className="fullnav-toggler link"
                id="full-menu-1"
                type="button"
              >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </button>

              <div className="slider-social">
                <ul className="list-unstyled">
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <li className="animated-wrap">
                    <a
                      className="animated-element"
                      href="https://www.facebook.com/themgkexpress"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i aria-hidden="true" className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <li className="animated-wrap">
                    <a
                      className="animated-element"
                      href="https://www.instagram.com/themgkexpress/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i aria-hidden="true" className="fab fa-instagram"></i>
                    </a>
                  </li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <li className="animated-wrap">
                    <a
                      className="animated-element"
                      href="https://twitter.com/themgkexpress"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i aria-hidden="true" className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className="nav-holder main style-2 alt-font">
            <button className="fullnav-close link" type="button">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </button>

            <div className="container">
              <div className="shape-left">
                <nav className="navbar full-menu-navigation left">
                  <ul className="list-unstyled">
                    <li>
                      <a className="link scroll" href="#home">
                        <span className="anchor-circle"></span>
                        <span className="anchor-text">Home</span>
                      </a>
                    </li>
                    <li>
                      <a className="link scroll" href="#about-us">
                        <span className="anchor-circle"></span>
                        <span className="anchor-text">About</span>
                      </a>
                    </li>
                    <li>
                      <a className="link scroll" href="#portfolio">
                        <span className="anchor-circle"></span>
                        <span className="anchor-text">Rates</span>
                      </a>
                    </li>
                    <li>
                      <a className="link scroll" href="#contact-us">
                        <span className="anchor-circle"></span>
                        <span className="anchor-text">Contact</span>
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        className="link scroll"
                        data-animation-duration="500"
                        data-toggle="modal"
                        data-target="#SignUpFormModal"
                        href="#"
                      >
                        <span className="anchor-circle"></span>
                        <span className="anchor-text">SignUp</span>
                      </a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        className="link scroll"
                        data-animation-duration="500"
                        data-toggle="modal"
                        data-target="#LoginFormModal"
                        href="#"
                      >
                        <span className="anchor-circle"></span>
                        <span className="anchor-text">Login</span>
                      </a>
                    </li>
                  </ul>
                  <span
                    className="full-menu-dot"
                    style={{ transform: `scale(0)` }}
                  ></span>
                </nav>
                <img alt="shape" src="agency/img/shape-8.png" />
              </div>
              <div className="shape-right">
                <div className="full-menu-detail hide-cursor">
                  {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                  <h6 className="title"></h6>
                  <p>
                    <i className="fas fa-user-alt"></i>MGK Express
                  </p>
                  <p>
                    <i className="fas fa-mobile-alt"></i>
                    <a
                      className="mr-3"
                      style={{ color: "#4941E0" }}
                      href="tel:8768021709"
                    >
                      876 238 8576
                    </a>
                  </p>
                  <p>
                    <i className="fas fa-envelope"></i>
                    <a
                      className="mr-3 alt-color"
                      style={{ color: "#4941E0" }}
                      href="mailto:info@mgkexpress.com"
                    >
                      info@mgkexpress.com
                    </a>
                  </p>
                </div>
                <img alt="shape" src="agency/img/shape-7.png" />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="SignUpFormModal"
            tabIndex="-1"
            aria-labelledby="SignUpFormLabel"
            aria-hidden="true"
          >
            {/* Heading */}
            <div className="modal-dialog">
              <div className="modal-content">
                <div
                  className="modal-header"
                  style={{ padding: "1rem 1rem 0 1rem" }}
                >
                  <span className="modal-title text-center">
                    <span className="sub-title">
                      <h5>We are MGK Express</h5>
                    </span>
                    <span>
                      <h2 className="title mt-2">
                        Lets start your{" "}
                        <span className="alt-color js-rotating">
                          shipping, journey.
                        </span>
                      </h2>
                    </span>
                  </span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <SignUp />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="LoginFormModal"
            tabIndex="-1"
            aria-labelledby="LoginUpFormLabel"
            aria-hidden="true"
          >
            {/* Heading */}
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title text-center">
                    <span className="sub-title">
                      <h5>We are MGK Express</h5>
                    </span>
                    <span>
                      <h2 className="title mt-2">
                        Lets start your{" "}
                        <span className="alt-color js-rotating">
                          shipping, journey.
                        </span>
                      </h2>
                    </span>
                  </span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Login />
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="FPasswordFormModal"
            tabIndex="-1"
            aria-labelledby="LoginUpFormLabel"
            aria-hidden="true"
          >
            {/* Heading */}
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title text-center">
                    <span className="sub-title">
                      <h5>We are MGK Express</h5>
                    </span>
                    <span>
                      <h2 className="title mt-2">
                        Lets start your{" "}
                        <span className="alt-color js-rotating">
                          shipping, journey.
                        </span>
                      </h2>
                    </span>
                  </span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ForgotPassword />
                </div>
              </div>
            </div>
          </div>
        </header>
      );
    } else if (value.loggedIn) {
      // console.log("Checking who's logged in");
      if (userRolef === "Customer") {
        // console.log("customer logged in");

        var Firstname = "";
        // console.log("checking for the users full name");
        if (
          value.userInfo.fullName == null &&
          value.userInfo.fullName === undefined &&
          value.userInfo.fullName === ""
        ) {
          Firstname = "Display Name";
        } else {
          // console.log("User has name");
          var FirstnameArr = value.userInfo.fullName.split(" ");
          Firstname = FirstnameArr[0];
        }

        // console.log("Returning header menu");
        return (
          // Navbar
          <>
            <nav className="main-header navbar fixed-top navbar-expand navbar-white navbar-light gradient-bg1">
              {/* Left navbar links */}
              <li className="nav-item ham-menu-at-small">
                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a
                  className="nav-link"
                  style={{ color: "white" }}
                  data-widget="pushmenu"
                  href="#"
                  role="button"
                >
                  <i className="fas fa-bars"></i>
                </a>
              </li>
              <div
                className="flex-mobile-style"
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  display: "flex",
                  paddingRight: "10%",
                }}
              >
                <img
                  src="agency/img/MKXPRESS-Final-Edited-2.png"
                  alt="Mgk Logo"
                  className="brand-image logo-display"
                  style={{ opacity: ".8", height: "13vh" }}
                />
              </div>
              <ul className="navbar-nav header-display">
                <li className="nav-item">
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    className="nav-link"
                    style={{ color: "white" }}
                    data-widget="pushmenu"
                    href="#"
                    role="button"
                  >
                    <i className="fas fa-bars"></i>
                  </a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <Link
                    className="nav-link link scroll active"
                    style={{ color: "white" }}
                    to="/Dashboard"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link link scroll active"
                    style={{ color: "white" }}
                    to="/ViewPackages"
                  >
                    View Packages
                  </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <Link
                    to="/Rates"
                    className="nav-link"
                    style={{ color: "white" }}
                  >
                    Rates
                  </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <Link
                    to="/Contact"
                    className="nav-link"
                    style={{ color: "white" }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>

              {/* Right navbar links */}
              {/* <ul className="navbar-nav ml-auto menu-right-mobile">
                    <li className="nav-item" style={{color: 'white'}}><FetchRefferalPoints/></li>
                  </ul>  */}
              {/* <RPoints /> */}
            </nav>
            {/* navbar*/}

            {/* Modal */}
            <RefferalModal />
            <TrackPackageModal />
            {/* Modal */}

            {/* Main Sidebar Container */}
            <aside
              id="customer-aside"
              className="main-sidebar sidebar-dark-primary elevation-4 gradient-bg1 sidebarHeight fixed-top"
              style={{ maxHeight: "170vh", minHeight: "100vh" }}
            >
              {/* Brand Logo */}
              {/* <Link to="/Dashboard" className="brand-link" style={{paddingLeft: "25%", height: "83px"}}> */}
              <img
                src="agency/img/MKXPRESS-Final-Edited-2.png"
                alt="Mgk Logo"
                className="brand-image"
                style={{ opacity: ".8", height: "130px" }}
              />
              <Link to={referralPath}>
                <i
                  data-widget="pushmenu"
                  className="fas fa-times nav-icon"
                  style={{
                    position: "absolute",
                    right: "8%",
                    top: "2.4%",
                    color: "white",
                    fontSize: "3vh",
                  }}
                ></i>
              </Link>
              {/* </Link> */}
              <br />
              {/* Sidebar */}
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-0 pb-3 mb-0 d-flex">
                  <div className="image">
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img
                      src="dist/img/dp.png"
                      className="img-circle elevation-2"
                      alt="User Image"
                    />
                  </div>
                  <div className="info">
                    <Link to="/UpdateProfile" className="d-block">
                      {Firstname == null &&
                      Firstname === undefined &&
                      Firstname === ""
                        ? "Display Name"
                        : Firstname.toUpperCase()}
                    </Link>
                  </div>
                </div>

                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    {/* Add icons to the links using the .nav-icon className
                            with font-awesome or any other icon font library */}

                    <li className="nav-item">
                      <a
                        href="/Dashboard"
                        onClick={() => handleRedirect("/Dashboard")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-home"></i>
                        <p>Home</p>
                      </a>
                    </li>
                    <li className="nav-item has-treeview">
                      <a
                        href="/UpdateProfile"
                        className="nav-link visible-small"
                        onClick={() => handleRedirect("/UpdateProfile")}
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-chart-pie"></i>
                        <p>Profile</p>
                      </a>
                      <Link
                        to="/UpdateProfile"
                        className="nav-link visible-large"
                      >
                        <i className="nav-icon fas fa-chart-pie"></i>
                        <p>Profile</p>
                      </Link>
                      {/* <ul className="nav nav-treeview">
                            <li className="nav-item">
                              <a href="pages/charts/chartjs.html" className="nav-link">
                                <i className="far fa-circle nav-icon"></i>
                                <p>ChartJS</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="pages/charts/flot.html" className="nav-link">
                                <i className="far fa-circle nav-icon"></i>
                                <p>Flot</p>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="pages/charts/inline.html" className="nav-link">
                                <i className="far fa-circle nav-icon"></i>
                                <p>Inline</p>
                              </a>
                            </li>
                          </ul> */}
                    </li>
                    <span
                      className="nav-item dropdown"
                      style={{ textAlign: "left" }}
                    >
                      <a
                        href={referralPath}
                        className="nav-link btn dropdown-toggle"
                        type="button"
                        id="dropdownMenu1"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-toggle="dropdown"
                      >
                        <i className="nav-icon fas fa-box"></i>
                        <p>Packages</p>
                      </a>
                      <div
                        className="dropdown-menu"
                        style={{ backgroundColor: "#3264f5" }}
                        aria-labelledby="dropdownMenu1"
                      >
                        <li className="nav-item">
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <Link
                            to="/ViewPackages"
                            className="nav-link visible-large"
                          >
                            <i className="nav-icon fas fa-box"></i>
                            <p>View Packages</p>
                          </Link>
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <a
                            href="/ViewPackages"
                            onClick={() => handleRedirect("/ViewPackages")}
                            className="nav-link visible-small"
                            data-widget="pushmenu"
                          >
                            <i className="nav-icon fas fa-box"></i>
                            <p>View Packages</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <Link
                            data-toggle="modal"
                            data-target="#ViewTrackPackage"
                            to={location.pathname}
                            className="nav-link visible-large"
                          >
                            <i className="nav-icon fas fa-solid fa-map-marker-alt"></i>
                            <p>Track Package</p>
                            {/* <span className="right badge badge-success">New</span> */}
                          </Link>
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <Link
                            data-toggle="modal"
                            data-target="#ViewTrackPackage"
                            to={location.pathname}
                            className="nav-link visible-small"
                            data-widget="pushmenu"
                          >
                            <i className="nav-icon fas fa-solid fa-map-marker-alt"></i>
                            <p>Track Package</p>
                            <br />
                            {/* <span className="right badge badge-success">New</span> */}
                          </Link>
                        </li>
                        <li className="nav-item">
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <Link
                            to="/PreAlert"
                            className="nav-link visible-large"
                          >
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>Pre Alert</p>
                          </Link>
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <a
                            href="/PreAlert"
                            onClick={() => handleRedirect("/PreAlert")}
                            className="nav-link visible-small"
                            data-widget="pushmenu"
                          >
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>Pre Alert</p>
                            <br />
                          </a>
                        </li>
                        {/* <li className="nav-item"> */}
                        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        {/* <Link
                            to="/ScheduleDelivery"
                            className="nav-link visible-large"
                          >
                            <i className="nav-icon fas fa-truck"></i>
                            <p>Schedule Delivery</p>
                          </Link> */}
                        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        {/* <a
                            href="/ScheduleDelivery"
                            onClick={() => handleRedirect("/ScheduleDelivery")}
                            className="nav-link visible-small"
                            data-widget="pushmenu"
                          >
                            <i className="nav-icon fas fa-truck"></i>
                            <p>Schedule Delivery</p>
                          </a>
                        </li> */}
                      </div>
                    </span>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        data-toggle="modal"
                        data-target="#ViewReferralDetails"
                        to={location.pathname}
                        className="nav-link visible-large"
                      >
                        <i className="nav-icon fas fa-solid fa-star"></i>
                        <p>Referrals</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        data-toggle="modal"
                        data-target="#ViewReferralDetails"
                        to={location.pathname}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-solid fa-star"></i>
                        <p>Referrals</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link to="/Rates" className="nav-link visible-large">
                        <i className="nav-icon fas fa-dollar-sign"></i>
                        <p>Rates</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a
                        href="/Rates"
                        onClick={() => handleRedirect("/Rates")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-dollar-sign"></i>
                        <p>Rates</p>
                      </a>
                    </li>
                    {/* <li className="nav-item"> */}
                    {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    {/* <a href="#" className="nav-link visible-large">
                            <i className="nav-icon fas fa-calculator"></i>
                            <p>
                              Calculator
                              <span className="right badge badge-danger">Coming Soon</span>
                            </p>
                          </a> */}
                    {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    {/* <a href="#" className="nav-link visible-small" data-widget="pushmenu">
                            <i className="nav-icon fas fa-calculator"></i>
                            <p>
                              Calculator
                            </p><br/>
                            <p className="right badge badge-danger">Coming Soon</p>
                          </a>
                        </li> */}
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link to="/Contact" className="nav-link visible-large">
                        <i className="nav-icon fas fa-address-book"></i>
                        <p>Contact Us</p>
                      </Link>
                      <a
                        href="/Contact"
                        onClick={() => handleRedirect("/Contact")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-address-book"></i>
                        <p>Contact Us</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        className="nav-link visible-large"
                        onClick={handleLogout}
                        to={referralPath}
                      >
                        <i className="nav-icon fas fa-sign-out-alt"></i>
                        <p>Logout</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        className="nav-link visible-small"
                        onClick={handleLogout}
                        to={referralPath}
                      >
                        <i className="nav-icon fas fa-sign-out-alt"></i>
                        <p>Logout</p>
                      </Link>
                    </li>
                  </ul>
                </nav>
                {/* sidebar-menu */}
              </div>
              {/* sidebar */}
            </aside>
          </>
        );
      } else if (userRolef === "Staff") {
        return (
          // Navbar
          <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
              {/* Left navbar links */}
              <ul className="navbar-nav">
                <li className="nav-item">
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    className="nav-link"
                    data-widget="pushmenu"
                    href="#"
                    role="button"
                  >
                    <i className="fas fa-bars"></i>
                  </a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <Link
                    className="nav-link link scroll active"
                    to="/AdminDashboard"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link link scroll"
                    to="/AddOrUploadPackages"
                  >
                    Add Package
                  </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <Link to="/FindAPackage" className="nav-link">
                    Find A Package
                  </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <Link to="/ViewUsers" className="nav-link">
                    View Users
                  </Link>
                </li>
                <li className="d-none d-sm-inline-block">
                  <Link className="nav-link link scroll" to="/FindUser">
                    Find User
                  </Link>
                </li>
              </ul>
            </nav>
            {/* navbar */}

            {/* Main Sidebar Container */}
            <aside
              className="main-sidebar sidebar-dark-primary elevation-4 gradient-bg1"
              style={{ height: "100vh" }}
            >
              {/* Brand Logo */}
              {/* <Link to="/StaffDashboard" className="brand-link" style={{paddingLeft: "25%", height: "83px"}}> */}
              <img
                src="agency/img/MKXPRESS-Final-Edited-2.png"
                alt="Mgk Logo"
                className="brand-image"
                style={{ opacity: ".8", height: "130px" }}
              />
              <i
                data-widget="pushmenu"
                className="fas fa-times nav-icon"
                style={{
                  position: "absolute",
                  right: "8%",
                  top: "2.4%",
                  color: "white",
                  fontSize: "3vh",
                }}
              ></i>
              {/* </Link> */}
              <br />
              {/* Sidebar */}
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                  <div className="image">
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img
                      src="dist/img/dp.png"
                      className="img-circle elevation-2"
                      alt="User Image"
                    />
                  </div>
                  <div className="info">
                    <Link to="/UpdateProfile" className="d-block">
                      {value.userInfo.fullName == null &&
                      value.userInfo.fullName === undefined &&
                      value.userInfo.fullName === ""
                        ? "Display Name"
                        : value.userInfo.fullName.toUpperCase()}
                    </Link>
                  </div>
                </div>

                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    {/* Add icons to the links using the .nav-icon className
                            with font-awesome or any other icon font library */}
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        to="/AddOrUploadPackages"
                        className="nav-link visible-large"
                      >
                        <i className="nav-icon fas fa-box"></i>
                        <p>Add A Package</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a
                        href="/AddOrUploadPackages"
                        onClick={() => handleRedirect("/AddOrUploadPackages")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-box"></i>
                        <p>Add A Package</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        to="/FindAPackage"
                        className="nav-link visible-large"
                      >
                        <i className="nav-icon fas fa-box"></i>
                        <p>Find A Package</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a
                        href="/FindAPackage"
                        onClick={() => handleRedirect("/FindAPackage")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-box"></i>
                        <p>Find A Package</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link to="/FindUser" className="nav-link visible-large">
                        <i className="nav-icon fas fa-box"></i>
                        <p>Find User</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a
                        href="/FindUser"
                        onClick={() => handleRedirect("/FindUser")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-box"></i>
                        <p>Find User</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link to="/ViewUsers" className="nav-link visible-large">
                        <i className="nav-icon fas fa-user-clock"></i>
                        <p>View Users</p>
                        <p className="right badge badge-success">New</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a
                        to="/ViewUsers"
                        onClick={() => handleRedirect("/ViewUsers")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-user-clock"></i>
                        <p>View Users</p>
                        <br />
                        <p className="right badge badge-success">New</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a href="#" className="nav-link visible-large">
                        <i className="nav-icon fas fa-calculator"></i>
                        <p>
                          Calculator
                          <span className="right badge badge-danger">
                            Coming Soon
                          </span>
                        </p>
                      </a>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a href="#" className="nav-link visible-small">
                        <i className="nav-icon fas fa-calculator"></i>
                        <p>Calculator</p>
                        <br />
                        <p className="right badge badge-danger">Coming Soon</p>
                      </a>
                    </li>
                    {/* <li className="nav-item">
                          <a href="#" className="nav-link">
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>
                              Pre Alert
                              <span className="right badge badge-danger">Coming Soon</span>
                            </p>
                          </a>
                        </li> */}

                    <li className="nav-item has-treeview">
                      <a
                        href="/UpdateProfile"
                        onClick={() => handleRedirect("/UpdateProfile")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-chart-pie"></i>
                        <p>
                          Profile
                          {/* <i className="right fas fa-angle-left"></i> */}
                        </p>
                      </a>
                      <Link
                        to="/UpdateProfile"
                        className="nav-link visible-large"
                      >
                        <i className="nav-icon fas fa-chart-pie"></i>
                        <p>
                          Profile
                          {/* <i className="right fas fa-angle-left"></i> */}
                        </p>
                      </Link>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a
                            href="pages/charts/chartjs.html"
                            className="nav-link"
                          >
                            <i className="far fa-circle nav-icon"></i>
                            <p>ChartJS</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="pages/charts/flot.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Flot</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="pages/charts/inline.html"
                            className="nav-link"
                          >
                            <i className="far fa-circle nav-icon"></i>
                            <p>Inline</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        className="nav-link visible-large"
                        onClick={handleLogout}
                        to={referralPath}
                      >
                        <i className="nav-icon fas fa-sign-out-alt"></i>
                        <p>Logout</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        className="nav-link visible-small"
                        onClick={handleLogout}
                        to={referralPath}
                      >
                        <i className="nav-icon fas fa-sign-out-alt"></i>
                        <p>Logout</p>
                      </Link>
                    </li>
                  </ul>
                </nav>
                {/* sidebar-menu */}
              </div>
              {/* sidebar */}
            </aside>
          </>
        );
      } else if (userRolef === "Admin") {
        // console.log()
        return (
          // Navbar
          <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
              {/* Left navbar links */}
              <ul className="navbar-nav">
                <li className="nav-item">
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    className="nav-link"
                    data-widget="pushmenu"
                    href="#"
                    role="button"
                  >
                    <i className="fas fa-bars"></i>
                  </a>
                </li>
                <li className="nav-item d-sm-inline-block">
                  <Link
                    className="nav-link link scroll active"
                    to="/AdminDashboard"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link link scroll"
                    to="/AddOrUploadPackages"
                  >
                    Add Package
                  </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <Link to="/FindAPackage" className="nav-link">
                    Find A Package
                  </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <Link to="/ViewUsers" className="nav-link">
                    View Users
                  </Link>
                </li>
                <li className="d-none d-sm-inline-block">
                  <Link className="nav-link link scroll" to="/FindUser">
                    Find User
                  </Link>
                </li>
              </ul>
            </nav>
            {/* navbar */}

            {/* Main Sidebar Container */}
            <aside
              className="main-sidebar sidebar-dark-primary elevation-4 gradient-bg1"
              style={{ height: "100vh" }}
            >
              {/* Brand Logo */}
              {/* <Link to="/StaffDashboard" className="brand-link" style={{paddingLeft: "25%", height: "83px"}}> */}
              <img
                src="agency/img/MKXPRESS-Final-Edited-2.png"
                alt="Mgk Logo"
                className="brand-image"
                style={{ opacity: ".8", height: "130px" }}
              />
              <i
                data-widget="pushmenu"
                className="fas fa-times nav-icon"
                style={{
                  position: "absolute",
                  right: "8%",
                  top: "2.4%",
                  color: "white",
                  fontSize: "3vh",
                }}
              ></i>
              {/* </Link> */}
              <br />
              {/* Sidebar */}
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                  <div className="image">
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img
                      src="dist/img/dp.png"
                      className="img-circle elevation-2"
                      alt="User Image"
                    />
                  </div>
                  <div className="info">
                    <Link to="/UpdateProfile" className="d-block">
                      {value.userInfo.fullName == null &&
                      value.userInfo.fullName === undefined &&
                      value.userInfo.fullName === ""
                        ? "Display Name"
                        : value.userInfo.fullName.toUpperCase()}
                    </Link>
                  </div>
                </div>

                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    {/* Add icons to the links using the .nav-icon className
                            with font-awesome or any other icon font library */}
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        to="/AdminDashboard"
                        className="nav-link visible-large"
                      >
                        <i className="nav-icon fas fa-home"></i>
                        <p>Home</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a
                        href="/AdminDashboard"
                        onClick={() => handleRedirect("/AdminDashboard")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-home"></i>
                        <p>Home</p>
                      </a>
                    </li>
                    <span
                      className="nav-item dropdown"
                      style={{ textAlign: "left" }}
                    >
                      <a
                        href={referralPath}
                        className="nav-link btn dropdown-toggle"
                        type="button"
                        id="dropdownMenu1"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-toggle="dropdown"
                      >
                        <i className="nav-icon fas fa-box"></i>
                        <p>Packages</p>
                      </a>
                      <div
                        className="dropdown-menu"
                        style={{ backgroundColor: "#3264f5" }}
                        aria-labelledby="dropdownMenu1"
                      >
                        <li className="nav-item">
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <Link
                            to="/AddOrUploadPackages"
                            className="nav-link visible-large"
                          >
                            <i className="nav-icon fas fa-box"></i>
                            <p>Add A Package</p>
                          </Link>
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <a
                            to="/AddOrUploadPackages"
                            onClick={() =>
                              handleRedirect("/AddOrUploadPackages")
                            }
                            className="nav-link visible-small"
                            data-widget="pushmenu"
                          >
                            <i className="nav-icon fas fa-box"></i>
                            <p>Add A Package</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <Link
                            to="/FindAPackage"
                            className="nav-link visible-large"
                          >
                            <i className="nav-icon fas fa-box"></i>
                            <p>Find A Package</p>
                          </Link>
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <a
                            to="/FindAPackage"
                            onClick={() => handleRedirect("/FindAPackage")}
                            className="nav-link visible-small"
                            data-widget="pushmenu"
                          >
                            <i className="nav-icon fas fa-box"></i>
                            <p>Find A Package</p>
                          </a>
                        </li>
                      </div>
                    </span>
                    <span
                      className="nav-item dropdown"
                      style={{ textAlign: "left" }}
                    >
                      <a
                        href={referralPath}
                        className="nav-link btn dropdown-toggle"
                        type="button"
                        id="dropdownMenu1"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-toggle="dropdown"
                      >
                        <i className="nav-icon fas fa-user-clock"></i>
                        <p>Users</p>
                      </a>
                      <div
                        className="dropdown-menu"
                        style={{ backgroundColor: "#3264f5" }}
                        aria-labelledby="dropdownMenu1"
                      >
                        <li className="nav-item">
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <Link
                            to="/FindUser"
                            className="nav-link visible-large"
                          >
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>Find User</p>
                          </Link>
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <a
                            href="/FindUser"
                            onClick={() => handleRedirect("/FindUser")}
                            className="nav-link visible-small"
                            data-widget="pushmenu"
                          >
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>Find User</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <Link
                            to="/ViewUsers"
                            className="nav-link visible-large"
                          >
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>View Users</p>
                            {/* <p className="right badge badge-success">New</p> */}
                          </Link>
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <a
                            href="/ViewUsers"
                            onClick={() => handleRedirect("/ViewUsers")}
                            className="nav-link visible-small"
                            data-widget="pushmenu"
                          >
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>View Users</p>
                            <br />
                            {/* <p className="right badge badge-success">New</p> */}
                          </a>
                        </li>
                        <li className="nav-item">
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <Link
                            to="/ConsistentUsers"
                            className="nav-link visible-large"
                          >
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>Consistent Users</p>
                            {/* <p className="right badge badge-success">New</p> */}
                          </Link>
                          {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <a
                            href="/ConsistentUsers"
                            onClick={() => handleRedirect("/ConsistentUsers")}
                            className="nav-link visible-small"
                            data-widget="pushmenu"
                          >
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>Consistent Users</p>
                            <br />
                            {/* <p className="right badge badge-success">New</p> */}
                          </a>
                        </li>
                      </div>
                    </span>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        to="/StaffMembers"
                        className="nav-link visible-large"
                      >
                        <i className="nav-icon fas fa-calculator"></i>
                        <p>Staff Members</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a
                        href="/StaffMembers"
                        onClick={() => handleRedirect("/StaffMembers")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-calculator"></i>
                        <p>Staff Members</p>
                        <br />
                      </a>
                    </li>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        to="/UpdateRates"
                        className="nav-link visible-large"
                      >
                        <i className="nav-icon fas fa-dollar-sign"></i>
                        <p>
                          Update Rates
                          <span className="right badge badge-success">New</span>
                        </p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a
                        href="/UpdateRates"
                        onClick={() => handleRedirect("/UpdateRates")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-dollar-sign"></i>
                        <p>Update Rates</p>
                        <br />
                        <p className="right badge badge-success">New</p>
                      </a>
                    </li>
                    {/* <li className="nav-item">
                          <a href="#" className="nav-link">
                            <i className="nav-icon fas fa-user-clock"></i>
                            <p>
                              Pre Alert
                              <span className="right badge badge-danger">Coming Soon</span>
                            </p>
                          </a>
                        </li> */}

                    <li className="nav-item has-treeview">
                      <a
                        href="/UpdateProfile"
                        onClick={() => handleRedirect("/UpdateProfile")}
                        className="nav-link visible-small"
                        data-widget="pushmenu"
                      >
                        <i className="nav-icon fas fa-chart-pie"></i>
                        <p>
                          Profile
                          {/* <i className="right fas fa-angle-left"></i> */}
                        </p>
                      </a>
                      <Link
                        to="/UpdateProfile"
                        className="nav-link visible-large"
                      >
                        <i className="nav-icon fas fa-chart-pie"></i>
                        <p>
                          Profile
                          {/* <i className="right fas fa-angle-left"></i> */}
                        </p>
                      </Link>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <a
                            href="pages/charts/chartjs.html"
                            className="nav-link"
                          >
                            <i className="far fa-circle nav-icon"></i>
                            <p>ChartJS</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="pages/charts/flot.html" className="nav-link">
                            <i className="far fa-circle nav-icon"></i>
                            <p>Flot</p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="pages/charts/inline.html"
                            className="nav-link"
                          >
                            <i className="far fa-circle nav-icon"></i>
                            <p>Inline</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        className="nav-link visible-large"
                        onClick={handleLogout}
                        to={referralPath}
                      >
                        <i className="nav-icon fas fa-sign-out-alt"></i>
                        <p>Logout</p>
                      </Link>
                      {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <Link
                        className="nav-link visible-small"
                        onClick={handleLogout}
                        to={referralPath}
                      >
                        <i className="nav-icon fas fa-sign-out-alt"></i>
                        <p>Logout</p>
                      </Link>
                    </li>
                  </ul>
                </nav>
                {/* sidebar-menu */}
              </div>
              {/* sidebar */}
            </aside>
          </>
        );
      } else {
        return <p>loading</p>;
      }
    }
  } catch (err) {
    // console.log("Header error is?")
    // console.log(err);
  }
  //history.push("/Dashboard");
  //return <p>Something is not right</p>
};

export default Header;
