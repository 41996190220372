/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
//import PackageStatus from "../components/PackageStatus";
import { MDBDataTableV5, MDBDataTable } from "mdbreact";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import Moment from "moment";

export default function ViewPackagesScreen() {
  var { value } = useAuth();
  var { packages, currentUser, fetchPackages } = value;
  const [rows, setRows] = useState([]);

  useEffect(function () {
    if (currentUser != null && packages == null && packages === undefined) {
      try {
        fetchPackages(currentUser.uid, value);
      } catch {
        //console.log("unable to fetch address");
      }
    }

    if (packages !== null && packages !== undefined) {
      var newrows = [];
      let filteredPacks = packages.filter((item) => item.Status === "Ready");
      packages.map((item) => {
        if (item.Status !== "Ready") {
          filteredPacks.push(item);
        }
        return null;
      });
      //console.log("Whats inside packages");
      //console.log(packages);
      var numRows = filteredPacks.length;
      for (var i = 0; i < numRows; ) {
        var status = null;
        var Invoice = null;
        if (filteredPacks[i].Status === "Ready")
          status = (
            <span className={"badge badge-success"}>
              {filteredPacks[i].Status}
            </span>
          );

        if (filteredPacks[i].Status === "In Transit")
          status = (
            <span className={"badge badge-warning"}>
              {filteredPacks[i].Status}
            </span>
          );

        if (filteredPacks[i].Status === "Processing At Customs")
          status = (
            <span className={"badge badge-info"}>
              {filteredPacks[i].Status}
            </span>
          );

        if (filteredPacks[i].Status === "Received At Warehouse")
          status = (
            <span className={"badge badge-primary"}>
              {filteredPacks[i].Status}
            </span>
          );

        if (filteredPacks[i].Status === "Delivered")
          status = (
            <span className={"badge badge-danger"}>
              {filteredPacks[i].Status}
            </span>
          );

        if (filteredPacks[i].Status === "Courier Delivering")
          status = (
            <span className={"badge badge-light text-dark"}>
              {filteredPacks[i].Status}
            </span>
          );

        if (status === "Held By Customs")
          return <span className={"badge badge-dark"}>{status}</span>;

        if (filteredPacks[i].Status === "Invoice Uploaded")
          status = (
            <span className={"badge badge-secondary"}>
              {filteredPacks[i].Status}
            </span>
          );

        if (filteredPacks[i].Status === "" || null)
          status = <span className={"badge badge-danger"}>Loading</span>;

        Invoice =
          filteredPacks[i].Status !== "Ready" &&
          filteredPacks[i].Status !== "Delivered" &&
          filteredPacks[i].Status !== "Invoice Uploaded" ? (
            <Link
              to={`/UploadInvoice/${filteredPacks[i].TrackingNumber}`}
              className="dashboard-invoice"
              data-toggle="tooltip"
              data-placement="top"
              title="Click Here To Upload Invoice"
            >
              <span
                data-toggle="modal"
                data-target="#ViewUserDetailsModal"
                id={filteredPacks[i].TrackingNumber}
                name={filteredPacks[i].TrackingNumber}
              >
                <i
                  id={filteredPacks[i].TrackingNumber}
                  name={filteredPacks[i].TrackingNumber}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View User"
                  style={{
                    color: "#3264f5",
                    cursor: "pointer",
                    marginRight: "3%",
                    fontSize: "25px",
                  }}
                  className="fas fa-cloud-upload-alt"
                ></i>
              </span>
            </Link>
          ) : filteredPacks[i].Status === "Invoice Uploaded" ? (
            <Link
              to={`/UploadInvoice/${filteredPacks[i].TrackingNumber}`}
              className="dashboard-invoice"
              data-toggle="tooltip"
              data-placement="top"
              title="Invoice(s) Uploaded"
            >
              <span
                data-toggle="modal"
                data-target="#ViewUserDetailsModal"
                id={filteredPacks[i].TrackingNumber}
                name={filteredPacks[i].TrackingNumber}
              >
                <i
                  id={filteredPacks[i].TrackingNumber}
                  name={filteredPacks[i].TrackingNumber}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="View User"
                  style={{
                    color: "#3264f5",
                    cursor: "pointer",
                    marginRight: "3%",
                    fontSize: "25px",
                  }}
                  className="fa fa-paperclip"
                ></i>
              </span>
            </Link>
          ) : (
            "N/A"
          );
        var newrow = {
          trackingnumber: filteredPacks[i].TrackingNumber,
          item: filteredPacks[i].ItemName,
          invoice: Invoice,
          status: status,
          orderDate: Moment(filteredPacks[i].OrderDate.toDate()).format(
            "YYYY-MM-DD"
          ),
        };

        newrows.push(newrow);
        i = i + 1;
      }
      setRows(newrows);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   var [datatable, setDatatable] = useState({
  //     columns: [
  //       {
  //         label: "Tracking Number",
  //         field: "trackingnumber",
  //         width: 250,
  //         attributes: {
  //           "aria-controls": "DataTable",
  //           "aria-label": "Tracking Number",
  //         },
  //       },
  //       {
  //         label: "Upload Invoice",
  //         field: "invoice",
  //         width: 150,
  //       },
  //       {
  //         label: "Item",
  //         field: "item",
  //         width: 150,
  //       },
  //       {
  //         label: "Status",
  //         field: "status",
  //         width: 150,
  //       },
  //       {
  //         label: "Date",
  //         field: "orderDate",
  //         width: 150,
  //       },
  //     ],
  //     rows: [],
  //   });

  const columns = [
    {
      name: "trackingnumber",
      label: "TrackingNumber",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "invoice",
      label: "Upload Invoice",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "item",
      label: "Item",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "orderDate",
      label: "date",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    search: true,
    selectableRows: "none",
    download: false,
    rowsPerPage: 8,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: false,
      },
    },
    print: false,
    sortOrder: {
      name: "orderDate",
      direction: "desc",
    },
  };

  if (packages != null) {
    return (
      <div
        className="content-wrapper "
        // style={{
        //   backgroundColor: "#f4f6f9",
        //   minHeight: "500px",
        //   height: "94vh",
        // }}
      >
        <section className="content2">
          <div className="container-fluid container-margin-vpackages">
            <div className="card-body p-0">
              <div className="viewPackage-div-style" style={{ color: "black" }}>
                {/* <MDBDataTable
                  scrollX
                  hover
                  striped
                  entriesOptions={[5, 20, 25]}
                  entries={5}
                  pagesAmount={4}
                  data={datatable}
                  pagingTop
                  searchTop
                  searchBottom={false}
                  className="table-responsive"
                /> */}
                <MUIDataTable data={rows} columns={columns} options={options} />

                {/* <!-- /.table-responsive --> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <>Loading</>;
  }
}
