/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
//import PackageStatus from "../components/PackageStatus";
import CustomDatatable from "../components/CustomDatatable";
//import SingleStaffMemberView from "../components/SingleStaffMemberView";
const SingleStaffMemberView = React.lazy(() =>
  import("../components/SingleStaffMemberView")
);

const columns = [
  {
    name: "Position",
    label: "Role",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Name",
    label: "Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Contact_Number",
    label: "Contact #",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Update",
    label: "Update",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export default function ManageStaffScreen() {
  var { value } = useAuth();
  var { currentUser, fetchStaffUsers, staffUsers, userRolef } = value;

  const [userResult, setuserRes] = useState({});
  const [count, setCount] = useState(0);
  var history = useHistory();

  useEffect(
    function () {
      //console.log("Whats inside users")
      //console.log(users);
      if (userRolef !== "Admin") {
        history.push("/Login");
      }
      if (currentUser !== null) {
        if (staffUsers === null || staffUsers === undefined) {
          try {
            fetchStaffUsers(value);
          } catch {
            //console.log("unable to fetch address");
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userResult, staffUsers]
  );

  return (
    <div
      className="content-wrapper "
      style={{ backgroundColor: "#f4f6f9", minHeight: "500px", height: "94vh" }}
    >
      <section className="content">
        <div className="container-fluid container-margin-vpackages">
          <h2 className="text-center mb-4" style={{ color: "#007bff" }}>
            Staff Members
          </h2>
          <CustomDatatable
            users={staffUsers}
            columns={columns}
            isManageStaff={true}
            setuserRes={setuserRes}
          />
        </div>
      </section>
      <div
        className="modal fade"
        id="ViewUserDetailsModal"
        tabIndex="-1"
        aria-labelledby="SignUpFormLabel"
        aria-hidden="true"
      >
        {/* Heading */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-center"
                style={{ textAlign: "center !important", width: "100%" }}
              >
                <span className="sub-title"></span>
                <span>
                  <h2 className="title mt-2" style={{ color: "#3264f5" }}>
                    User Details
                  </h2>
                </span>
              </h5>
              <button
                id="AreYouSureClose"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <SingleStaffMemberView
                userResult={userResult}
                isStaff={true}
                setCount={setCount}
                count={count}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
