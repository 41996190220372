/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Alert, Card } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import Feild from "../components/Feild";
import { useHistory } from "react-router-dom";
import PackageStatus from "../components/PackageStatus";

var initialValues = { singlePackage: [], tracking_number: "" };

export default function FindPackage() {
  //Initialize state
  var { value } = useAuth();
  var { checkIfPackageExistStaff, singlePackage, clearPackageResultStaff } =
    value;
  var [error, setError] = useState("");
  var [success, setSuccess] = useState("");
  var [loading, setLoading] = useState(false);

  //var history = useHistory();

  var [state, setState] = useState(initialValues);

  var handleSubmit = async function handleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside fuction");
    if (state.tracking_number.length < 5) {
      return setError("Please enter a valid tracking number.");
    }
    try {
      setError("");
      setSuccess("");
      setLoading(true);
      await checkIfPackageExistStaff(state.tracking_number, value)
        .then(async function (res) {
          //console.log(res);
          if (res === false) {
            setError(
              "No package found with tracking number: " + state.tracking_number
            );
            await clearPackageResultStaff(value);
          } else {
            setSuccess(
              "Package was found with tracking number: ." +
                state.tracking_number
            );
            setState({ ...state, password: "", passwordconf: "" });
          }
        })
        .catch(function (err) {
          //console.log(err);
          setError("No package found with tracking number:");
        });
    } catch (err) {
      //console.log(err)
      setError("No package found with tracking number:");
    }
    setLoading(false);
  };

  var onInputChange2 = function onInputChange2(event) {
    //console.log(event.target.value);
    let { checked, value, name } = event.target;
    //let { email, password} = state;
    if (name === "Email") {
      setState({ ...state, [name.toLowerCase()]: value });
    } else if (name === "Terms") {
      setState({ ...state, [name.toLowerCase()]: checked });
    } else {
      setState({ ...state, [name.toLowerCase()]: value });
    }
  };

  var renderPackages = function renderPackages() {
    if (singlePackage.length === 0) {
      return (
        <p style={{ paddingLeft: "5%" }}>
          There are no packages to display at this time.
        </p>
      );
    }

    return singlePackage.map((element) => (
      <tr key={element.PackageInfo.TrackingNumber}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <td>
          <Link to={"/EditPackageStaff/" + element.PackageInfo.TrackingNumber}>
            {element.PackageInfo.TrackingNumber}
          </Link>
        </td>
        <td>{element.PackageInfo.ItemName}</td>
        <td>
          <PackageStatus status={element.PackageInfo.Status} />
        </td>
      </tr>
    ));
  };

  if (singlePackage != null) {
    return (
      <div className="content-wrapper" style={{ minHeight: "500px" }}>
        <section className="content" style={{ height: "151vh" }}>
          <div
            className="container-fluid"
            style={{ marginTop: "5%", marginBottom: "5%" }}
          >
            <div className="card-body p-0">
              <div className="row">
                <Card className="update-form-style">
                  <Card.Body>
                    <h2
                      className="text-center mb-4"
                      style={{ color: "#007bff" }}
                    >
                      Find Package
                    </h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <form
                      onSubmit={handleSubmit}
                      className="contact-form text-center"
                      id="modal-contact-form-data6"
                    >
                      <div className="form-group" id="tracking_number">
                        <Feild
                          name="tracking_number"
                          placeholder="Enter Tracking Number"
                          value={state.fullname}
                          onChange={onInputChange2}
                          type="text"
                          required="required"
                        />
                      </div>
                      <button
                        disabled={loading}
                        className="btn btn-large btn-rounded btn-purple btn-hvr-pink modal_contact_btn"
                        id="updateprofile_submit_btn"
                        type="submit"
                      >
                        Find Package
                        <div className="btn-hvr-setting">
                          <ul className="btn-hvr-setting-inner">
                            <li className="btn-hvr-effect"></li>
                            <li className="btn-hvr-effect"></li>
                            <li className="btn-hvr-effect"></li>
                            <li className="btn-hvr-effect"></li>
                          </ul>
                        </div>
                      </button>
                      <div className="w-100 text-center mt-2 form-link">
                        <Link to="/StaffDashboard">Cancel</Link>
                      </div>
                    </form>
                    <div>
                      <br />
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th>Tracking Number</th>
                            <th>Item</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>{renderPackages()}</tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className="content-wrapper" style={{ minHeight: "500px" }}>
        <section className="content" style={{ height: "151vh" }}>
          <div
            className="container-fluid"
            style={{ marginTop: "5%", marginBottom: "5%" }}
          >
            <div className="card-body p-0">
              <div className="row">
                <Card className="update-form-style">
                  <Card.Body>
                    <h2
                      className="text-center mb-4"
                      style={{ color: "#007bff" }}
                    >
                      Find Package
                    </h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <form
                      onSubmit={handleSubmit}
                      className="contact-form text-center"
                      id="modal-contact-form-data6"
                    >
                      <div className="form-group" id="tracking_number">
                        <Feild
                          name="tracking_number"
                          placeholder="Enter Tracking Number"
                          value={state.fullname}
                          onChange={onInputChange2}
                          type="text"
                          required="required"
                        />
                      </div>
                      <button
                        disabled={loading}
                        className="btn btn-large btn-rounded btn-purple btn-hvr-pink modal_contact_btn"
                        id="updateprofile_submit_btn"
                        type="submit"
                      >
                        Find Package
                        <div className="btn-hvr-setting">
                          <ul className="btn-hvr-setting-inner">
                            <li className="btn-hvr-effect"></li>
                            <li className="btn-hvr-effect"></li>
                            <li className="btn-hvr-effect"></li>
                            <li className="btn-hvr-effect"></li>
                          </ul>
                        </div>
                      </button>
                      <div className="w-100 text-center mt-2 form-link">
                        <Link to="/StaffDashboard">Cancel</Link>
                      </div>
                    </form>
                    <div
                      style={{
                        color: "#007bff",
                        textAlign: "center",
                        fontWeight: "bold",
                        paddingTop: "5%",
                      }}
                    >
                      <p>Results Will Display Here</p>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
