/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";

//Import Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

var Login = function Login() {
  var emailRef = useRef();
  var passwordRef = useRef();
  var { value } = useAuth();
  var { login, gLogin, fLogin, tLogin } = value;
  var { fetchUserInfo } = value;
  var [error, setError] = useState("");
  var [loading, setLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  //var history = useHistory();

  var handleSubmit = async function handleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside fuction");
    try {
      setError("");
      setLoading(true);
      await login(
        emailRef.current.value,
        passwordRef.current.value,
        value
      ).then(async function (res1) {
        if (
          res1 !== "Username / Password Incorrect" &&
          res1 !== "Unable to login at this time" &&
          res1.currentUser !== undefined
        ) {
          await fetchUserDetails(res1).then(function (res) {
            if (res) {
              //console.log("About to close signup modal.");
              closeModal();
              //setLoggedIn(true);

              //console.log(userRolef);
              // if(userRolef === "Customer"){
              //     console.log("About to navigate to dashboard.");
              //     history.push("/Dashboard");
              // }else if(userRolef === "Staff"){
              //     console.log("About to navigate to staff dashboard.");
              //     history.push("/StaffDashboard");
              // }
            } else {
              setLoading(false);
              setError("Unable to login at this time");
            }
          });
        } else {
          setLoading(false);
          setError(res1);
        }
      });
    } catch {
      setLoading(false);
      setError("Failed to login");
    }
    setLoading(false);
  };

  var handleGoogleSubmit = async function handleGoogleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside Google Submit fuction");
    try {
      setError("");
      setLoading(true);
      await gLogin(value).then(async function (res1) {
        console.log(res1);
        if (res1 != null) {
          await fetchUserDetails(res1).then(function (res) {
            if (res) {
              //console.log("About to close login modal.");
              closeModal();
              //setLoggedIn(true);
              //console.log("About to navigate to dashboard.");
              //history.push("/Dashboard");
            } else {
              setError("Unable to login at this time");
            }
          });
        } else {
          setError("Unable to login at this time.");
        }
      });
    } catch (err) {
      console.log(err);
      setError("Failed to login");
    }
    setLoading(false);
  };

  var handleFacebookSubmit = async function handleFacebookSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside Facebook Submit fuction");
    try {
      setError("");
      setLoading(true);
      await fLogin(value).then(async function (res1) {
        if (res1 != null) {
          await fetchUserDetails(res1).then(function (res) {
            if (res) {
              //console.log("About to close login modal.");
              closeModal();
              //setLoggedIn(true);
              //console.log("About to navigate to dashboard.");
              //history.push("/Dashboard");
            } else {
              setError("Unable to login at this time");
            }
          });
        } else {
          setError("Unable to login at this time.");
        }
      });
    } catch {
      setError("Failed to login");
    }
    setLoading(false);
  };

  var handleTwitterSubmit = async function handleTwitterSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside Twitter Submit fuction");
    try {
      setError("");
      setLoading(true);
      await tLogin(value).then(async function (res1) {
        if (res1 != null) {
          await fetchUserDetails(res1).then(function (res) {
            if (res) {
              //console.log("About to close login modal.");
              closeModal();
              //setLoggedIn(true);
              //console.log("About to navigate to dashboard.");
              //history.push("/Dashboard");
            } else {
              setError("Unable to login at this time");
            }
          });
        } else {
          setError("Unable to login at this time.");
        }
      });
    } catch {
      setError("Failed to login");
    }
    setLoading(false);
  };

  var switchModal = function switchModal() {
    try {
      //console.log("switchModdal")
      window.$("#LoginFormModal").modal("hide");
      window.$("body").removeClass("modal-open");
      window.$(".modal-backdrop").remove();
      window.$("#SignUpFormModal").modal("show");
    } catch {
      //console.log("failed to switch to login modal");
    }
  };

  var switchFPasswordModal = function switchFPasswordModal() {
    try {
      //console.log("switchModdal")
      window.$("#LoginFormModal").modal("hide");
      window.$("body").removeClass("modal-open");
      window.$(".modal-backdrop").remove();
      window.$("#FPasswordFormModal").modal("show");
    } catch {
      //console.log("failed to switch to login modal");
    }
  };

  var closeModal = function closeModal() {
    try {
      //console.log("switchModdal")
      window.$("#LoginFormModal").modal("hide");
      window.$("body").removeClass("modal-open");
      window.$(".modal-backdrop").remove();
    } catch {
      //console.log("failed to switch to close login modal");
    }
  };
  var fetchUserDetails = async function fetchUserDetails(payload) {
    //console.log("Is current user null");
    //console.log(payload);
    if (payload.currentUser !== null && payload.currentUser !== undefined) {
      if (
        payload.currentUser.uid !== null &&
        payload.currentUser.uid !== undefined
      ) {
        //console.log("Fetching user info");
        return await fetchUserInfo(payload.currentUser.uid, payload, null);
      }
    }
    return false;
  };

  const handleChange = function handleChange(event) {
    setshowPassword(!showPassword);
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <form
        onSubmit={handleSubmit}
        className="contact-form text-center"
        id="modal-contact-form-data4"
      >
        <div className="form-group" id="email">
          <input
            type="email"
            name="email"
            placeholder="Email"
            ref={emailRef}
            className="form-control"
            autoComplete="on"
            required
          />
        </div>
        <div className="form-group" id="password">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            autoComplete="off"
            placeholder="Password"
            ref={passwordRef}
            className="form-control"
            required
          />
        </div>
        <div
          className="form-check"
          style={{ textAlign: "initial" }}
          id="showpassword"
        >
          <input
            type="checkbox"
            className="form-check-input login-check"
            id="exampleCheck2"
            value={showPassword}
            onClick={(e) => handleChange(e)}
          />
          <label className="form-check-label ml-2" htmlFor="exampleCheck2">
            Click the box to show password
          </label>
        </div>
        <p className="w-100 text-left mt-2 form-link">
          <a onClick={switchFPasswordModal}>Forgot Password?</a>
        </p>
        <button
          disabled={loading}
          className="btn btn-large btn-rounded btn-blue btn-hvr-pink modal_contact_btn text-center"
          id="login_submit_btn"
          type="submit"
        >
          Log In
          <div className="btn-hvr-setting">
            <ul className="btn-hvr-setting-inner">
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
            </ul>
          </div>
        </button>
        <p className="w-100 text-center mt-2">----------OR----------</p>
        <p className="w-100 text-center mt-2">
          <FontAwesomeIcon
            icon={faGoogle}
            style={styles.gIcon}
            onClick={handleGoogleSubmit}
          />
          <FontAwesomeIcon
            icon={faFacebook}
            style={styles.fIcon}
            onClick={handleFacebookSubmit}
          />
          <FontAwesomeIcon
            icon={faTwitter}
            style={styles.tIcon}
            onClick={handleTwitterSubmit}
          />
        </p>
        <p className="w-100 text-center mt-2 form-link">
          Need an account? <a onClick={switchModal}>SignUp</a>
        </p>
      </form>
    </>
  );
};

var styles = {
  gIcon: {
    color: "#dd4b39",
    cursor: "pointer",
    fontSize: "20px",
  },
  fIcon: {
    color: "#4267B2",
    cursor: "pointer",
    fontSize: "20px",
    marginRight: "5%",
    marginLeft: "5%",
  },
  tIcon: {
    color: "#1DA1F2",
    cursor: "pointer",
    fontSize: "20px",
  },
};

export default Login;
