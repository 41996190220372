import { useState } from "react";
import Feild from "../components/Feild";

var initialValues = { tracking_number: "" };

export default function TrackPackageModal() {
  var [state, setState] = useState(initialValues);

  var onInputChange2 = function onInputChange2(event) {
    console.log(event.target.value);
    let { value, name } = event.target;
    console.log(name.toLowerCase());
    setState({ ...state, [name.toLowerCase()]: value });
  };

  return (
    <div
      className="modal fade"
      id="ViewTrackPackage"
      tabIndex="-1"
      aria-labelledby="SignUpFormLabel"
      aria-hidden="true"
    >
      {/* Heading */}
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {/* <h5 className="modal-title text-center" style={{textAlign: 'center !important', width: '100%'}}>
                            <span className="sub-title"></span>
                            <span>
                            <h2 className="title mt-2" style={{marginLeft: 'auto', marginRight: 'auto', color: "#3264f5"}} >User Details</h2>
                            </span>
                            
                        </h5> */}
            <div className="card" style={{ width: "100%", marginBottom: 0 }}>
              <div className="card-header">
                <h3
                  className="card-title"
                  style={{ color: "#007bff", fontWeight: 700 }}
                >
                  Track A Package
                </h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{
                      color: "rgb(200, 61, 115)",
                      fontSize: "2.5rem",
                      padding: "0px 20px",
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              {/* <!-- /.card-header --> */}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12" style={{ textAlign: "center" }}>
                    <form
                      className="contact-form text-center"
                      id="modal-contact-form-data6"
                    >
                      <div className="form-group" id="tracking_number">
                        <Feild
                          name="tracking_number"
                          placeholder="Tracking Number"
                          value={state.tracking_number}
                          onChange={onInputChange2}
                          type="text"
                        />
                      </div>
                      <a
                        href={`${"https://www.aftership.com/track/"}${
                          state.tracking_number
                        }`}
                        target={"_blank"}
                        rel="noreferrer"
                        className={"links"}
                      >
                        <button
                          className="btn btn-large btn-rounded btn-purple btn-hvr-pink modal_contact_btn"
                          id="Trackpackage_submit_btn"
                          type="button"
                        >
                          Track
                          <div className="btn-hvr-setting">
                            <ul className="btn-hvr-setting-inner">
                              <li className="btn-hvr-effect"></li>
                              <li className="btn-hvr-effect"></li>
                              <li className="btn-hvr-effect"></li>
                              <li className="btn-hvr-effect"></li>
                            </ul>
                          </div>
                        </button>
                      </a>
                      <br />
                      <style>
                        {`
                                                    .links {
                                                        text-decoration: none;
                                                    }
                                                `}
                      </style>
                    </form>
                  </div>
                  {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
              </div>
              {/* <!-- /.card-body --> */}
              <div
                className="card-footer bg-white p-0"
                style={{ textAlign: "center" }}
              ></div>
              {/* <!-- /.footer --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
