/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-redeclare */
import { useContext, useEffect, createContext, useReducer } from "react";
//import { act } from 'react-dom/test-utils';
import {
  auth,
  socialAuth,
  googleAuthProvider,
  SignInWithPopup,
  SignInWithEmailAndPassword,
  CreateUserWithEmailAndPassword,
  SendPasswordResetEmail,
  SignOut,
  facebookAuthProvider,
  twitterAuthProvider,
  db,
  Collection,
  GetDocs,
  Query,
  Where,
  DeleteDoc,
  OnSnapshot,
  GetDoc,
  SetDoc,
  AddDoc,
  UpdateDoc,
  Doc,
  functionss,
  timeStamp,
} from "../firebase";
import sendEmail, { sendEmailForm } from "../email.js";

export var AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

//var functions = require("firebase-functions");

//var { Logging } = require('@google-cloud/logging');
// Instantiate the StackDriver Logging SDK. The project ID will
// be automatically inferred from the Cloud Functions environment.
//var logging = new Logging();
//var log = logging.log('custom-MGK-Express-Log');

// This metadata is attached to each log entry. This specifies a fake
// Cloud Function called 'Custom Metrics' in order to make your custom
// log entries appear in the Cloud Functions logs viewer.
// var METADATA = {
//   resource: {
//     type: 'cloud_function',
//     labels: {
//       function_name: 'Site-Wide-Logs',
//       region: 'us-central1'
//     }
//   }
// };

function authReducer(state, action) {
  //console.log("inside reducer");
  switch (action.type) {
    // case "login_user":
    //   //console.log("returning login reducer result.");
    //   return {
    //     ...state,
    //     loggedIn: action.payload.loggedIn
    //   };
    case "auth_change":
      //console.log("Auth listener dispatch results.");
      //console.log(action.payload);
      return {
        ...state,
        currentUser: action.payload.currentUser,
        loading: action.payload.loading,
        loggedIn: action.payload.loggedIn,
      };
    case "gauth_change":
      //console.log("Auth listener dispatch google auth results.");
      return {
        ...state,
        currentUser: action.payload.currentUser,
        loading: action.payload.loading,
        loggedIn: action.payload.loggedIn,
      };
    case "fauth_change":
      //console.log("Auth listener dispatch facebook auth results.");
      return {
        ...state,
        currentUser: action.payload.currentUser,
        loading: action.payload.loading,
        loggedIn: action.payload.loggedIn,
      };
    case "tauth_change":
      //console.log("Auth listener dispatch twitter auth results.");
      return {
        ...state,
        currentUser: action.payload.currentUser,
        loading: action.payload.loading,
        loggedIn: action.payload.loggedIn,
      };
    case "logout_user":
      //console.log("dispatching logout action");
      //console.log(action);
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
        currentUser: action.payload.currentUser,
      };
    case "fetch_address":
      //console.log("dispatching fetch_address action");
      //console.log(action);
      return {
        ...state,
        airFreightAdd: action.payload.airFreightAdd,
        seaFreightAdd: action.payload.seaFreightAdd,
        mailbox_Num: action.payload.mailbox_Num,
      };
    case "fetch_packages":
      //console.log("dispatching fetch_packages action");
      //console.log(action);
      return {
        ...state,
        packages: action.payload.packages,
        readyPack: action.payload.readyPack,
        inTransPack: action.payload.inTransPack,
        points: action.payload.points,
        balance: action.payload.balance,
        FetchPackUnsubscribe: action.payload.fetchPackUnsubscribe,
      };
    case "fetch_users":
      //console.log("dispatching fetch_users action");
      //console.log(action);
      return {
        ...state,
        packages: action.payload.users,
      };
    case "fetch_users_staff":
      //console.log("dispatching fetch_users action");
      //console.log(action);
      return {
        ...state,
        staffUsers: action.payload.staffUsers,
      };
    case "fetch_user_dashboard":
      //console.log("dispatching fetch_user_dashboard action");
      //console.log(action);
      return {
        ...state,
        singleUser: action.payload.singleUser,
      };
    case "fetch_packages_delivery":
      //console.log("dispatching fetch_address action");
      //console.log(action);
      return {
        ...state,
        packagesForDelivery: action.payload.packagesForDelivery,
      };
    case "ready-count":
      //console.log("dispatching ready count action");
      //console.log(action);
      return {
        ...state,
        readyPack: action.payload.readyPack,
      };
    case "fetch_userinfo":
      //console.log("dispatching fetch user info action");
      //console.log(action);
      return {
        ...state,
        userInfo: action.payload.userInfo,
        loggedIn: action.payload.loggedIn,
        userRolef: action.payload.userRolef,
        currentUser: action.payload.currentUser,
        staffUsers: action.payload.staffUsers,
      };
    case "find_singlePack":
      //console.log("dispatching single package by tracking number result");
      //console.log(action);
      return {
        ...state,
        singlePackage: action.payload.singlePackage,
      };
    case "find_packages_by_daterange":
      //console.log("dispatching single package by tracking number result");
      //console.log(action);
      return {
        ...state,
        rangeOfPackages: action.payload.rangeOfPackages,
      };
    case "clear_search_res_staff":
      //console.log("dispatching clear single package search");
      //console.log(action);
      return {
        ...state,
        singlePackage: action.payload.singlePackage,
        singlePackageId: action.payload.singlePackageId,
      };
    case "fetch_reffpoints_info":
      //console.log("dispatching fetch reff points final");
      //console.log(action);
      return {
        ...state,
        reffPoints: action.payload.reffPoints,
      };
    case "fetch_points_history":
      return {
        ...state,
        pointsHistory: action.payload.pointsHistory,
      };
    case "update_reffpoints_info":
      //console.log("dispatching fetch reff points final");
      //console.log(action);
      return {
        ...state,
        reffPoints: action.payload.reffPoints,
        rangeOfPackages: action.payload.rangeOfPackages,
      };
    case "fetch_consistentuserinfo":
      //console.log("dispatching fetch reff points final");
      //console.log(action);
      return {
        ...state,
        consistentusers: action.payload.consistentusers,
      };
    case "add invoice":
      return {
        ...state,
        invoiceDetails: action.payload.invoiceDetails,
      };
    case "fetch_Rates":
      return {
        ...state,
        Rates: action.payload.Rates,
        RatesContd: action.payload.RatesContd,
        AddLB: action.payload.AddLB,
      };
    case "update_Rates":
      return {
        ...state,
        Rates: action.payload.Rates,
      };

    case "update_RatesContd":
      return {
        ...state,
        RatesContd: action.payload.RatesContd,
      };
    default:
      return state;
  }
}

function AuthProvider({ children }) {
  var emailServiceId = process.env.REACT_APP_emailServiceId;
  var emailVerifyTemplate = process.env.REACT_APP_emailVerifyTemplate;
  var emailContactTemplate = process.env.REACT_APP_emailContactTemplate;
  var emailNewPackageTemplate = process.env.REACT_APP_emailNewPackageTemplate;
  var emailNewPreAlertTemplate = process.env.REACT_APP_emailNewPreAlertTemplate;
  var emailNewInvoiceUploadTemplate =
    process.env.REACT_APP_emailNewInvoiceUploadTemplate;
  var emailInvoiceDeleiveredTemplate =
    process.env.REACT_APP_emailInvoiceDeleiveredTemplate;
  var emailPaidInvoiceDeleiveredTemplate =
    process.env.REACT_APP_emailPaidInvoiceUploadTemplate;
  var emailNewScheduleDeliveryTemplate =
    process.env.REACT_APP_emailNewScheduleDeliveryTemplate;
  var emailUserId = process.env.REACT_APP_emailUserId;
  var currentUser = undefined;
  var loading = true;
  var loggedIn = false;
  var airFreightAdd = undefined;
  var seaFreightAdd = undefined;
  var mailbox_Num = "";
  var packages = undefined;
  var packagesForDelivery = undefined;
  var singlePackage = undefined;
  var singleUser = undefined;
  var rangeOfPackages = undefined;
  var singlePackageId = undefined;
  var readyPack = undefined;
  var inTransPack = undefined;
  var users = undefined;
  var staffUsers = undefined;
  var points = 0;
  var reffPoints = undefined;
  var balance = 0;
  let consistentusers = undefined;
  let FetchPackUnsubscribe = undefined;
  var pointsHistory = undefined;
  var Rates = undefined;
  var RatesContd = undefined;
  var AddLB = undefined;

  var userInfo = {
    contactNumber: "",
    email: "",
    fullName: "",
    verified: false,
    verifiedemailsent: false,
    addressLine1: "",
    addressLine2: "",
    city: "",
    postalCode: "",
    stateOrparish: "",
    dateCreated: "",
    mailbox_Num,
  };

  var invoiceDetails = [];

  var userRolef = "";

  //sign up user
  var signup = function signup(currentstate, payload) {
    //retuns a promise
    var result = CreateUserWithEmailAndPassword(
      auth,
      currentstate.email,
      currentstate.password
    )
      .then(async function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        var payloadf = {
          ...payload,
          currentUser: user,
          loading: false,
          userInfo: {
            ...userInfo,
            fullName: currentstate.fullname,
            email: currentstate.email,
            contactNumber: currentstate.contact,
          },
        };
        return payloadf;
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        //console.log(error.code);
        if (
          errorCode === "auth/email-already-exists" ||
          errorCode === "auth/email-already-in-use"
        ) {
          return "The email address is already in use by another account.";
        }

        if (
          errorCode === "auth/network-request-failed" ||
          "auth/internal-error"
        ) {
          return "Unable to signup at this time";
        } else {
          return "Unable to login at this time";
        }
        //var errorMessage = error.message;
        //console.log(error.message);
        // The email of the user's account used.
        //var email = error.email;
        //console.log(error.email);
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        //console.log(error.credential);
        // ...
        //return error.message;
      });
    return result;
  };

  var staffSignup = async function staffSignup(currentstate) {
    //retuns a promise
    const password = "12345678";
    const createNewMember = functionss.httpsCallable("createNewMember");
    let email = currentstate.email?.trim();
    var result = createNewMember({ email, password })
      .then(async function ({ data: user }) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        //console.log(user);
        return user;
      })
      .catch(function (error) {
        //Handle Errors here.
        var errorCode = error.code;
        console.log(errorCode);
        var errorMessage = error.message;
        console.log(errorMessage);
        //The email of the user's account used.
        var erroremail = error.email;
        console.log(erroremail);
        //The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(credential);
        // ...
        return error.message;
      });
    return result;
  };

  var login = function login(email, password, payload) {
    //retuns a promise
    //console.log("about to log the user in.")
    var result = SignInWithEmailAndPassword(auth, email, password)
      .then(async function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        var payloadf = { ...payload, currentUser: user, loading: false };
        return payloadf;
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        //console.log(error.code);
        if (
          errorCode === "auth/user-not-found" ||
          "auth/wrong-password" ||
          "auth/invalid-email"
        ) {
          return "Email / Password Incorrect";
        }

        if (
          errorCode === "auth/network-request-failed" ||
          "auth/internal-error"
        ) {
          return "Unable to login at this time";
        } else {
          return "Unable to login at this time";
        }

        //var errorMessage = error.message;
        //console.log(error.message);
        // The email of the user's account used.
        //var email = error.email;
        //console.log(error.email);
        //The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        //console.log(error.credential);
        // ...
        //return "success";
      });
    return result;
  };

  var gLogin = async function gLogin(payload) {
    //retuns a promise
    //console.log("about to log the user in using google.")
    var result = SignInWithPopup(auth, googleAuthProvider)
      .then(async function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        var payloadf = {
          ...payload,
          currentUser: user,
          loading: false,
          userInfo: {
            ...userInfo,
            fullName:
              user.displayName !== null && user.displayName !== undefined
                ? user.displayName
                : "",
            email:
              user.email !== null && user.email !== undefined ? user.email : "",
          },
        };
        return payloadf;
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        console.log(error.code);
        var errorMessage = error.message;
        console.log(error.message);
        // The email of the user's account used.
        var email = error.email;
        console.log(error.email);
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(error.credential);
        // ...
        return null;
      });
    return result;
  };

  var fLogin = function fLogin(payload) {
    //retuns a promise
    //console.log("about to log the user in using facebook.")
    var result = SignInWithPopup(auth, facebookAuthProvider)
      .then(async function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        var payloadf = { ...payload, currentUser: user, loading: false };
        return payloadf;
      })
      .catch(function (error) {
        // Handle Errors here.
        //var errorCode = error.code;
        //console.log(error.code);
        //var errorMessage = error.message;
        //console.log(error.message);
        // The email of the user's account used.
        //var email = error.email;
        //console.log(error.email);
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        //console.log(error.credential);
        // ...
        return null;
      });
    return result;
  };

  var tLogin = function tLogin(payload) {
    //retuns a promise
    //console.log("about to log the user in using twitter.")
    var result = SignInWithPopup(auth, twitterAuthProvider)
      .then(async function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        var payloadf = { ...payload, currentUser: user, loading: false };
        return payloadf;
      })
      .catch(function (error) {
        // Handle Errors here.
        //var errorCode = error.code;
        //console.log(error.code);
        //var errorMessage = error.message;
        //console.log(error.message);
        // The email of the user's account used.
        //var email = error.email;
        //console.log(error.email);
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
        //console.log(error.credential);
        // ...
        return null;
      });
    return result;
  };

  var logout = function logout(payload) {
    //retuns a promise
    payload.loggedIn = false;
    //console.log(payload.FetchPackUnsubscribe);
    //payload.FetchPackUnsubscribe();
    SignOut(auth).then(function () {
      payload.currentUser = null;
      dispatch({
        type: "logout_user",
        payload: payload,
      });
    }); //return res;
  };

  var resetPassword = function resetPassword(email) {
    //retuns a promise
    var resultf = SendPasswordResetEmail(auth, email)
      .then((res) => {
        // Password reset email sent!
        // ..
        console.log(res);
        return res;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        // ..
      });

    return resultf;
  }; //Fetch mailbox number for customer

  var fetchMailBoxNumberByUserId = async function fetchMailBoxNumberByUserId(
    uid,
    payload
  ) {
    // console.log("User id inside fetchMailBoxNumberByUserId is: ");
    // console.log(uid);
    //console.log("getting ref for MailBoxes");
    //console.log("querying Mailboxes");
    var packArr = [];
    const q = Query(Collection(db, "MailBoxes"), Where("Uid", "==", uid));

    const querySnapshot = await GetDocs(q);
    querySnapshot.forEach((doc) => {
      var res = doc.id;
      packArr.push(res);
    });

    if (packArr.length > 0) {
      // console.log("The mailbox found is:");
      // console.log(packArr[0]);
      payload.mailbox_Num = packArr[0];
    } else {
      //console.log("mailbox does not exist");
      payload.mailbox_Num = "None";
    }
    // console.log("mailbox result b4 return:");
    // console.log(payload.mailbox_Num);
    return payload.mailbox_Num;
  }; //Fetch user info

  var fetchAddress = async function fetchAddress(uid, payload) {
    // console.log("FetchAddress ");
    //console.log("User id is: ");
    //console.log(uid);
    // console.log("fetching mailboxNumber");
    await fetchMailBoxNumberByUserId(uid, payload)
      .then(async function (res1) {
        //console.log(res1);
        if (
          res1 !== null &&
          res1 !== undefined &&
          res1 !== "None" &&
          res1 !== ""
        ) {
          //console.log("Existing user mailboxnumber is: " + res1);
          payload.mailbox_Num = res1; //console.log("fetching addresses");
          const shippingAddRef = Doc(db, "ShippingAddress", "DefaultAddress");
          const shipAddSnap = await GetDoc(shippingAddRef);
          if (shipAddSnap.exists()) {
            var addresses = shipAddSnap.data();
            if (addresses !== null) {
              payload.airFreightAdd = addresses.AirFreight;
              payload.seaFreightAdd = addresses.SeaFreight;
              dispatch({
                type: "fetch_address",
                payload: payload,
              });
            } else {
              console.log("No such address!");
            }
          } else {
            console.log("No such address!");
          }
        } else {
          console.log("creating new mailbox address");
          var loop = "run";
          let genMBox = "";
          do {
            console.log("inside generate loop");
            // eslint-disable-next-line no-loop-func
            try {
              let genRes = generate(uid);
              console.log("check if mbox failed: ", genRes);
              if (genRes !== "failed" && loop !== "stop") {
                genMBox = genRes;
                loop = "stop";
              } else {
                loop = "run";
              }
            } catch (error) {
              console.log(
                "unable to generate mailbox number at this time",
                error
              );
            }
          } while (loop === "run");

          console.log("what is genmbox? ", genMBox);
          if (genMBox !== "") {
            console.log("New user mailbox number is: " + genMBox);
            console.log("fetching addresses");
            payload.mailbox_Num = genMBox;
            const docRef = Doc(db, "Users", uid);
            const docSnap = await GetDoc(docRef);
            if (docSnap.exists()) {
              var user = docSnap.data();
              console.log(user);
              if (user !== null) {
                let user2 = {
                  addressLine1:
                    user.addressLine1 !== null &&
                    user.addressLine1 !== undefined
                      ? user.addressLine1
                      : "",
                  addressLine2:
                    user.addressLine2 !== null &&
                    user.addressLine2 !== undefined
                      ? user.addressLine2
                      : "",
                  city:
                    user.city !== null && user.city !== undefined
                      ? user.city
                      : "",
                  contactNumber: user.contactNumber,
                  dateCreated: user.dateCreated,
                  email: user.email,
                  fullName: user.fullName,
                  postalCode:
                    user.postalCode !== null && user.postalCode !== undefined
                      ? user.postalCode
                      : "",
                  stateOrparish:
                    user.stateOrparish !== null &&
                    user.stateOrparish !== undefined
                      ? user.stateOrparish
                      : "",
                  verified:
                    user.verified !== null && user.verified !== undefined
                      ? user.verified
                      : true,
                  verifiedemailsent:
                    user.verifiedemailsent !== null &&
                    user.verifiedemailsent !== undefined
                      ? user.verifiedemailsent
                      : true,
                  mailbox_Num: genMBox,
                  rPoints:
                    user.hasOwnProperty("rPoints") && user.rPoints !== ""
                      ? user.rPoints
                      : "0",
                };
                //console.log(user2);
                //update user mailboxnum
                const usersRef = Doc(db, "Users", uid);
                await UpdateDoc(usersRef, user2);
                const shippingAddRef = Doc(
                  db,
                  "ShippingAddress",
                  "DefaultAddress"
                );
                const shipAddSnap = await GetDoc(shippingAddRef);
                if (shipAddSnap.exists()) {
                  //console.log("Document data:", shipAddSnap.data());
                  var addresses = shipAddSnap.data();
                  if (addresses !== null) {
                    payload.airFreightAdd = addresses.AirFreight;
                    payload.seaFreightAdd = addresses.SeaFreight;
                    dispatch({
                      type: "fetch_address",
                      payload: payload,
                    });
                  }
                } else {
                  // doc.data() will be undefined in this case
                  //console.log("No such document!");
                }
              }
            }
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        }
      })
      .catch(function (error) {
        console.log("Error at getting user:", error);
      });
  };

  var addUserInfo = async function UserInfo() {
    // console.log("User id is: ");
    // console.log(uid);
    // console.log("fetching user");
    let uidArr = [
      "0MtDQLBI5tQfQQT5lGntlo8ehzN2",
      "0WNCzh7j4YfQ8r3E8iczE3gpYwH2",
      "0cmgPv4AfofQhMnCZSbafbFnIV22",
      "0lh3RQzNX3YiWZaBzUYDd3LL7f62",
      "0x9JAR3urCUZGfOrbPs05VO4vHo1",
      "1SCFaCj4c1YczyRNBIe9wMCFFCi2",
      "1wUKKGCfqVTYcEwto4YWVatW9gY2",
      "21V6erNiiaZqQwZf8QHGxZsQ3Th1",
      "2VyQ4HrZ2Wd84KIYDvGiPEWSYxu1",
      "2YiaCU22jadH8h0RdXHyHDwwtw93",
      "2h7ar6fMWybxDUuoCquYfC6wuzU2",
      "2nmD2h0r9rd8mryJjFI4y0rKmU12",
      "2rtzDu4wxDTHha6LMOciXN6SI9w1",
      "3DHWbA9jC3hMmOA9gU2J0sCgQmN2",
      "3OU1eFJqWAaScgRzvMNQztBqm8Y2",
      "3TPsfhcRyuROMpyDYntWQTyQ4jy2",
      "3WXow57nEVUQeBZve6Vi7Ck1uL32",
      "3pO7iiFAUad0jI0fsHApxNIgszJ2",
      "41AI5j55y6PeBh64piU3wiqTmZs2",
      "4LwTk0Wy8CX4kf30LQmo0fJljzB2",
      "4PfSnV9IifMnv840R3aDM2QPKd22",
      "4U3tgrRzVmWbZolfapfY42E4V7q2",
      "4WWeWEvSdRPxF0PWVySHpb4ST1z2",
      "4eyn7JtN95cSxjTme8Dc2wiiSGC2",
      "4jTOptcnrvZDMAfFnWKbl8hygvV2",
      "5HNGnJZknvUPTQwlyBz3mVbV9hE3",
      "5PSbzOcMNCdmZfxZLqfKkxExmhu2",
      "5QHPlKrPP2f1CTGFxgSRJDdAGAP2",
      "5XpcitPnJsbDIbwJSc4l6XsJuo43",
      "5kftfpRx7FgRYu3BXlKI3kDPD3K2",
      "5sQwvpKvdhXNH3JWUecOSdD5vHf1",
      "6KxQ3xhQSsex423JwZu7tQqQEoN2",
      "6Y60KloFqhQgYEbhHwALnZXN6F43",
      "6fNlrxNjb1SSXRUm2yVDfbsdxlY2",
      "6qsbJi9LfSXIl40Upf5p1BXllyc2",
      "73l8JVhe8qO80LTiHSStl3xA4L23",
      "79PlSbED4lNrHAR0ykZF0X9FLmv1",
      "7Bkb07tTbZN3wG7V8EEg2sNbA7v2",
      "7IBjwAi9qDhYDQfNigKUlmNh2BL2",
      "7Z6wTLv7cdQfcAfs7jC9i6bPK9y1",
      "7yMM1O99qhdwphpPj1VJ11BfHbz1",
      "81EWJArx1rgl2YjE2gIidRq4Iqp1",
      "86cgSWTsvre2phATdiGe6Tfb9vq1",
      "878lF1XnFkTcbvoVZ0eFC9U6ohU2",
      "8CcLnw9xsCgDwwkAXv2u0VkUw6r2",
      "8I20dBV5R1RlFINeidqxdR0cPxr2",
      "8OeMpV2bkzNulhfDEtkQPmdQngt1",
      "8VilSlyPPAZTzKLbE2vVbASXTjr2",
      "8fJlusruIFOGS8lN4pZGeHIR4EW2",
      "92ezvXPIPLR6ztjNy6fvT4vOsLY2",
      "96MPvuMBModU1piEFmoRN388qrD3",
      "970YtSr4VvgqEzGHfyyyVeh4pBD2",
      "9CEvt4jtjlPPzZMWT2lxgtT65ma2",
      "9bLnDxqxkBgJ6WrTpG1x4zmcKFH3",
      "9hrjYYXWgYf1WW2dkx7HBMCm6q52",
      "9t0dtQmFkoPcnEdYYvnZ8dLqIjz1",
      "9xcJi8QEdhNtR98URzJcVPeZSk82",
      "9ypooSq1hYV93DzXO7yHxwa52O72",
      "AHInp1jhc1OsKEr4Wsv13z7WMyU2",
      "AHw1bd416gTpU7Zlm9hmxaLeK6o2",
      "AJz7XWwc1DWIjWh9HZRkniF1N2N2",
      "AVp9OnwPtLb4c8v5pqixEQPAi8c2",
      "AbSX4pLFmrS0bZQFYSxTPO7AWLJ2",
      "AehV61HIyMXr0NQRJxXxygWk66B3",
      "Az8XVzrEBkdk0eHUuwYENqD1Ipf2",
      "B2384uzv0xW1yGVuoabDD8vSI1B2",
      "B5S3wXaVOOdpeR5qDZivD6CS86Y2",
      "B6tOjeqgciQD03mrsjD5Wrf1MR53",
      "BEdMm0D8HQcmkF6ITeTmmSgpnyv1",
      "BgN5LfxfuOecoMeHKzhKdfC3Y7F3",
      "Bk12TudblkUXOefwWADXsunhFcq1",
      "BlXCCEZXgeZagCACJe1VEeT3vNv2",
      "C08KCkrlj8W3nl9pJtLtIyEiH0u2",
      "CTA6VB0SRBZdzbjP5AKNP2vTjB32",
      "CZFl69lbCPOqA8VGbSnmnUPFbVn1",
      "CmvLNH94AoMGqs3ppw57CJDQ43E3",
      "DHrlLqxKaDXn4OYPQCAXoOvHerf1",
      "DKQCaZyyuScdAnr5rhBK1XWoG5c2",
      "DPDF3XIlEIaB6WJR4HFijxC7PZP2",
      "DQ64mhPttgbFqDiYUlmpKOGRpEr1",
      "DhCe9KjYO4Y8ZjwzxbmYoCg2DQG2",
      "Do6RoY8DvYawocB9TcwT9L14G1E3",
      "E8z3qAeCP3PVcUsfA9jKzl0226u2",
      "EWK4Y6TiTTRfIKRNjLIK7dCU1II2",
      "EWoxPs36KVb3qq40cWb9j8X2VKw2",
      "EcOARCTS0uPcSw8iTAMh1V89qhi2",
      "F8bnuDPf4Wd2M73aH3XXpzJmmog2",
      "FTFFKXFmzeWQSm8vXSCtBKAcxLP2",
      "Fk39jqQFu1SOZSphArTwlT0RDLc2",
      "FosRpWCQUANVkdwxbclP5LYrIQp2",
      "FsxuP2kzURR1yI1ls6Vw9xWbi662",
      "G1ukP17fKeYlFWp1ShDOYOFeaVF2",
      "G1wxDCCOpVdOkZbwOprnKUuFbFq2",
      "G5Whatlxi2Qe7jrRxg0CgUWVjtz2",
      "GE4j1ZNoujaolJyPfn8qYXMuGfL2",
      "GFyIN7GlZtbdK8KaAF7H0Un4qHr1",
      "GOuU017UdKZvSQaqW6zpx9NVe7O2",
      "GTKC20Aq8icy3zPs1B93rsXD5Sn2",
      "Gd1zZqccnfhYFb2d4YIgLTOwqi72",
      "GgsTamSSUoVndPSihoLgIhUw1K52",
      "GoHzDbabECc1kmIL54Upp70F6K73",
      "GpwREkiI0LSL7akKuqET7u36Wez1",
      "GpyguM7UrShWlng1AuRCBLcfi682",
      "GqsrK9yMZgSfnYTEtj1vDSkgzS02",
      "GrXiz9075xh1dekvrhLlicbUP4k2",
      "GuQAiE0tHrd5yW9fHhlL9PXmJwj1",
      "GvsZ335ocvfPHsCGnaNOZHP05Qs2",
      "GyK9wodcUnPraKhFHJInrO1aIgu2",
      "H2cptSmFdOWhGIM9sGDFgtU8gqK2",
      "HS0RGyrv6DUaYs9kujBZPDH6kVv1",
      "Hb2KXWtKk1aB1th2uMd37f7g5A53",
      "HdcuSkBkxffXMwVvZ1s8lXgwkBr1",
      "I33PsGWF6PXFlxPBoVx2We8zC9z2",
      "I3x1nVgGyObizwVPOT6UkOReOqt2",
      "I66LmnvrYmVyEcwzXEiV8yPgvNS2",
      "IHdQIEEOj3ZRfdNkAqEzFgqPVxQ2",
      "INzIcTI6oGct7x1L9k8lpi1hsXp1",
      "IU77ebYF2lVtAKnlkLzhhgGBGxj1",
      "IUDTmrbVJbTtGfwrpZ5P3kh2e5m1",
      "IfwJrVFTTUgYaCzmAIsT3yw9Du72",
      "Ir4CGyykj3bOOoxDxyBUBg6RcIw1",
      "J60VYvnMJueHmK2ECFHksYOzLqz2",
      "JCHl3mmSoRMJkt2jBFXnSCRTYzG2",
      "JGRrYbDMjdSyBUMhd29KTlxANm72",
      "JIEnRirxtfOnt27YyfNrGtYGZoj2",
      "JVSyMWezYnVeMWfArKzjdQIzWMq1",
      "JVsLwMQmKPOC9ETNeYggcdKyMD42",
      "JZ8umV5dqJZaRoFUCfkhbkdCrE52",
      "K0QAKHpFMJeZ26shAK3Cr04YVNu2",
      "K2MBEKVj0Db1ApJieUsSJoYHRHE2",
      "KJFUrPiCapdT7oLrHp27LrD9fAx1",
      "KbGLCh7LYwhXE0sOhcTaWNjJOYv1",
      "KjT4XDOfQWP41qR0noiB5RRyzoZ2",
      "L1J3BozujJUl4i99uciql0imdrL2",
      "LFcKi9PKATckVHFNbyhAvSVElGl2",
      "LbebWTUihng2LBdyCs9L5ANwDIa2",
      "LgFFDXWrSOec4CjHFnIK8AmTw542",
      "LljBJc9gTgUH3ZoUA8pl4VhEJmf1",
      "LrMybXwW1QTgEqcwjLvjqgXLMmt1",
      "Lu6OJ9dM0gZzgvbKWSLPXiWuca43",
      "MJRSQ2r2B6cEx5LYC3OpDEuDre33",
      "Ma5JFYkBQ9Xr59qbRzccnvlVL4K3",
      "Mg24zCGA8abY60KhImPskjjVa9R2",
      "MsOrz7vrS3Vn2lCCG3sIloJZAO62",
      "Mw0A8TmSphRk5PmUBl67EIofzeg1",
      "Mw85GLaWIKheVmyZ0ggWoiuJPVL2",
      "Mwr4TOnVQqQxfEPLo3Cf3AgtcKC2",
      "NXX4SDuARIWaEPVu9HIjeJtW7DQ2",
      "NYaWGpU7iIeKBXJCWqWFS4aMJ2K2",
      "NZV1uPQ28KXtPwGtgtXN1yPE39N2",
      "Nk7XecBgnWdXnFS85LXZNIpqGEB3",
      "NrVFkmMPWCOAvscVrHGKMHGYv9l2",
      "NrWV3FBU9QQYoeSi8uJX8AuX0662",
      "NshaMG9bSETohkub655kk7CZnPy1",
      "O1TrLFREDbboIUf4LLyjvSkCXNA2",
      "O9ZxSmIzpHWmPGFuInzdxMWNNCw2",
      "ONYoxhmQv3hHlq26DxyqCVTdAar1",
      "OQk29O1rRTbFV2u7IA1bRBSiMQB3",
      "OTFoVqGHB5P7vUNwDqcfTXL5c1l1",
      "ObnBwoLV2nMFrqpzRVUSZ2NLBGz2",
      "OeD6RJTnV3RltEOlXaRwBAJPUlC3",
      "OmiBUKBS1YSiYfvxkzqd9ymKDod2",
      "OsThmf99wQce7eNL0OIDyogd0wo1",
      "Oy5reLc2znbIkeUr429cuTJkH1x2",
      "P2E8ofs3sOayf7n93QIMjw6NVPl2",
      "PRKGXvuQbuW3kCZBM4Gwb3KMFhr2",
      "PdAYrrsxNCOBx2maOvf0Z8uQAtJ2",
      "PoWsfvPRzlSDBp4PLGtOPyCDDVG2",
      "Q4gbeeheJSTb5NFPHXLF4GyhY652",
      "QCk1by5eWAOvsKZ1x5JNblrjPsI2",
      "QKiP0Y39zMX1NwOfNL36W7FaOqa2",
      "QPJZcrJ8fqRPbSLOc5c1QMdIkKC3",
      "Qc5Xk2WeYxglzjEZ6njIGZhHeON2",
      "Qrcx0l76HzfnkGNThteOdCvU2Cw1",
      "QughRzVg6rYA0ns398NVp7zA8BF2",
      "QznczG1kDpflTrv8wcELJQzxyD62",
      "R0rBdrCYcQSVVhcfzC953e1TWM83",
      "R13H55nr0dYncwk1JFDATNhnqRu2",
      "R26Z5sEKlMRK2akgOWTbtppQYPM2",
      "RAITAnYcuUe3tEootbYgbiuaQ2p2",
      "RLb9qXONQpb0IitghEMDxXCEfvl1",
      "RPr07Vbrwye77NJcuuJA2UmOcYw2",
      "RTYVPOt64TPKNR5Fb6NaNEevOWM2",
      "RsM380d0RFbunBzLYcSliGOVf593",
      "RugHAkrlniNXtzwIy4puEnS0c1s1",
      "RusxZMnGR2QaykBw2kEYbzUeDYH2",
      "S6lMms5JrfUmFTXDPn5NQjhx7d92",
      "S9G1R387BXUhiyjNzhzjpqbmmxl2",
      "SE1RFvI0HzVEhSeC4Gw8rOsUiVA2",
      "SR6vZWlUZgWrFvpyK4VAVCb8qcw2",
      "SeZSrfHmnpcRju5I0hYcmfFkC9H3",
      "SoONDobtuGQ3mkl70spe1ukYCgh2",
      "Sxg6QvLWIxTpfTPMKNfgbbCEoMx1",
      "T2rKkjqvUPNqQPGnvBmJEHzVJiE3",
      "TUI8DtLj1CSrUc4s7XPgUVmhkTD3",
      "TfWRNCi3BsNSXz390yUftl9a9Ec2",
      "TlknGsyZWkfij2te5pRTiVcCAGG3",
      "TmEjmx7QrqNFsVZs9k7tbIKtmiR2",
      "TpXIQ6bFZbVd02yocJq6Huhbiq12",
      "U3jdCRSkA3UIMSvVZ28ogJIo52H3",
      "UAW37RMABwbK1ZgdnZMKQhiJqI22",
      "UDrMUyJ4w5anrew1l4dxuQJOwxD2",
      "UjgXR3r98VPZFj6O9fie7tZlBiB2",
      "UlLbzzm5GWcmFWdKuS0QUyZEyjs1",
      "UpZiON2asEZMMh28euoOM721JFe2",
      "UsIzLp3FaUTdqvBbYFaXMSyv7Kr2",
      "UvV0kgPu4bMw39ORauSXCWBbkAW2",
      "UxBA2HLWVgVBEMEIf6YoaZZWsUj1",
      "V8aT9f5boKdVniryJq7e2Zoged52",
      "VF4ljNpBFRMAdW2gY1G5m6dfgeu1",
      "VOrpW1ebzIfg7Wvc28hVniQjy6D2",
      "VP5oOIvh6cXJXtnXmZiexGzJxKx2",
      "VViCq0bu1cNw1VcPrU1RnhQSvOg2",
      "VdfOSnL5n7VmHIdYcgbXOUSFl4f1",
      "VdqKgSNgpVfwsETAdfjnvVh0reu1",
      "VnwEYdtodSNN1dedPGuGU8Gz1wz2",
      "VuZHfyA8NgQ5zG8fxUvtujHM1ta2",
      "W0yXz9pvCIX0gfO8zbqRWoMi6Am2",
      "W1PdueEuTZdMEN0tsU6EabQi47p2",
      "W8jYsxIiEiMYJfF607Yu3sLifId2",
      "WF9Uho6IXkS6p44MPxw6Q1JShYL2",
      "WbxKdsNbfUQJ9GtD2mJTAOZjlXg1",
      "WhfKPMWmTqZYDFaEWm29RAVPY0n2",
      "Wr5GHLzrvNTnCs5ENsZIzQZz9453",
      "X3M2zqNHllbrQSpDhkMnYYSFwe63",
      "XAW49hLPHpRUPgtKLbcXgQguRKh2",
      "XDhRfiOJ8JUG9dU5l4ERRmCgU9t2",
      "XMKaJ5SkchcruhDcnP4VhIssKdj2",
      "XSBuo3NmRVbDiBV67drZCt7FS2h1",
      "XULSVwxFApdz8btCw3YAlsqq2lf1",
      "YfbnuWYWWJhithJurCg3Y94OZwy2",
      "YtkvcUjFK0bUigPKETXrFyrNZAB2",
      "Yu1mahj2wOcNQOxF8wp8OO1DYeL2",
      "Z4uFvy30HHfsr84premvNUOGmvU2",
      "ZWkpzL3Dpig1ES5l1F8fwfqDDEP2",
      "ZeXeWXk60TQfydIbUr4xOfCVgqE3",
      "ZmyPa7Qc5gPHKvX7ulQb46jCbYv1",
      "ZoXpvMjFdRZKX3uO3a8rWyLn65j1",
      "aA7BlfOX4QVAU0ySIyjvzGA42wF3",
      "aIKq3zHEgPf6jZszMjco73Hl1Nk1",
      "aIa4RfHXMQYoryghq3zeNTZS3iB3",
      "aKjEFMoyFrQfRh6HfVq5b1pDcvv2",
      "aMzaWGzuq8RSPGYD4zW8P6X6r502",
      "aaBSbPzxdvc29hocaiHsp5LNudw2",
      "abq7UreGx5U9mQ4G8uTIlL0ANaE2",
      "an9E9xSqfgOY8jsUev6Mxi3ZCaA2",
      "b6KlY2vqteQEHau0oA5za8ZTNau1",
      "bEKvMnItqHdF6rGmpyrtdWiZh8p1",
      "bUelVfiWlYbumdhxv8SqOF9mQo33",
      "bpz00GPsX6Rt65PqjEbUtNDvQZA2",
      "c7W8P90m0PRuUFmGs6lri1FzEGf1",
      "cLrXFclkYoay5R8G9jv2z34Msu43",
      "cSAAOff4gtPhUFsHSp9c5KZ2n8Z2",
      "cYlgipdJjBgNE6Jjt68a8ktxFkT2",
      "cZvY1AzXBhYqmxAMcKUq1aibgp82",
      "chA9nJRJu6WysmJygSVwIzZUhZ42",
      "cjmxXKkU1McCJkog6CM4QSOOzCz2",
      "corOwH3LRLdL0RRnp8ZekHi1I403",
      "crZlxhGzbPYtrHjEnl9vSdmDJpS2",
      "d0RJkpOUm0fX5PDZeNhLVTySHPy1",
      "d31KlWWQTBOy29XPpQfVjT6dhxz1",
      "dIMumbGn0zf3HdMucMBsvBYwYbk2",
      "dhjoqjyrF6Vji6iLF7s7WWt9rMo2",
      "dvZ2nJfMRTbp2YDtQ3LT26lqkdP2",
      "e7SSbyEz7tTAY0w3zA1mzV5dbW33",
      "eOUWHjvsckW9FHG9Hj26xQdau3A2",
      "eQJIVbobtUOWZHDDihGl8k03jk23",
      "eT1wYGJdXQSM98ff0mdLTScawbc2",
      "eUAzvhVFO2e1w0948WmuLJlWyUr1",
      "elBs8fwabLSAnrkGttVyMv96gyq2",
      "fN3VGT9YfsPp6viTDBONA4YuAEG3",
      "fRvAQqXFD0ZYyyQbBuzeiyLdQr63",
      "fZ0bhUR7P2UhfHEfmCphnhp2TS13",
      "fs6MtK8cnZbaNzfHbJIB8ybCV9U2",
      "fuKEtgD7KUd9iwJJ3uP4hffi0Md2",
      "g4lycHFtngWcZv6ziYAwIDBhkTK2",
      "g9KMJTS8HpPH22GxpRhAWu1QdLb2",
      "g9wpgVSx6yOKk5JizTAo3279BCE2",
      "gD3iiEgBkuMsWlZPRl3WgnFZ57j1",
      "gOdzSyaSILUnYropTH7VkmX85OI3",
      "gPb7Se6qxOZtH8kkRoDieSP6uYo2",
      "gUS1raaieKNA4GqsMArabuxsxY33",
      "gjdD5wYZd8O86dE9tZvDkQPcj2y1",
      "gknslHps0nWmOf8sjFkRtxWvPOZ2",
      "h8oYpF2N1Wd73Q0gjYd2IAffERt2",
      "hSE0iADFdAdwKxEzuqQgaV6cm6r2",
      "hY4nKHHoIQU0bw7huJlFJbCJ6JE2",
      "hbUStnZxTbdPSfI45ypU4GRUihs1",
      "hg2H3TL9omaJsDgH2t2w9xqvLPo1",
      "hkINAwEOF3gY0TcvHoD9JvaMrzo2",
      "hqMtzuK0GQdbrCwRO29LJ33xgRU2",
      "ht7M9elvJbTzfqGhbWk5A82sGC72",
      "hxWbMDO53dVK0o7UfKIQMU3wWny1",
      "i1OoiVhaOpeE1VvG6cPbel19Xtz2",
      "iAkqTduR7tgePwdHgQ3i40Vk1rr1",
      "iGCD06FHFDMYckKxYya5Ir8YVS53",
      "iYoe9jXhnWb9n0ET455lW9DOncf1",
      "idKIvwHDz1Wd36TSHLNNrGSLnJ23",
      "igvIR0PjgqeqrlfJ3224XiFEkhn1",
      "ih6qKV6QPsPApq6ibO1G90NqNpg2",
      "ii13pdNRpUdG6FOIVUAHZFSL3sl2",
      "ikBrYPjEu9Oi3KsauR5u7sVJ12N2",
      "ipMT0m04ZXVXDCYTQlCBpoAj0F42",
      "j8hkpYOiINRgxvANniQVqpHDfL22",
      "jBcTOcLQnOVFDIf2h1iGpGhoJxg1",
      "jSLHSQjkRdcUaVAtSM2WmOlVssI2",
      "jqKfm49rrnaTJVvABJfLw53a9FI2",
      "jw1S8wqitGXTQPiYg1mWhU1tGDW2",
      "jwKGOfSlF0gwuoVH2SzDoTWLcfV2",
      "kHcNjXui7DMaDYxoh1PTwfzObim2",
      "kHq7XM2UwQXkWTUjaHBaznc2pX43",
      "kUVDPwVY9VM4FPdHBVEH3dO9Vmu2",
      "ke7dEG7BoZVTD8vQy47BwM47JGy1",
      "koRykL2vb3QemcDkPjfJCFgr5223",
      "kr7vHABec6TvSeqcclGFQyECr4D3",
      "ksX9Efwjofa3YE1o99ihZgU1cAN2",
      "kxTUDiadMiMW8rWaKtFvHlBlBVo1",
      "l0i761DZs4XGN1pci8k9PTAyaPN2",
      "l5EXFtLDTLbladVVXzRRmBTtOXH2",
      "l8xuIcu3J7NQescoff0JZsb8dLu2",
      "lDSrvwkvHDZuNwpXgzl8scKauPu1",
      "lI5IKu0lxKZbNFC4agbKwsqXIy92",
      "lKspmW3mZKTh5DO602PjyyyzqR82",
      "lKsrTI6rqnMrovD8oIO9tCcNLvC3",
      "lQgib6cKFnMnScC9ga3L31fJgp32",
      "lQvatzT8VMP1FItiAyz1Pjo7hGH2",
      "lRD6eeXtJwgjbRAXlA8jXnCGQus1",
      "m77DJNBeabboLqgs3GusdWBbOu13",
      "mVutWYe2ViYYCPGYfETKw7mRISB3",
      "mk0D030r9oRIPxUubBO3A8vUZe22",
      "mrdnpKrpVjaYiZlIRtds3CtRAki2",
      "mvDCzXFV3TOBlO3ZPr8SwDXIeEY2",
      "n3Xkl9ozTGZa0uJHehqvSF9NC512",
      "nG0TUqohAyRFqxilatPb2SqHWIL2",
      "nYaOMOJm2Fb2UHvyATlDQG1aJ7U2",
      "nieWdJh3zKXiHIwhlcW9MvE81GP2",
      "nnVHPsGJU1WmFiYng4kLrKdev3S2",
      "oHhAwQ2pkMa04DbipDVTiCzy5yu1",
      "oJoKEIxfV5aPk853viRW8YgNR1t1",
      "obC7XLkBaAVsEIkLHyL3Q0hVwBE3",
      "oeXczN3PoBbUVfDpwBRwydxgp1N2",
      "onLmb8pMJ8fVTtkFHShnWj1Fgkw1",
      "p2UopXI4K0U9GHnm6OaUp0BcnSV2",
      "pJyZz1QjFpgwzVsNbTUPSsRgnAu1",
      "pKtw1w8IwQRvjMMXiFMw3sUZur63",
      "pLFoaHL0x7RgeresURVyTtwu39j1",
      "pLfZBBsGhYghFuDQLkeudWvleQh1",
      "plT4DMwONihXB9UE6ibBRKttLXW2",
      "q6Rlo0dfHeV8a0O6NCnVZk6cMAw1",
      "qJ3I54CEagYyq0WisOQdUr1pw013",
      "qLr71KjvFrSFcKGgtOLIyWKU8E23",
      "qaJk8hYzfaMeEfmS4O1rqjspm8N2",
      "qvEff6AKHCSe6YGaYbH8UsYTnyn1",
      "r8Y79RhWD6hih0XgnOrxj8Cu0xi1",
      "rFoqJnFrtHQmHsS87MUQxcenuAU2",
      "ra5cozUxODfAMUV9EkIvfB9rhG02",
      "rd95tTk5skVvABykT4svZqkdJvd2",
      "reNWL0MGUoUalFYca7tKtJ98G2A2",
      "rlFxmZjDN0SllEgu2sWzRC1eKE13",
      "s0U83vmLDUUKU4yQ02CVMnflQ353",
      "s3Nf4gNhSWcSOrQjVVDuTIbWeJm2",
      "sEsQKD1ubGaO2eXeLkfLkelcAkA2",
      "sH8k8tBqKpehdwopjRbieN1bnos2",
      "sbMEQi34d8h9PsSKs9XpAROAPJE3",
      "siVCpME9ctTDZCE5IG4lTKpBwK53",
      "sqdxkxBkjYaii78n7xgi69sZJ2o2",
      "szyG4hluZVd2EN1gsRvAmTGbx7Q2",
      "tHB46zlGSoOnBLr1aTFIaRGyqgq2",
      "tOgBn8kkjOR8KSkQFmuMSDAdrIs1",
      "tuhzcJQwOjewKAloQ6c4tA1spB93",
      "tyusKRgV1QNhy86gdTzIo5HLCaq2",
      "uCuwA3s5iOMg2UWTCAZyCbFvdPD2",
      "uI2kWh3iI7O6XDoj2MmxxEVMc0D2",
      "uYBsA8CsNTMUDNB4Y1XXj4Z5hig2",
      "urJcd3QCMnQ2bAvJKA3OwmeVuGh2",
      "vBq4MOuYHLVLagTZJHMbjlmn4Zw1",
      "vDs7Y6URQwOpEX2CmQnV02COxe32",
      "vlma5FEElRSkDYULF5FfyZSGiQZ2",
      "vykPxpeWIGR03re0btEIBZj3Lcs2",
      "w3wv3iMfRBWN9PE3R22QsMXsAMQ2",
      "wOxArhWZiYfdEMXBUWnCoRj2s943",
      "wmM9aoXFY7bhiUMaU73kxGT8Xym1",
      "xQDv0ojAsoMdSaGbFP4nXhZtsgP2",
      "xrZIPThNSBZSYLyixyGsAzllKoB2",
      "xw5tsGznWAWw5sGS433uQIzCueP2",
      "y3VTR1QHxLRulufxdb2C52uVA5m1",
      "yQtVToBlUGZ4Aya6YLRKB7GptkA2",
      "yTR5hzDs6jfF7U56yREPegNKt9F3",
      "ymhaxrF4EHPTkbR0Xi38iyQvHR53",
      "ysSeNcbV2lUy9I2hJFqwDTmR1vd2",
      "z3gbRJF7PpOcSt6Kj8yoEpmf1jB3",
      "z7ilOo363ZXrB8sFxo07AQFafN52",
      "z9Efj0k2nRcBSPDJwu0FCgSLuu43",
      "ztkwnLdoJLb5yVmELVwDeawXv0v2",
      "zv6WR5GmHzazgQHdFnnkoIqd3i72",
      "zw1tUfgyw2dYiZzeYog3FO3IUyx1",
      "zwkK8PkhC6UIufSbnGdES4or60B3",
    ];

    let timeArr = [
      1609620743131, 1610035777073, 1621807649826, 1610069538422, 1627655647957,
      1614360289116, 1637730271784, 1638153475555, 1614708571868, 1629514293425,
      1614143220569, 1639100593601, 1619987632227, 1636272352360, 1609633312037,
      1611177817562, 1627267951045, 1627388819331, 1631322616512, 1612738900448,
      1609872500232, 1626904387416, 1622313542582, 1609539632502, 1614020768625,
      1620269980833, 1612738619411, 1631719822343, 1621511436578, 1611091785824,
      1624927536693, 1630430339368, 1622093341268, 1641608801823, 1611518855360,
      1636671459964, 1609539757455, 1620479787999, 1610067390529, 1643210336991,
      1620324253747, 1640882983079, 1619885671600, 1616015618904, 1631498856004,
      1609540273676, 1609539355503, 1631743350113, 1642543850727, 1627788774863,
      1609711256286, 1609539662241, 1609366388135, 1611008517170, 1615605255885,
      1618708854134, 1626230803829, 1631260752719, 1609633158421, 1630454661911,
      1613526104977, 1638818405838, 1612569624848, 1610026668302, 1631630369789,
      1611458982932, 1616345124895, 1629400422742, 1619540573823, 1609641770038,
      1615772542608, 1610299050298, 1636206583233, 1609718690586, 1618243195511,
      1611586055255, 1609538507665, 1643295997225, 1613531509218, 1634471054793,
      1609538377207, 1620081086972, 1623166253787, 1609712155478, 1622397917067,
      1629940485161, 1619038835228, 1613952349677, 1611031194663, 1612383366670,
      1619209228836, 1609633106558, 1609538976464, 1609538186412, 1609539842991,
      1637254814668, 1609539034553, 1636290686021, 1610033840761, 1631368378680,
      1609559177062, 1612637131608, 1609621167044, 1615558108753, 1631627138765,
      1611435900125, 1630333003129, 1619915155949, 1630359110260, 1610069726892,
      1621538212719, 1609090247649, 1609895354037, 1618957937414, 1622949661305,
      1610296656849, 1621222366256, 1610033440491, 1609538926672, 1611620600930,
      1611189965080, 1629743314886, 1609597668990, 1609711887531, 1610989401879,
      1609623726970, 1638112453598, 1622104918572, 1618327284382, 1621373964130,
      1619672426806, 1610394629988, 1609539141841, 1609537853590, 1617231260270,
      1613784951363, 1609514894069, 1612396914141, 1613686211858, 1636247565830,
      1633140151402, 1629691352372, 1610053824623, 1614297658860, 1613072003562,
      1609537741402, 1609539073711, 1636391740347, 1630497317846, 1620163017606,
      1610296675892, 1620258358379, 1625007625071, 1631478603829, 1618287404096,
      1622806702990, 1633666320405, 1614197912007, 1618636030465, 1610069991297,
      1624049014118, 1617332380386, 1630400026502, 1609537982835, 1627033945183,
      1609896815862, 1640646645380, 1618110479495, 1609597796288, 1609623437178,
      1610851589352, 1612831498778, 1609538545012, 1613054420683, 1638826903782,
      1610068220935, 1623687586523, 1638891955344, 1617801513102, 1637034406464,
      1609633226428, 1619809420790, 1630526841325, 1615921625939, 1609539875364,
      1641483209449, 1637953338149, 1631737852065, 1621903292988, 1611104788960,
      1610354308347, 1609539589273, 1637616352359, 1609539316232, 1619233554937,
      1624973403938, 1621288918947, 1620134606984, 1638925593539, 1609995550940,
      1634745436257, 1611248053875, 1621537413819, 1616538519837, 1609539553802,
      1610326650751, 1609622182528, 1622740695631, 1610197928519, 1641434705803,
      1612057725463, 1609539693683, 1609540144730, 1609642236726, 1624331854827,
      1639964991529, 1630640923757, 1609554610104, 1621702474393, 1610049565454,
      1618096210721, 1613608929114, 1609539724320, 1640190286712, 1621861899808,
      1611021959267, 1622762505310, 1609712064014, 1609597715558, 1633129593933,
      1619144454887, 1609597614542, 1609891917552, 1611193776821, 1619850197741,
      1617116254182, 1642554442455, 1631459305569, 1609633012392, 1610068089638,
      1627606847471, 1609538311542, 1616634960017, 1627660807812, 1609633365853,
      1630193516652, 1613349829764, 1632166350271, 1610147718006, 1610289637872,
      1613965371488, 1618144645213, 1633979176206, 1611938888675, 1609714699175,
      1609711731156, 1610069011791, 1609539181718, 1625338362578, 1629577822745,
      1609711987469, 1623292183825, 1613774351534, 1624463944913, 1640447205602,
      1611351726072, 1619791388288, 1609623575355, 1609537912155, 1626411101451,
      1610117296421, 1642861043393, 1629170390528, 1639512633152, 1609539265138,
      1610068577125, 1630460117230, 1609595776421, 1619658982760, 1609558969692,
      1621254027666, 1621812454028, 1612426236042, 1609890495014, 1642351326803,
      1609538022885, 1609539465191, 1642019340768, 1619691610091, 1642878161725,
      1621224474935, 1621688508859, 1637881074854, 1626539860519, 1609711366075,
      1620240807194, 1630587158054, 1609432391358, 1619564409762, 1609641671745,
      1619204967576, 1626524301706, 1621437427583, 1621007351846, 1609538585393,
      1620484094150, 1609538273671, 1634262698585, 1630162084277, 1611595113270,
      1619381008026, 1610660743891, 1618286271479, 1633807546782, 1633453244535,
      1640711145384, 1630342670480, 1609895290195, 1612237741814, 1635942057009,
      1609538085247, 1618803486336, 1609538731050, 1619993521374, 1609783706623,
      1610148869941, 1636073768249, 1611178951219, 1634686813744, 1619304547693,
      1628878969711, 1611592923620, 1609871444617, 1626994776666, 1610035730857,
      1623853363291, 1637596972711, 1611111350386, 1612741321963, 1609538684476,
      1609539906904, 1621221466278, 1626903965657, 1609621996275, 1612034654810,
      1609539390042, 1625103636630, 1609349520898, 1610067523927, 1619877094745,
      1639954537571, 1609537456990, 1629653513115, 1611931646335, 1635006171674,
      1618132896209, 1609539432110, 1624319352880, 1632689265169, 1627802566952,
      1632157967479, 1621276097348, 1620228315385, 1609538788632, 1609622363721,
      1609539958852, 1625059048903, 1630447201568, 1611175140770, 1617116195004,
      1610030960585, 1625928796634, 1609539810458, 1639267532555, 1630869641993,
      1609558578516, 1614209638754, 1642993252601, 1615565269250, 1638066754810,
      1626448350317, 1640347322953, 1609539498477, 1637189153822, 1612206963930,
      1636261071080, 1626457524723, 1613584113866, 1611941080189, 1614028080273,
      1610068360718, 1610297777330, 1612352188680,
    ];

    for (let i = 0; i < uidArr.length; ) {
      await db
        .collection("Users")
        .doc(uidArr[i])
        .get()
        .then(async function (doc) {
          console.log("Checking user result for fetch user info");
          if (doc.exists) {
            // console.log("user exist");
            // console.log(doc.data());
            // console.log("what is inside payload for fetch user info");
            // console.log(payloadf);
            //Convert to City object
            var user = doc.data(); //console.log("verify email sent fetch user check ? " + user.verifiedemailsent);

            if (user !== null) {
              console.log("checking mailbox");
              if (user.mailbox_Num === undefined) {
                await db
                  .collection("MailBoxes")
                  .where("Uid", "==", uidArr[i])
                  .get()
                  .then(async function (snapshot) {
                    let user2 = {
                      addressLine1:
                        user.addressLine1 !== null &&
                        user.addressLine1 !== undefined
                          ? user.addressLine1
                          : "",
                      addressLine2:
                        user.addressLine2 !== null &&
                        user.addressLine2 !== undefined
                          ? user.addressLine2
                          : "",
                      city:
                        user.city !== null && user.city !== undefined
                          ? user.city
                          : "",
                      contactNumber: user.contactNumber,
                      dateCreated: user.dateCreated,
                      email: user.email,
                      fullName: user.fullName,
                      postalCode:
                        user.postalCode !== null &&
                        user.postalCode !== undefined
                          ? user.postalCode
                          : "",
                      stateOrparish:
                        user.stateOrparish !== null &&
                        user.stateOrparish !== undefined
                          ? user.stateOrparish
                          : "",
                      verified:
                        user.verified !== null && user.verified !== undefined
                          ? user.verified
                          : true,
                      verifiedemailsent:
                        user.verifiedemailsent !== null &&
                        user.verifiedemailsent !== undefined
                          ? user.verifiedemailsent
                          : true,
                      mailbox_Num: "",
                    };
                    //console.log("Logging MailBox");
                    snapshot.docs.forEach(function (doc) {
                      console.log(doc.data());
                      user2.mailbox_Num = doc.id;
                    });

                    console.log("about to update " + uidArr[i]);

                    await db
                      .collection("Users")
                      .doc(uidArr[i])
                      .set(user2)
                      .then(function (doc) {
                        console.log(i);
                        i = i + 1;
                      })
                      .catch(function (error) {
                        console.error("Error writing user info: ", error);
                        i = i + 1;
                      });
                  })
                  .catch(function (err) {
                    console.log(err);
                    //payload.mailbox_Num = "None";
                    //return;
                  });
              } else {
                console.log("already Exist " + i);
                i = i + 1;
              }
            }
          } else {
            console.log("doesn't Exist " + i);
            i = i + 1;
          }
          //dateCreated: timeStamp.fromDate(new Date());
        })
        .catch(function (error) {
          console.log(error);
          i = i + 1;
        });
    }
  };

  function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setMilliseconds(0);
    return date;
  }

  var fetchShippersInLastSixMonths =
    async function fetchShippersInLastSixMonths(payload) {
      console.log("inside the function");
      console.log("querying packages");

      var packArr = [];
      let uidArr = [];
      let userArr = [];
      let date = addMonths(new Date(), -6);
      // date = new Date();

      //console.log("date is: ");
      //console.log(date);
      let startDate = timeStamp.fromDate(date);
      // console.log("start date is:");
      // console.log(startDate);
      try {
        const q = Query(
          Collection(db, "PackagesNew"),
          Where("PackageInfo.OrderDate", ">=", startDate)
        );
        await OnSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach(async function (doc) {
            //console.log(doc.data());
            var res = doc.data();
            packArr.push(res.PackageInfo);
            //console.log(Moment(res.PackageInfo.OrderDate.toDate()));
            if (!uidArr.includes(res.Uid)) {
              uidArr.push(res.Uid);
              const docRef = Doc(db, "Users", res.Uid);
              const docSnap = await GetDoc(docRef);

              if (docSnap.exists()) {
                //console.log("user exist");
                // console.log(docSnap.data());
                // console.log("what is inside payload for fetch user info");
                // console.log(payloadf);
                //Convert to City object
                var user = docSnap.data(); //console.log("verify email sent fetch user check ? " + user.verifiedemailsent);

                if (user !== null) {
                  userArr.push(user);
                }
              } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
              }
            }
          });
          //console.log("Uid arr length is: " + uidArr.length);
          //console.log(userArr);

          setTimeout(() => {
            payload.consistentusers = userArr;
            dispatch({
              type: "fetch_consistentuserinfo",
              payload: payload,
            });
          }, 3000);
        });
      } catch (err) {
        console.log(err);
      }
    };

  var fetchUserInfo = async function fetchUserInfo(
    uid,
    payloadf,
    currentState
  ) {
    // console.log("User id is: ");
    // console.log(uid);
    // console.log("fetching user");
    //let enabled = undefined
    const docRef = Doc(db, "Users", uid);
    const docSnap = await GetDoc(docRef);
    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      var user = docSnap.data(); //console.log("verify email sent fetch user check ? " + user.verifiedemailsent);

      if (user !== null) {
        //console.log(user.enabled)
        if (user.enabled === undefined || user.enabled === true) {
          payloadf.userInfo.contactNumber = user.contactNumber;
          payloadf.userInfo.email = user.email;
          payloadf.userInfo.fullName = user.fullName;
          payloadf.loggedIn = true;
          payloadf.userInfo.addressLine1 =
            user.addressLine1 !== null && user.addressLine1 !== undefined
              ? user.addressLine1
              : "";
          payloadf.userInfo.addressLine2 =
            user.addressLine2 !== null && user.addressLine2 !== undefined
              ? user.addressLine2
              : "";
          payloadf.userInfo.city =
            user.city !== null && user.city !== undefined ? user.city : "";
          payloadf.userInfo.postalCode =
            user.postalCode !== null && user.postalCode !== undefined
              ? user.postalCode
              : "";
          payloadf.userInfo.stateOrparish =
            user.stateOrparish !== null && user.stateOrparish !== undefined
              ? user.stateOrparish
              : "";
          payloadf.userInfo.verifiedemailsent =
            user.verifiedemailsent !== null &&
            user.verifiedemailsent !== undefined
              ? user.verifiedemailsent
              : true;
          payloadf.userInfo.verified =
            user.verified !== null && user.verified !== undefined
              ? user.verified
              : true;
          payloadf.userInfo.dateCreated =
            user.dateCreated !== null && user.dateCreated !== undefined
              ? user.dateCreated
              : "";
          payloadf.userInfo.mailbox_Num =
            user.mailbox_Num !== null && user.mailbox_Num !== undefined
              ? user.mailbox_Num
              : "";
          payloadf.userInfo.rPoints =
            user.hasOwnProperty("rPoints") && user.rPoints !== ""
              ? user.rPoints
              : "0";

          return await userHasRole(uid, payloadf).then(function (userRoleRes) {
            // console.log("Final user ref is: ");
            // console.log(userRoleRes);
            dispatch({
              type: "fetch_userinfo",
              payload: userRoleRes,
            });
            return true;
          });
        } else {
          //enabled = null;
          console.log("enabled is: ", null);
          return false;
        }
      }

      return payloadf;
    } else {
      // console.log("No such user!")
      // console.log("creating new user");
      // console.log(payloadf)
      var user = {
        contactNumber:
          payloadf.userInfo.contactNumber !== null
            ? payloadf.userInfo.contactNumber
            : "",
        email: payloadf.userInfo.email !== null ? payloadf.userInfo.email : "",
        fullName:
          payloadf.userInfo.fullName !== null
            ? payloadf.userInfo.fullName.toLowerCase()
            : "",
        verified: false,
        verifiedemailsent: false,
        addressLine1: "",
        addressLine2: "",
        city: "",
        postalCode: "",
        stateOrparish: "",
        dateCreated: timeStamp.fromDate(new Date()),
      };
      // console.log(user);
      await SetDoc(Doc(db, "Users", uid), user);

      payloadf.userInfo.contactNumber = user.contactNumber;
      payloadf.userInfo.email = user.email;
      payloadf.userInfo.fullName = user.fullName;
      payloadf.userInfo.verified = user.verified;
      payloadf.userInfo.verifiedemailsent = user.verifiedemailsent;
      payloadf.loggedIn = true;
      payloadf.userInfo.addressLine1 =
        user.addressLine1 !== null && user.addressLine1 !== undefined
          ? user.addressLine1
          : "";
      payloadf.userInfo.addressLine2 =
        user.addressLine2 !== null && user.addressLine2 !== undefined
          ? user.addressLine2
          : "";
      payloadf.userInfo.city =
        user.city !== null && user.city !== undefined ? user.city : "";
      payloadf.userInfo.postalCode =
        user.postalCode !== null && user.postalCode !== undefined
          ? user.postalCode
          : "";
      payloadf.userInfo.stateOrparish =
        user.stateOrparish !== null && user.stateOrparish !== undefined
          ? user.stateOrparish
          : "";
      payloadf.userInfo.dateCreated =
        user.dateCreated !== null && user.dateCreated !== undefined
          ? user.dateCreated
          : "";

      var userRoleResf = undefined;
      await userHasRole(uid, payloadf).then(function (userRoleRes) {
        // console.log("Final user ref is: ");
        // console.log(userRoleRes);
        userRoleResf = userRoleRes;
        return userRoleRes;
        // dispatch({
        //   type: "fetch_userinfo",
        //   payload: userRoleRes,
        // });
      });

      var newrefArr = [];
      const q = Query(
        Collection(db, "Refferals"),
        Where("NewCustUid", "==", uid)
      );

      if (currentState?.refferalId !== null) {
        if (currentState?.refferalId !== "") {
          //console.log("about to start refferal process");
          var newuserRef = null;
          const querySnapshot = await GetDocs(q);
          querySnapshot.forEach((doc) => {
            //console.log(doc.data());
            var res = doc.data();
            newrefArr.push(res.PackageInfo);
          }); //console.log("Array contents");

          //Check if customer was already reffered
          if (newrefArr.length === 0) {
            //console.log("about to add reff info");
            //new customer being referred
            const docSnap2 = await GetDoc(docRef);
            var prepDate = new Date();
            //console.log(prepDate);
            var tstamp = timeStamp.fromDate(prepDate); //Get timestamp
            var reffDetails = {
              NewCustUid: uid,
              RefferalDate: tstamp,
              ReffererUid: "",
            }; //Setup object for storing

            if (docSnap2.exists()) {
              reffDetails.ReffererUid = docSnap2.id;
            }

            //check if refferer exists
            if (
              reffDetails.ReffererUid === undefined ||
              reffDetails.ReffererUid === null ||
              reffDetails.ReffererUid === ""
            ) {
              //console.log("refferer not found");
              return "Refferer does not exist";
            } else {
              //console.log("storing reff info and points");
              //Store New Referral
              //ensure u cant refer your self
              if (reffDetails.NewCustUid !== reffDetails.ReffererUid) {
                await AddDoc(Collection(db, "Refferals"), reffDetails);
              }

              dispatch({
                type: "fetch_userinfo",
                payload: userRoleResf,
              });
            }
          } else {
            //customer already reffered
            //console.log("customer already reffered");
            if (newuserRef !== null && newuserRef !== undefined) {
              return newuserRef;
            }
          }
        } else {
          dispatch({
            type: "fetch_userinfo",
            payload: userRoleResf,
          });
        }
      } else {
        dispatch({
          type: "fetch_userinfo",
          payload: userRoleResf,
        });
      }
      return true;
    }
  };

  var fetchUserInfoForSignUp = async function fetchUserInfoForSignUp(
    uid,
    payload,
    currentState
  ) {
    try {
      //console.log("User id is: ");
      //console.log(uid);
      //console.log("the currentState is: ");
      //console.log(currentState);
      //console.log("fetching user");
      const docRef = Doc(db, "Users", uid);
      const docSnap = await GetDoc(docRef);

      if (docSnap.exists()) {
        //console.log("user exist");
        //console.log(docSnap.data());
        //console.log("what is inside payload");
        //console.log(payload);
        // Convert to City object
        var user = docSnap.data();

        if (user !== null) {
          payload.userInfo.contactNumber = user.contactNumber;
          payload.userInfo.email = user.email;
          payload.userInfo.fullName = user.fullName;
          payload.userInfo.verified = user.verified;
          payload.userInfo.verifiedemailsent = user.verifiedemailsent;
          payload.loggedIn = true;
          payload.userInfo.addressLine1 =
            user.addressLine1 !== null && user.addressLine1 !== undefined
              ? user.addressLine1
              : "";
          payload.userInfo.addressLine2 =
            user.addressLine2 !== null && user.addressLine2 !== undefined
              ? user.addressLine2
              : "";
          payload.userInfo.city =
            user.city !== null && user.city !== undefined ? user.city : "";
          payload.userInfo.postalCode =
            user.postalCode !== null && user.postalCode !== undefined
              ? user.postalCode
              : "";
          payload.userInfo.stateOrparish =
            user.stateOrparish !== null && user.stateOrparish !== undefined
              ? user.stateOrparish
              : "";
          payload.userInfo.dateCreated =
            user.dateCreated !== null && user.dateCreated !== undefined
              ? user.dateCreated
              : "";
          payload.userInfo.mailbox_Num =
            user.mailbox_Num !== null && user.mailbox_Num !== undefined
              ? user.mailbox_Num
              : "";
          payload.userInfo.rPoints =
            user.hasOwnProperty("rPoints") && user.rPoints !== ""
              ? user.rPoints
              : "0";
        }
      } else {
        //console.log("No such user!")
        //console.log("creating new user");
        ////console.log("contact number is: " + currentState.contact);
        var user2 = {
          contactNumber:
            payload.userInfo.contactNumber !== null
              ? payload.userInfo.contactNumber
              : "",
          email: payload.userInfo.email !== null ? payload.userInfo.email : "",
          fullName:
            payload.userInfo.fullName !== null
              ? payload.userInfo.fullName.toLowerCase()
              : "",
          verified: false,
          verifiedemailsent: false,
          addressLine1: "",
          addressLine2: "",
          city: "",
          postalCode: "",
          stateOrparish: "",
          dateCreated: timeStamp.fromDate(new Date()),
        };

        // console.log(user2);

        let storeRes = await SetDoc(Doc(db, "Users", uid), user2)
          .then(function (doc) {
            //console.log("User info  successfully written!");
            return true;
          })
          .catch(function (error) {
            //console.error("Error writing user info: ", error);
            return false;
          });

        if (await storeRes) {
          payload.userInfo.contactNumber = user2.contactNumber;
          payload.userInfo.email = user2.email;
          payload.userInfo.fullName = user2.fullName;
          payload.userInfo.verifiedemailsent = user2.verifiedemailsent;
          payload.userInfo.verified = user2.verified;
          payload.loggedIn = true;
          payload.userInfo.addressLine1 =
            user2.addressLine1 !== null && user2.addressLine1 !== undefined
              ? user2.addressLine1
              : "";
          payload.userInfo.addressLine2 =
            user2.addressLine2 !== null && user2.addressLine2 !== undefined
              ? user2.addressLine2
              : "";
          payload.userInfo.city =
            user2.city !== null && user2.city !== undefined ? user2.city : "";
          payload.userInfo.postalCode =
            user2.postalCode !== null && user2.postalCode !== undefined
              ? user2.postalCode
              : "";
          payload.userInfo.stateOrparish =
            user2.stateOrparish !== null && user2.stateOrparish !== undefined
              ? user2.stateOrparish
              : "";
          payload.userInfo.dateCreated =
            user2.dateCreated !== null && user2.dateCreated !== undefined
              ? user2.dateCreated
              : "";
        }
      }

      var userRoleResf = undefined;
      await userHasRole(uid, await payload).then(function (userRoleRes) {
        //console.log("Final user ref after fetch role is: ");
        //console.log(userRoleRes);
        userRoleResf = userRoleRes;
        return userRoleRes;
      });

      var newrefArr = [];
      const q = Query(
        Collection(db, "Refferals"),
        Where("NewCustUid", "==", uid)
      );

      if (currentState?.refferalId !== null) {
        if (currentState?.refferalId !== "") {
          //console.log("about to start refferal process");
          var newuserRef = null;
          const querySnapshot = await GetDocs(q);
          querySnapshot.forEach((doc) => {
            //console.log(doc.data());
            var res = doc.data();
            newrefArr.push(res.PackageInfo);
          }); //console.log("Array contents");

          //Check if customer was already reffered
          if (newrefArr.length === 0) {
            //console.log("about to add reff info");
            //new customer being referred
            const docSnap2 = await GetDoc(docRef);
            var prepDate = new Date();
            //console.log(prepDate);
            var tstamp = timeStamp.fromDate(prepDate); //Get timestamp
            var reffDetails = {
              NewCustUid: uid,
              RefferalDate: tstamp,
              ReffererUid: "",
            }; //Setup object for storing

            if (docSnap2.exists()) {
              reffDetails.ReffererUid = docSnap2.id;
            }

            //check if refferer exists
            if (
              reffDetails.ReffererUid === undefined ||
              reffDetails.ReffererUid === null ||
              reffDetails.ReffererUid === ""
            ) {
              //console.log("refferer not found");
              return "Refferer does not exist";
            } else {
              //console.log("storing reff info and points");
              //Store New Referral
              //ensure u cant refer your self
              if (reffDetails.NewCustUid !== reffDetails.ReffererUid) {
                await AddDoc(Collection(db, "Refferals"), reffDetails);
              }

              dispatch({
                type: "fetch_userinfo",
                payload: userRoleResf,
              });
            }
          } else {
            //customer already reffered
            //console.log("customer already reffered");
            if (newuserRef !== null && newuserRef !== undefined) {
              return newuserRef;
            }
          }
        } else {
          dispatch({
            type: "fetch_userinfo",
            payload: userRoleResf,
          });
        }
      } else {
        dispatch({
          type: "fetch_userinfo",
          payload: userRoleResf,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  var fetchCustomerInfo = async function fetchCustomerInfo(uid, payload) {
    //console.log("fetching user");

    const docRef = Doc(db, "Users", uid);
    const docSnap = await GetDoc(docRef);

    if (docSnap.exists()) {
      var user = docSnap.data();

      if (user !== null) {
        payload.userInfo.email = user.email;
        payload.userInfo.fullName = user.fullName;
      }
      return payload;
    } else {
      return payload;
    }
  };

  //Fetch packages for customer
  //Fetch packages
  var fetchPackages = async function fetchPackages(uid, payload) {
    try {
      // console.log("User id is: ");
      // console.log(uid);
      // console.log("Payload is: ");
      // console.log(payload);
      // console.log("getting ref for packages")
      //console.log("querying packages");
      var packArr = [];
      const packagesRef = Collection(db, "PackagesNew");
      // Create a query against the Collection.
      const q = Query(packagesRef, Where("Uid", "==", uid));

      let fetchPackUnsubscribe = await OnSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          var res = doc.data();
          packArr.push(res.PackageInfo);
        });

        payload.packages = packArr;
        payload.readyPack = checkPackages(packArr, "Ready");
        //console.log("Checked Ready");
        payload.inTransPack = checkPackages(packArr, "In Transit");
        //console.log("Checked Transit");
        payload.balance = checkBalance(packArr, "Ready");
        //console.log("Checked Balance");
        payload.points = checkPackages(packArr, "Received At Warehouse");
        //console.log("Checked Points");
      });

      setTimeout(() => {
        //console.log(fetchPackUnsubscribe);
        payload.FetchPackUnsubscribe = fetchPackUnsubscribe;
        dispatch({
          type: "fetch_packages",
          payload: payload,
        });
      }, 4000);
    } catch (err) {
      console.log(err.message);
    }
  };

  //Fetch users Admin
  var fetchUsers = async function fetchUsers(payload) {
    var userArr = [];
    // var userIds = [];
    // var mailBoxNums = [];
    //var finalArr = [];
    const querySnapshot = await GetDocs(Collection(db, "Users"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id);
      //console.log(doc.data());
      var res = doc.data();
      // userIds.push(doc.id);
      userArr.push(res);
    });

    payload.users = userArr;
    dispatch({
      type: "fetch_users",
      payload: payload,
    });
  };

  //Fetch users Admin
  var fetchStaffUsers = async function fetchStaffUsers(payload) {
    var userArr = [];
    // var userIds = [];
    // var mailBoxNums = [];
    //var finalArr = [];
    var Staff = "Staff";
    var Admin = "Admin";
    const q = Query(Collection(db, "Users"), Where("Position", "==", Staff));

    const q2 = Query(Collection(db, "Users"), Where("Position", "==", Admin));

    const querySnapshot = await GetDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      var res = doc.data();
      res["uid"] = doc.id;
      userArr.push(res);
    });

    const querySnapshot2 = await GetDocs(q2);
    querySnapshot2.forEach((doc2) => {
      // doc.data() is never undefined for query doc snapshots
      var res2 = doc2.data();
      res2["uid"] = doc2.id;
      userArr.push(res2);
    });

    payload.staffUsers = userArr;
    dispatch({
      type: "fetch_users_staff",
      payload: payload,
    });
  };

  var clearFetchPackagesForDilivery =
    async function clearFetchPackagesForDilivery(payload) {
      payload.packagesForDelivery = [];
      dispatch({
        type: "fetch_packages_delivery",
        payload: payload,
      });
    };

  var fetchPackagesForDelivery = async function fetchPackagesForDelivery(
    uid,
    payload
  ) {
    // console.log("inside fetchPackagesForDelivery:");
    // console.log("User id is: ");
    // console.log(uid);
    // console.log("getting ref for packages");
    getInvoices(uid, payload);
    var packArr = [];
    // console.log("querying packages");
    const q = Query(
      Collection(db, "PackagesNew"),
      Where("Uid", "==", uid),
      Where("PackageInfo.Status", "!=", "Delivered")
    );

    const querySnapshot = await GetDocs(q);
    querySnapshot.forEach((doc) => {
      // console.log(doc.data());
      var res = doc.data();
      packArr.push({
        ...res.PackageInfo,
        Uid: res.Uid,
        fullName: res.fullName,
      });
      // console.log("loop bottom");
    });

    // console.log("checkIfPackageIsAlreadyScheduled");
    // console.log(packArr);
    await checkIfPackageIsAlreadyScheduled(packArr, uid)
      .then(async function (res2) {
        // console.log("What is inside res?");
        // console.log(res2);
        if (res2 !== null && res2 !== undefined && res2.length !== 0) {
          if (res2 !== "failed") {
            if (res2.length > 0) {
              // console.log(packArr);
              for (var f = 0; f < res2.length; ) {
                var pack = res2[f];
                var index = packArr.indexOf(pack);
                //console.log("index found is : " + index.toString());
                if (index !== -1) {
                  packArr.splice(index, 1);
                }
                f = f + 1;
              }
              // console.log("Final list is:? ", packArr);

              if (
                packArr.length > 0 &&
                payload.packagesForDelivery === undefined
              ) {
                payload.packagesForDelivery = packArr;
                dispatch({
                  type: "fetch_packages_delivery",
                  payload: payload,
                });
              }
            } else {
              if (
                packArr.length > 0 &&
                payload.packagesForDelivery === undefined
              ) {
                payload.packagesForDelivery = packArr;
                dispatch({
                  type: "fetch_packages_delivery",
                  payload: payload,
                });
              }
            }
          }
        } else {
          if (
            (packArr.length > 0 && payload.packagesForDelivery === undefined) ||
            (packArr.length > 0 && payload.packagesForDelivery?.length === 0)
          ) {
            payload.packagesForDelivery = packArr;
            dispatch({
              type: "fetch_packages_delivery",
              payload: payload,
            });
          } else if (uid !== payload.packagesForDelivery[0].Uid) {
            console.log(packArr);
            console.log(uid);
            payload.packagesForDelivery = packArr;
            dispatch({
              type: "fetch_packages_delivery",
              payload: payload,
            });
          }
        }
      })
      .catch(function (err1) {
        // console.log(err1);
      });
  };

  //Find user Admin
  var findUser = async function findUser(payload, search_value, search_type) {
    //console.log("i made it");
    var userArr = [];
    var userIds = [];
    var mailBoxNums = [];
    var finalArr = [];
    if (search_type === "Fullname") {
      const q = Query(
        Collection(db, "Users"),
        Where("fullName", "==", search_value)
      );

      const querySnapshot = await GetDocs(q);
      querySnapshot.forEach((doc) => {
        //console.log(doc.id);
        //console.log(doc.data());
        var res = doc.data();
        userIds.push(doc.id);
        userArr.push(res);
      });

      for (var i = 0; i < userIds.length; ) {
        await getUserMailboxNumberForStaff(userIds[i])
          .then(async function (num) {
            //console.log(num);
            mailBoxNums.push(num);
          })
          .catch(function (err) {
            //console.log("nothing")
          });
        i = i + 1;
      }

      for (var f = 0; f < mailBoxNums.length; ) {
        var obj = {
          ...userArr[f],
          MailBoxNumber: mailBoxNums[f],
        };
        finalArr.push(obj);
        f = f + 1;
      }

      //console.log("ARRAY CONTENTS !!!!!!!!!!!!!!!!!!!!!!!!!!");
      //console.log(finalArr);

      payload.users = finalArr;
      dispatch({
        type: "fetch_users",
        payload: payload,
      });
    } else if (search_type === "Mailbox#") {
      await getUserByMailboxNumberForStaff(search_value)
        .then(async function (userID) {
          if (userID !== false && userID !== "failed") {
            const userRef = Doc(db, "Users", userID);
            const docSnap = await GetDoc(userRef);

            if (docSnap.exists()) {
              // console.log(docSnap.id);
              // console.log(docSnap.data());
              var res = docSnap.data();
              userArr.push(res);

              // console.log(userArr);
              for (var f = 0; f < userArr.length; ) {
                var obj = {
                  ...userArr[f],
                  MailBoxNumber: search_value,
                };
                finalArr.push(obj);
                f = f + 1;
              }
              // console.log("Fianl arrayyy is? ");
              // console.log(finalArr);
              payload.users = finalArr;
              dispatch({
                type: "fetch_users",
                payload: payload,
              });
            } else {
              // console.log("none was found.");
              payload.users = [];
              dispatch({
                type: "fetch_users",
                payload: payload,
              });
            }
          } else {
            // console.log("none was found2.");
            payload.users = [];
            dispatch({
              type: "fetch_users",
              payload: payload,
            });
          }
        })
        .catch((err) => {
          //console.log("none was found3.");
          //console.log(err);
          payload.users = [];
          dispatch({
            type: "fetch_users",
            payload: payload,
          });
        });
    }
  };

  var findUserForDashboard = async function findUserForDashboard(
    payload,
    search_value
  ) {
    //console.log("i made it");
    var userArr = [];
    var finalArr = [];

    await getUserByMailboxNumberForStaff(search_value)
      .then(async function (userID) {
        if (userID !== false && userID !== "failed") {
          const docRef = Doc(db, "Users", userID);
          const docSnap = await GetDoc(docRef);
          //console.log("Logging users");
          if (docSnap.exists()) {
            //console.log(docSnap.id);
            //console.log(docSnap.data());
            var res = docSnap.data();
            userArr.push(res);
            //console.log(userArr);
            for (var f = 0; f < userArr.length; ) {
              var obj = {
                ...userArr[f],
                MailBoxNumber: search_value,
              };
              finalArr.push(obj);
              f = f + 1;
            }
            //console.log("Fianl arrayyy is? ");
            //console.log(finalArr);
            payload.singleUser = finalArr;
            dispatch({
              type: "fetch_users",
              payload: payload,
            });
          } else {
            //console.log("none was found.");
            payload.singleUser = [];
            dispatch({
              type: "fetch_users",
              payload: payload,
            });
          }
        } else {
          //console.log("none was found2.");
          payload.singleUser = [];
          dispatch({
            type: "fetch_user_dashboard",
            payload: payload,
          });
        }
      })
      .catch((err) => {
        //console.log("none was found3.");
        //console.log(err);
        payload.singleUser = [];
        dispatch({
          type: "fetch_user_dashboard",
          payload: payload,
        });
      });
  };

  //Count packages per for status
  var checkPackages = function checkPackages(packages, status) {
    var count = 0;

    if (packages !== null && packages !== undefined) {
      packages.forEach(function (pac) {
        //console.log(pac);
        var res = pac;

        if (res.Status === status) {
          count = count + 1;
        }
      });
    }

    return count;
  }; //Count balace for ready status

  var checkBalance = function checkBalance(packages, status) {
    var balace = 0.0;

    if (packages !== null && packages !== undefined) {
      packages.forEach(function (pac) {
        //console.log(pac);
        var res = pac;

        if (res.FinalCost !== null && res.FinalCost !== undefined) {
          if (res.Status === status) {
            var add =
              res.FinalCost !== null && res.FinalCost !== undefined
                ? parseFloat(res.FinalCost)
                : 0;
            balace = balace + add;
          }
        }
      });
    }

    return balace;
  }; //Add Package Check Balace

  var addPackageStaff = async function addPackageStaff(packageZip) {
    var tstamp = timeStamp.fromDate(new Date(packageZip.order_date));
    var packageDetails = {
      PackageInfo: {
        Cost: packageZip.cost,
        ItemName: packageZip.item_name,
        MailBoxNumber: packageZip.mailbox_number,
        Merchant: packageZip.merchant,
        OrderDate: tstamp,
        Status: packageZip.status,
        TrackingNumber: packageZip.tracking_number,
        Weight: packageZip.weight,
        Courier: packageZip.courier,
        FinalCost: packageZip.fcost,
        HouseNum: packageZip.house_Num,
        Duty: packageZip.duty,
      },
      Uid: "",
      fullName: "",
    };
    var payload = {
      userInfo: {
        email: "",
        fullName: "",
      },
    };
    var RequestParams = {
      user_email: "",
      user_name: "",
      merchant:
        packageZip.merchant !== null && packageZip.merchant !== undefined
          ? packageZip.merchant
          : "",
      status:
        packageZip.status !== null && packageZip.status !== undefined
          ? packageZip.status
          : "",
      trackingNum:
        packageZip.tracking_number !== null &&
        packageZip.tracking_number !== undefined
          ? packageZip.tracking_number
          : "",
    };
    var packArr = [];

    const checkIfPackageExists = Query(
      Collection(db, "PackagesNew"),
      Where("PackageInfo.TrackingNumber", "==", packageZip.tracking_number)
    );
    try {
      const querySnapshot = await GetDocs(checkIfPackageExists);
      querySnapshot.forEach((doc) => {
        //console.log(doc.data());
        //functionss.logger.log("Packages-Uid: " + packageZip.mailbox_number,doc.data());
        var res = doc.data();
        packArr.push(res.PackageInfo);
      });

      //console.log("Array contents");
      //console.log(packArr);

      if (packArr.length > 0) {
        //console.log("Package with Tracking number exist.")
        return `Tracking number exist. ${packageZip.tracking_number}`;
      } else {
        var res = await getUserByMailboxNumber(packageZip.mailbox_number).then(
          async function (resUser) {
            // var data1 = {event: 'getUserByMailboxNumber staff add package',
            //     value:{"User by mailbox returned for mailbox: " : packageZip.mailbox_number, result: res}
            // };
            // var entry1 = log.entry(METADATA, data1);
            // log.write(entry1);
            if (resUser === "failed") {
              return "Mailbox doesnot exist";
            } else if (resUser !== false) {
              packageDetails.Uid = resUser;
              const getUserRef = Doc(db, "Users", packageDetails.Uid);
              const docSnap = await GetDoc(getUserRef);

              if (docSnap.exists()) {
                var userpack = docSnap.data();
                packageDetails.fullName = userpack.fullName;
                try {
                  //console.log("New Package Details  successfully written!");
                  //Store Package details
                  await AddDoc(Collection(db, "PackagesNew"), packageDetails);
                  var finalResult = await fetchCustomerInfo(
                    packageDetails.Uid,
                    payload
                  )
                    .then(async function (cusInfoResult) {
                      if (
                        cusInfoResult !== null &&
                        cusInfoResult !== undefined &&
                        cusInfoResult.userInfo.fullName !== "" &&
                        cusInfoResult.userInfo.email !== ""
                      ) {
                        RequestParams.user_email = cusInfoResult.userInfo.email;
                        RequestParams.user_name =
                          cusInfoResult.userInfo.fullName; // var data2 = {event: 'getUserByMailboxNumber staff add package',
                        //     value:{"Params going to sendNewPackageMethod mailbox: " : packageZip.mailbox_number, result: RequestParams}
                        // };
                        // var entry2 = log.entry(METADATA, data2);
                        // log.write(entry2);
                        //console.log("Params going to sendNewPackageMethod");
                        //console.log(RequestParams);

                        var emailRes = await sendNewPackageEmail(RequestParams)
                          .then(function (emailSentRes) {
                            if (emailSentRes) {
                              return true;
                            } else {
                              // var data2 = {event: 'getUserByMailboxNumber staff add package',
                              //     value:"Unable to send add package email at this time mailbox: " + packageZip.mailbox_number
                              // };
                              // var entry2 = log.entry(METADATA, data2);
                              // log.write(entry2);
                              //console.log("Unable to send add package email at this time.")
                              return true;
                            }
                          })
                          .catch(function (err) {
                            // var data3 = {event: 'getUserByMailboxNumber staff add package',
                            //       value:{"Unable to send add package email at this time mailbox: " : packageZip.mailbox_number, error: err}
                            //   };
                            // var entry3 = log.entry(METADATA, data3);
                            // log.write(entry3);
                            //console.log("Unable to send add package email at this time.")
                            //console.log(err);
                            return true;
                          });
                        return emailRes;
                      } else {
                        return true;
                      }
                    })
                    .catch(function (err) {
                      // var data4 = {event: 'getUserByMailboxNumber staff add package',
                      //           value:{"error fetching user info to send email: " : packageZip.mailbox_number, error: err}
                      //       };
                      // var entry4 = log.entry(METADATA, data4);
                      // log.write(entry4);
                      //console.log("error fetching user info to send email")
                      //console.log(err);
                      return false;
                    });
                  return finalResult;
                } catch (err) {
                  // var data5 = {event: 'staff add package',
                  //             value:{"Error writing New Package Details mailbox: " : packageZip.mailbox_number, error: error}
                  //         };
                  //   var entry5 = log.entry(METADATA, data5);
                  //   log.write(entry5);
                  //console.error("Error writing New Package Details: ", err);
                  return false;
                }
              }
            }
          }
        );
        return res;
      }
    } catch {
      return false;
    }
  }; //Add Package

  //Delete Package
  var deletePackageStaff = async function deletePackageStaff(tracking_numb) {
    var packDockId = "";
    //console.log("Track numb is: " + tracking_numb);
    const q = Query(
      Collection(db, "PackagesNew"),
      Where("PackageInfo.TrackingNumber", "==", tracking_numb)
    );

    const querySnapshot = await GetDocs(q);
    querySnapshot.forEach((doc) => {
      //functionss.logger.log("Logging packages tracking Num: " + tracking_numb, doc.data());
      //console.log(doc.data());
      packDockId = doc.id; //functionss.logger.log("Single package id is:" + doc.id, "Tracking Num: " + tracking_numb);
      //console.log("Single package id is:" + doc.id);
    });
    if (packDockId.length > 3) {
      await DeleteDoc(Doc(db, "PackagesNew", packDockId)).catch(function (
        error
      ) {
        console.error("Error removing document: ", error);
        return false;
      });
      return true;
    } else {
      console.log("no doc id");
      return false;
    }
  };

  var editPackageStaff = async function editPackageStaff(
    packageZip,
    packageTnum,
    payloadf,
    packIndex
  ) {
    console.log("package zip is:");
    console.log(packageZip);
    console.log("package tracking num is");
    console.log(packageTnum);
    console.log(packageZip.order_date instanceof timeStamp);
    var tstamp =
      packageZip.order_date instanceof timeStamp
        ? packageZip.order_date
        : timeStamp.fromDate(new Date(packageZip.order_date));
    var packageDetails = {
      PackageInfo: {
        Cost: packageZip.cost,
        ItemName: packageZip.item_name,
        MailBoxNumber: packageZip.mailbox_number,
        Merchant: packageZip.merchant,
        OrderDate: tstamp,
        Status: packageZip.status,
        TrackingNumber: packageZip.tracking_number,
        Weight: packageZip.weight,
        Courier: packageZip.courier,
        FinalCost: packageZip.fcost,
        HouseNum: packageZip.house_Num,
        Duty: packageZip.duty,
      },
      Uid: "",
      fullName: packageZip.fullName,
    };
    var payload = {
      userInfo: {
        email: "",
        fullName: "",
      },
    };
    var RequestParams = {
      user_email: "",
      user_name: "",
      merchant:
        packageZip.merchant !== null && packageZip.merchant !== undefined
          ? packageZip.merchant
          : "",
      status:
        packageZip.status !== null && packageZip.status !== undefined
          ? packageZip.status
          : "",
      trackingNum:
        packageZip.tracking_number !== null &&
        packageZip.tracking_number !== undefined
          ? packageZip.tracking_number
          : "",
    };
    var resUid = await getUserByMailboxNumber(packageZip.mailbox_number).then(
      function (res) {
        return res;
      }
    );

    var currentPInfo = undefined;
    const q = Query(
      Collection(db, "PackagesNew"),
      Where("PackageInfo.TrackingNumber", "==", packageTnum)
    );

    const querySnapshot = await GetDocs(q);
    //functionss.logger.log("Logging packages tracking Num: " + tracking_numb);
    //console.log("Logging packages");
    var result1 = undefined;

    querySnapshot.forEach((doc) => {
      result1 = doc.id;
      currentPInfo = doc.data();
      //console.log("Single package id is:" + doc.id);
    });

    if (result1 === undefined) {
      result1 = false;
    }
    // console.log("User by mailbox returned");
    // console.log(resUid);

    // console.log("package id is");
    // console.log(result1);

    if (resUid === "failed") {
      return "Mailbox or package doesnot exist";
    } else if (resUid !== false && result1 !== false) {
      packageDetails.Uid = resUid;
      console.log("Abbout to update package");
      console.log(packageDetails);
      console.log(packageTnum);
      const packagesNewRef = Doc(db, "PackagesNew", result1);
      let storeRes = await UpdateDoc(packagesNewRef, packageDetails)
        .then(async function (doc) {
          console.log("New Package Details  successfully written!");
          // console.log(doc);
          var finalResult = await fetchCustomerInfo(packageDetails.Uid, payload)
            .then(async function (cusInfoResult) {
              //console.log(cusInfoResult);
              if (
                cusInfoResult !== null &&
                cusInfoResult !== undefined &&
                cusInfoResult.fullName !== "" &&
                cusInfoResult.email !== ""
              ) {
                RequestParams.user_email = cusInfoResult.userInfo.email;
                RequestParams.user_name = cusInfoResult.userInfo.fullName; //console.log("Params going to sendNewPackageMethod");
                //console.log(RequestParams);

                if (
                  packageDetails.PackageInfo.Status === "Ready" ||
                  packageDetails.PackageInfo.Status ===
                    "Received At Warehouse" ||
                  packageDetails.PackageInfo.Status === "In Transit"
                ) {
                  var emailRes = await sendNewPackageEmail(RequestParams)
                    .then(function (emailSentRes) {
                      if (emailSentRes) {
                        if (
                          payloadf !== undefined &&
                          payloadf !== null &&
                          packIndex !== undefined &&
                          packIndex !== null
                        ) {
                          // console.log(payloadf);
                          // console.log(payloadf.rangeOfPackages);
                          // console.log(payloadf.rangeOfPackages[packIndex].PackageInfo.Status);
                          payloadf.rangeOfPackages[
                            packIndex
                          ].PackageInfo.Status = packageZip.status;
                          //console.log(payloadf.rangeOfPackages[packIndex].PackageInfo.Status);

                          dispatch({
                            type: "find_packages_by_daterange",
                            payload: payloadf,
                          });
                        }
                        return true;
                      } else {
                        if (
                          payloadf !== undefined &&
                          payloadf !== null &&
                          packIndex !== undefined &&
                          packIndex !== null
                        ) {
                          // console.log(payloadf);
                          // console.log(payloadf.rangeOfPackages);
                          // console.log(payloadf.rangeOfPackages[packIndex].PackageInfo.Status);
                          payloadf.rangeOfPackages[
                            packIndex
                          ].PackageInfo.Status = packageZip.status;
                          //console.log(payloadf.rangeOfPackages[packIndex].PackageInfo.Status);

                          dispatch({
                            type: "find_packages_by_daterange",
                            payload: payloadf,
                          });
                        }
                        //console.log("Unable to send add package email at this time.")
                        return true;
                      }
                    })
                    .catch(function (err) {
                      //console.log("Unable to send add package email at this time.")
                      console.log(err);
                      if (
                        payloadf !== undefined &&
                        payloadf !== null &&
                        packIndex !== undefined &&
                        packIndex !== null
                      ) {
                        payloadf.rangeOfPackages[packIndex].PackageInfo.Status =
                          packageZip.status;
                        dispatch({
                          type: "find_packages_by_daterange",
                          payload: payloadf,
                        });
                        console.log(payloadf.rangeOfPackages[packIndex]);
                      }
                      return true;
                    });
                  return emailRes;
                } else if (
                  currentPInfo !== undefined &&
                  currentPInfo !== null
                ) {
                  if (
                    packageDetails.PackageInfo.Status === "Delivered" &&
                    currentPInfo.PackageInfo.Status !== "Delivered"
                  ) {
                    //CHECK IF USER EXIST
                    const userRef = Doc(db, "Users", packageDetails.Uid);
                    const userSnap = await GetDoc(userRef);

                    if (userSnap.exists()) {
                      console.log("user exists");
                      var user = userSnap.data(); //console.log("verify email sent fetch user check ? " + user.verifiedemailsent);
                      let payloadf1 = {};
                      if (user !== null) {
                        payloadf1 = {
                          ...user,
                          rPoints:
                            user.hasOwnProperty("rPoints") &&
                            user.rPoints !== ""
                              ? (parseInt(user.rPoints) + 1).toString()
                              : "1",
                        };
                      }
                      console.log(payloadf1);
                      let resPointsUser = [];
                      let resPointsRefUser = [];
                      let resPointsNewPack = [];
                      let RefferalPointsObj = {
                        Action: "Add Point",
                        DateCreated: new Date().toString(),
                        NumberOfPoints: "",
                        TrackingNumber: "",
                        Uid: "",
                      };

                      let RefferalPointsObj2 = {
                        Action: "Ref Point",
                        DateCreated: new Date().toString(),
                        NumberOfPoints: "",
                        TrackingNumber: "",
                        Uid: "",
                      };
                      //CHECK POINTS HISTORY FOR THE PACKAGE
                      console.log(packageDetails);
                      const checkIfPointExists = Query(
                        Collection(db, "PointsTracking"),
                        Where("TrackingNumber", "==", packageTnum),
                        Where("Uid", "==", packageDetails.Uid),
                        Where("Action", "==", "Add Point")
                      );

                      const querySnapshotPTrack = await GetDocs(
                        checkIfPointExists
                      );
                      querySnapshotPTrack.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        resPointsUser.push(doc.data());
                      });

                      console.log("length1 is" + resPointsUser.length);
                      if (resPointsUser.length === 0) {
                        //GIVE USER A POINT IF NO POINT WAS AWARDED PRVIOUSLY
                        const usersNewRef2 = Doc(
                          db,
                          "Users",
                          packageDetails.Uid
                        );
                        await UpdateDoc(usersNewRef2, payloadf1);
                        console.log("Checking user result for fetch user info");

                        RefferalPointsObj = {
                          Action: "Add Point",
                          DateCreated: new Date().toString(),
                          NumberOfPoints: "1",
                          TrackingNumber: packageTnum,
                          Uid: packageDetails.Uid,
                        };

                        if (resPointsNewPack.length === 0) {
                          await AddDoc(
                            Collection(db, "PointsTracking"),
                            RefferalPointsObj
                          );
                        }
                        //Check if USER WAS REFERED
                        const checkReferral = Query(
                          Collection(db, "Refferals"),
                          Where("NewCustUid", "==", packageDetails.Uid)
                        );

                        const querySnapshotCheckRef = await GetDocs(
                          checkReferral
                        );
                        let referealusercheck = [];
                        querySnapshotCheckRef.forEach((doc) => {
                          // doc.data() is never undefined for query doc snapshots
                          referealusercheck.push(doc.data());
                        });

                        if (referealusercheck.length > 0) {
                          let refferalRes = referealusercheck[0];
                          let reffererID = refferalRes.ReffererUid;
                          //CHECK POINTS HISTORY FOR THE PACKAGE
                          const checkIfPointExistsReferer = Query(
                            Collection(db, "PointsTracking"),
                            Where("TrackingNumber", "==", packageTnum),
                            Where("Uid", "==", reffererID),
                            Where("Action", "==", "Ref Point")
                          );
                          const querySnapshotPTrackReferrer = await GetDocs(
                            checkIfPointExistsReferer
                          );
                          querySnapshotPTrackReferrer.forEach((doc) => {
                            // doc.data() is never undefined for query doc snapshots
                            resPointsRefUser.push(doc.data());
                          });
                          console.log("length2 is" + resPointsUser.length);
                          if (resPointsRefUser.length === 0) {
                            //GIVE Referrer USER A POINT IF NO POINT WAS AWARDED PRVIOUSLY
                            //CHECK IF ReffUSER EXIST
                            console.log("Checking if ref user exist");
                            const userRef4 = Doc(db, "Users", reffererID);
                            const user4Snap = await GetDoc(userRef4);
                            if (user4Snap.exists()) {
                              console.log("ref user exist");
                              var user2 = user4Snap.data();
                              //console.log("verify email sent fetch user check ? " + user.verifiedemailsent);
                              let payloadf2 = {};
                              if (user2 !== null) {
                                payloadf2 = {
                                  ...user2,
                                  rPoints:
                                    user2.hasOwnProperty("rPoints") &&
                                    user.rPoints !== ""
                                      ? (parseInt(user2.rPoints) + 1).toString()
                                      : "1",
                                };
                                console.log("+ 1 point ref user");
                                // Set the "user"
                                const userRef5 = Doc(db, "Users", reffererID);
                                await UpdateDoc(userRef5, payloadf2)
                                  .then(async function (doc5) {
                                    //console.log("Checking user result for fetch user info");
                                    RefferalPointsObj2 = {
                                      Action: "Ref Point",
                                      DateCreated: new Date().toString(),
                                      NumberOfPoints: "1",
                                      TrackingNumber: packageTnum,
                                      Uid: reffererID,
                                    };
                                    console.log("+ 1 point ref user history");
                                    //track points
                                    await AddDoc(
                                      Collection(db, "PointsTracking"),
                                      RefferalPointsObj2
                                    );
                                    if (
                                      payloadf !== undefined &&
                                      payloadf !== null &&
                                      packIndex !== undefined &&
                                      packIndex !== null
                                    ) {
                                      // console.log(payloadf);
                                      // console.log(payloadf.rangeOfPackages);
                                      // console.log(payloadf.rangeOfPackages[packIndex].PackageInfo.Status);
                                      payloadf.rangeOfPackages[
                                        packIndex
                                      ].PackageInfo.Status = packageZip.status;
                                      //console.log(payloadf.rangeOfPackages[packIndex].PackageInfo.Status);
                                      dispatch({
                                        type: "find_packages_by_daterange",
                                        payload: payloadf,
                                      });
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              }
                            }
                          }
                        }
                        return true;
                      }
                    }
                  }
                } else {
                  if (
                    payloadf !== undefined &&
                    payloadf !== null &&
                    packIndex !== undefined &&
                    packIndex !== null
                  ) {
                    // console.log(payloadf);
                    // console.log(payloadf.rangeOfPackages);
                    // console.log(payloadf.rangeOfPackages[packIndex].PackageInfo.Status);
                    payloadf.rangeOfPackages[packIndex].PackageInfo.Status =
                      packageZip.status;
                    //console.log(payloadf.rangeOfPackages[packIndex].PackageInfo.Status);

                    dispatch({
                      type: "find_packages_by_daterange",
                      payload: payloadf,
                    });
                  }
                }

                return true;
              } else {
                return true;
              }
            })
            .catch(function (err) {
              //console.log("error fetching user info to send email")
              console.log(err);
              return false;
            });
          return finalResult;
        })
        .catch(function (error) {
          console.error("Error writing New Package Details: ", error);
          return false;
        });
      return storeRes;
    }
  };

  //ClearSearch Result
  var clearPackageResultStaff = async function clearPackageResultStaff(value) {
    if (value.singlePackage !== undefined) {
      value.singlePackage = undefined;
    }

    if (value.singlePackageId) {
      value.singlePackageId = undefined;
    }

    dispatch({
      type: "clear_search_res_staff",
      payload: value,
    });
  };

  //Check if package exist
  var checkIfPackageExistStaff = async function checkIfPackageExistStaff(
    tracking_numb,
    payloadf
  ) {
    var packArr = [];
    const q = Query(
      Collection(db, "PackagesNew"),
      Where("PackageInfo.TrackingNumber", "==", tracking_numb)
    );

    const querySnapshot = await GetDocs(q).catch(function (err) {
      //functionss.logger.log("Package with Tracking number exist:" + tracking_numb, err);
      //console.error("Error checking if package exist: ", err);
      return false;
    });

    querySnapshot.forEach((doc) => {
      //functionss.logger.log("Logging packages tracking Num: " + tracking_numb, doc.data());
      //console.log(doc.data());
      var res = doc.data(); //functionss.logger.log("Single package id is:" + doc.id, "Tracking Num: " + tracking_numb);
      //console.log("Single package id is:" + doc.id);
      payloadf.singlePackageId = doc.id;
      packArr.push(res);
    });

    if (packArr.length !== 0) {
      //functionss.logger.log("Package with Tracking number exist:" + tracking_numb);
      //console.log("Package with Tracking number exist.")
      payloadf.singlePackage = packArr;
      dispatch({
        type: "find_singlePack",
        payload: payloadf,
      });
      return packArr;
    } else {
      //functionss.logger.log("Package with Tracking number exist:" + tracking_numb);
      //console.log("Package with Tracking number does not exist.")
      return false;
    }
  };

  //FindPackages Within date range
  var findPackagesByDateRange = async function findPackagesByDateRange(
    start,
    end,
    payloadf
  ) {
    //console.log(start);
    //console.log(end);
    var tstartstamp = timeStamp.fromDate(new Date(start));
    var tendstamp = timeStamp.fromDate(new Date(end));

    try {
      const q = Query(
        Collection(db, "PackagesNew"),
        Where("PackageInfo.OrderDate", ">=", tstartstamp),
        Where("PackageInfo.OrderDate", "<=", tendstamp)
      );
      OnSnapshot(q, (querySnapshot) => {
        var fpackArr = [];
        querySnapshot.forEach((doc) => {
          //console.log("data found");
          //functionss.logger.log("Logging packages tracking Num: " + tracking_numb, doc.data());
          //console.log(doc.data());
          var res = doc.data();

          fpackArr.push({ ...res });
          //functionss.logger.log("Single package id is:" + doc.id, "Tracking Num: " + tracking_numb);
          //console.log("Single package id is:" + doc.id);
        });
        //functionss.logger.log("Array contents tracking num:" + tracking_numb);
        //functionss.logger.log("tracking num:" + tracking_numb, packArr);
        //console.log("Array contents");
        //console.log(packArr);

        if (fpackArr.length !== 0) {
          //functionss.logger.log("Package with Tracking number exist:" + tracking_numb);
          //console.log("Package with Tracking number exist.")
          payloadf.rangeOfPackages = [];
          payloadf.rangeOfPackages = fpackArr;
          //console.log(fpackArr);
          //console.log("dispatching data");
          dispatch({
            type: "find_packages_by_daterange",
            payload: payloadf,
          });
        } else {
          //functionss.logger.log("Package with Tracking number exist:" + tracking_numb);
          //console.log("Package with Tracking number does not exist.")
          return false;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  //create pre alert
  var createPreAlert = async function createPreAlert(
    packageZip,
    file,
    uid,
    UserInfo,
    mbNum
  ) {
    var tstamp = timeStamp.fromDate(new Date(packageZip.order_date));
    var packageDetails = {
      PackageInfo: {
        Cost: packageZip.cost,
        ItemName: packageZip.item_name,
        MailBoxNumber: mbNum,
        Merchant: packageZip.merchant,
        OrderDate: tstamp,
        Status: packageZip.status,
        TrackingNumber: packageZip.tracking_number,
        Weight: packageZip.weight,
        Courier: packageZip.courier,
        HouseNum: "",
      },
      Uid: uid,
      fullName: UserInfo.fullName,
    };
    var fileType = packageZip.content.type;
    var RequestParams = {
      user_email: "",
      user_name: "",
      merchant:
        packageZip.merchant !== null && packageZip.merchant !== undefined
          ? packageZip.merchant
          : "",
      status:
        packageZip.status !== null && packageZip.status !== undefined
          ? packageZip.status
          : "",
      content: file,
      tracking_number: packageZip.tracking_number,
    };
    var packArr = [];
    try {
      const checkIfPackageExists = Query(
        Collection(db, "PackagesNew"),
        Where("PackageInfo.TrackingNumber", "==", packageZip.tracking_number)
      );

      const querySnapshot = await GetDocs(checkIfPackageExists);
      querySnapshot.forEach((doc) => {
        //console.log(doc.data());
        //functionss.logger.log("Packages-Uid: " + uid,doc.data());
        var res = doc.data();
        packArr.push(res.PackageInfo);
      });
      //console.log("Array contents");
      //console.log(packArr);

      if (packArr.length > 0) {
        //console.log("Package with Tracking number exist.")
        return "Tracking number exist";
      } else {
        //console.log("Package with Tracking number does not exist.")
        await AddDoc(Collection(db, "PackagesNew"), packageDetails);
        try {
          //console.log("New Package Details  successfully written!");
          if (
            UserInfo !== null &&
            UserInfo !== undefined &&
            UserInfo.fullName !== "" &&
            UserInfo.email !== ""
          ) {
            RequestParams.user_email = UserInfo.email;
            RequestParams.user_name = UserInfo.fullName; //console.log("Params going to sendNewPackageMethod");
            //console.log(RequestParams);
            var emailRes = await sendPreAlertEmail(RequestParams, fileType)
              .then(function (emailSentRes) {
                if (emailSentRes) {
                  return true;
                } else {
                  //console.log("Unable to send add package email at this time.")
                  return true;
                }
              })
              .catch(function (err) {
                //console.log("Unable to send add package email at this time.")
                //console.log(err);
                return true;
              });
            return emailRes;
          } else {
            return true;
          }
        } catch {
          //console.error("Error writing New Package Details: ", error);
          return false;
        }
      }
    } catch {
      //console.error("Error checking if package exist: ", err);
      return false;
    }
  }; //store address info

  var uploadInvoice = async function uploadInvoice(
    packageZip,
    file,
    UserInfo,
    mailbox_Num,
    payloadf,
    packIndex
  ) {
    var fileType = packageZip.content.type;
    var RequestParams = {
      user_email: "",
      user_name: "",
      content: file,
      tracking_number: packageZip.tracking_number,
    };

    if (
      UserInfo !== null &&
      UserInfo !== undefined &&
      UserInfo.fullName !== "" &&
      UserInfo.email !== ""
    ) {
      RequestParams.user_email = UserInfo.email;
      RequestParams.user_name = UserInfo.fullName;
      //console.log("Params going to sendNewPackageMethod");
      var packArr = [];
      const q = Query(
        Collection(db, "PackagesNew"),
        Where("PackageInfo.TrackingNumber", "==", packageZip.tracking_number)
      );

      const querySnapshot = await GetDocs(q).catch(function (err) {
        //functionss.logger.log("Package with Tracking number exist:" + tracking_numb, err);
        //console.error("Error checking if package exist: ", err);
        return false;
      });

      querySnapshot.forEach((doc) => {
        //functionss.logger.log("Logging packages tracking Num: " + tracking_numb, doc.data());
        //console.log(doc.data());
        var res = doc.data(); //functionss.logger.log("Single package id is:" + doc.id, "Tracking Num: " + tracking_numb);
        //console.log("Single package id is:" + doc.id);
        payloadf.singlePackageId = doc.id;
        res.PackageInfo.Status = "Invoice Uploaded";
        packArr.push(res);
      });
      //console.log(RequestParams);
      var emailRes = await uploadInvoiceEmail(
        RequestParams,
        fileType,
        mailbox_Num
      )
        .then(function (emailSentRes) {
          if (emailSentRes) {
            let PackageInfo = {
              cost: packArr[0].PackageInfo.Cost,
              item_name: packArr[0].PackageInfo.ItemName,
              mailbox_number: packArr[0].PackageInfo.MailBoxNumber,
              merchant: packArr[0].PackageInfo.Merchant,
              tstamp: packArr[0].PackageInfo.OrderDate,
              status: "Invoice Uploaded",
              tracking_number: packArr[0].PackageInfo.TrackingNumber,
              weight: packArr[0].PackageInfo.Weight,
              courier: packArr[0].PackageInfo.Courier,
              fcost: packArr[0].PackageInfo.FinalCost,
              house_Num: packArr[0].PackageInfo.HouseNum,
              fullName: packArr[0].fullName,
              order_date: packArr[0].PackageInfo.OrderDate,
              duty:
                packArr[0].PackageInfo?.Duty !== undefined &&
                packArr[0].PackageInfo?.Duty !== null
                  ? packArr[0].PackageInfo?.Duty
                  : 0,
            };

            editPackageStaff(
              PackageInfo,
              packageZip.tracking_number,
              payloadf,
              packIndex
            );
            return true;
          } else {
            //console.log("Unable to send add package email at this time.")
            return true;
          }
        })
        .catch(function (err) {
          //console.log("Unable to send add package email at this time.")
          console.log(err);
          return true;
        });
      return emailRes;
    } else {
      return false;
    }
  };

  const createInvoiceRows = async function createInvoiceRows(processedPacks) {
    // console.log(processedPacks);
    // Check for Points usage:
    const q = Query(
      Collection(db, "PointsTracking"),
      Where("Uid", "==", processedPacks[0].Uid),
      Where("Action", "==", "Use Points")
    );

    const querySnapshot = await GetDocs(q);
    const pointsArr = [];
    querySnapshot.forEach((doc) => {
      const res = doc.data();
      pointsArr.push({ ...res });
      //console.log("Single package id is:" + doc.id);
    });
    // Check result of points history
    // console.log(pointsArr);
    // Concatenate JSX elements as a string
    const rows = [];
    processedPacks.map((item) => {
      let filteredpoints = pointsArr.filter(
        (points) => item.TrackingNumber === points.TrackingNumber
      );

      if (filteredpoints.length > 0) {
        // console.log("tracking found");
        // console.log(filteredpoints);
        // console.log(filteredpoints[0].NumberOfPoints);
        if (item?.Duty !== null && item?.Duty !== undefined && item?.Duty > 0) {
          rows.push(`<tr key="${item.TrackingNumber}">
          <td style="padding: 10px; border-bottom: 1px solid #ddd;">
          <p>Package Weight: ${item.Weight}LBS</p>
          <p>Shipper: ${item.Merchant}</p>
          <p>Tracking #: ${item.TrackingNumber}</p>
          </td>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;text-align: right;">$${
            item.FinalCost
          }</td>
          </tr>
          <tr>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;">&nbsp;DUTY ON PACKAGE, TRACKING#: ${
            item.TrackingNumber
          }</td>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;text-align: right;">$${
            item?.Duty
          }</td>
          </tr>
          <tr>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;">&nbsp;POINTS ALLOTMENT</td>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;text-align: right;">- $${
            parseInt(filteredpoints[0]?.NumberOfPoints) * 50
          }</td>
          </tr>`);
        } else {
          rows.push(`<tr key="${item.TrackingNumber}">
          <td style="padding: 10px; border-bottom: 1px solid #ddd;">
          <p>Package Weight: ${item.Weight}LBS</p>
          <p>Shipper: ${item.Merchant}</p>
          <p>Tracking #: ${item.TrackingNumber}</p>
          </td>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;text-align: right;">$${
            item.FinalCost
          }</td>
          </tr>
          <tr>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;">&nbsp;POINTS ALLOTMENT</td>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;text-align: right;">- $${
            parseInt(filteredpoints[0]?.NumberOfPoints) * 50
          }</td>
          </tr>`);
        }
      } else {
        if (item?.Duty !== null && item?.Duty !== undefined && item?.Duty > 0) {
          rows.push(`<tr key="${item.TrackingNumber}">
          <td style="padding: 10px; border-bottom: 1px solid #ddd;">
          <p>Package Weight: ${item.Weight}LBS</p>
          <p>Shipper: ${item.Merchant}</p>
          <p>Tracking #: ${item.TrackingNumber}</p>
          </td>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;text-align: right;">$${item.FinalCost}</td>
          </tr>
          <tr>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;">&nbsp;DUTY ON PACKAGE, TRACKING#: ${item.TrackingNumber}</td>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;text-align: right;">$${item?.Duty}</td>
          </tr>`);
        } else {
          rows.push(`<tr key="${item.TrackingNumber}">
          <td style="padding: 10px; border-bottom: 1px solid #ddd;">
          <p>Package Weight: ${item.Weight}LBS</p>
          <p>Shipper: ${item.Merchant}</p>
          <p>Tracking #: ${item.TrackingNumber}</p>
          </td>
          <td style="padding: 10px; border-bottom: 1px solid #ddd;text-align: right;">$${item.FinalCost}</td>
          </tr>`);
        }
      }
      return null;
    });

    // console.log(rows);
    let rowsJoined = "";
    rowsJoined = rows.join("");
    if (typeof rowsJoined === "string") {
      while (rowsJoined.includes("</tr>,")) {
        rowsJoined = rowsJoined.replace("</tr>,", "</tr>");
      }
    } else {
      rowsJoined = "";
    }
    return rowsJoined;
  };

  //generate an invoice number
  const generateInvoiceNumber = function generateInvoiceNumber() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const randomPart = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    const invoiceNumber = `${year}${month}${day}-${hours}${minutes}${seconds}-${randomPart}`;
    return invoiceNumber;
  };

  var addInvoice = async function addInvoice(invoiceInfo, payload) {
    await AddDoc(Collection(db, "Invoices"), invoiceInfo);
    payload.invoiceDetails.push(invoiceInfo);
    dispatch({ type: "add invoice", payload });
    return true;
  };

  var getInvoices = async function getInvoices(uid, payload) {
    const q = Query(
      Collection(db, "Invoices"),
      Where("uid", "==", uid),
      Where("paid", "==", false)
    );

    const querySnapshot = await GetDocs(q).catch(function (error) {
      //console.log("Error getting document:", error);
      return "error";
    });

    const invArr = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        //console.log(doc.id);
        invArr.push(doc.data());
      }
    });

    payload.invoiceDetails = invArr;

    // console.log(payload.invoiceDetails);

    dispatch({ type: "add invoice", payload });
  };

  var updateInvoice = async function updateInvoice(
    invoiceUpdate,
    payload,
    id,
    trackingNums
  ) {
    try {
      // console.log("inside updateInvoice");
      // console.log(invoiceUpdate);
      // console.log(payload.invoiceDetails);
      const updateInvRef = Doc(db, "Invoices", id);
      await UpdateDoc(updateInvRef, invoiceUpdate);
      var tempInvoices = payload.invoiceDetails;

      // console.log(tempInvoices);
      if (tempInvoices.length > 0) {
        tempInvoices.map((item, index) => {
          if (item.trackingNums.includes(trackingNums[0])) {
            const tempInvoices2 = tempInvoices;
            // console.log(tempInvoices2);
            tempInvoices2[index].trackingNums = trackingNums;
            tempInvoices2[index].paid = invoiceUpdate.paid;
            tempInvoices2[index].dateUpdated = invoiceUpdate.dateUpdated;
            payload.invoiceDetails = tempInvoices2;
          }
          return null;
        });
      } else {
        tempInvoices.push({
          ...payload.invoiceDetails,
          paid: invoiceUpdate.paid,
          dateUpdated: invoiceUpdate.dateUpdated,
        });
        payload.invoiceDetails = tempInvoices;
      }

      dispatch({ type: "add invoice", payload });
      return true;
    } catch (err) {
      console.log("error updating invoice");
      console.log(err);
      return false;
    }
  };

  var sendInvoice = async function sendInvoice(
    checkedList,
    filteredPacks,
    payload,
    paidInvoiceFinal,
    invoiceNum
  ) {
    // console.log("checkedList packs");
    // console.log(checkedList);
    var processedPacks = checkedList.map((checkitem) => {
      let res = filteredPacks.filter(
        (pack) => pack.TrackingNumber == checkitem.tnum
      );

      return res[0];
    });

    //console.log("processedPacks packs");
    //console.log(processedPacks);

    //Create table rows
    var finalRows = "";
    await createInvoiceRows(processedPacks)
      .then((res) => {
        finalRows = res;
      })
      .catch((rowErr) => {
        console.log("Issue creating invoice table rows");
        console.log(rowErr);
        finalRows = "failed rows";
      });

    if (finalRows === "failed rows") {
      return false;
    }
    // console.log("rows check");
    // console.log(finalRows);
    //Prepare invoice date
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    //Prepare Invoice Total
    let subtotal = 0;
    processedPacks.map((item) => {
      subtotal += item.FinalCost + item.Duty;
      return null;
    });
    // console.log(finalRows);
    var RequestParams = {
      user_email: "",
      user_name: "",
      Mailbox_Num: filteredPacks[0].MailBoxNumber,
      inv_rows: finalRows, //htmlrows.body.firstChild,
      inv_total: subtotal,
      inv_date: formattedDate,
      invoiceNum: invoiceNum,
      invoicePaid: paidInvoiceFinal,
    };
    // feth user by uid
    const userssRef = Doc(db, "Users", filteredPacks[0].Uid);
    const docSnap = await GetDoc(userssRef);

    var customer = {};
    if (docSnap.exists()) {
      var res = docSnap.data();
      customer = res;
      RequestParams.user_email = customer.email;
      RequestParams.user_name = customer.fullName;
      // console.log("Params going to sendNewPackageMethod");
      // console.log(RequestParams);
      return await sendInvoiceEmail(RequestParams)
        .then(async function (emailSentRes) {
          if (emailSentRes) {
            if (paidInvoiceFinal) {
              // console.log("paid route");
              let invoiceUpdate = {
                trackingNums: [],
                paid: paidInvoiceFinal,
                paidAmount: subtotal,
                total: subtotal,
                customerName: RequestParams.user_name,
                dateUpdated: timeStamp.fromDate(new Date()),
              };

              processedPacks.map(async (item, index) => {
                // invoiceUpdate.trackingNums.push(item.TrackingNumber);
                console.log(item);
                let PackageInfo = {
                  cost: item.Cost,
                  courier: item.Courier,
                  duty:
                    item?.Duty !== undefined && item?.Duty !== null
                      ? item?.Duty
                      : 0,
                  fcost: item.FinalCost,
                  house_Num: item.HouseNum,
                  item_name: item.ItemName,
                  mailbox_number: item.MailBoxNumber,
                  merchant: item.Merchant,
                  order_date: item.OrderDate,
                  tstamp: item.OrderDate,
                  status: "Delivered",
                  tracking_number: item.TrackingNumber,
                  weight: item.Weight,
                  fullName: item.fullName,
                };

                let packIndex = payload.rangeOfPackages.findIndex(
                  (pack) =>
                    pack.PackageInfo.TrackingNumber === item.TrackingNumber
                );

                if (packIndex !== -1) {
                  console.log("Index found");
                  await editPackageStaff(
                    PackageInfo,
                    PackageInfo.tracking_number,
                    payload,
                    packIndex
                  );
                } else {
                  console.log("Index not found");
                  // editPackageStaff(
                  //   PackageInfo,
                  //   PackageInfo.tracking_number,
                  //   payload,
                  //   undefined
                  // );
                }
              });

              ///////////////////////
              const q = Query(
                Collection(db, "Invoices"),
                Where("uid", "==", processedPacks[0].Uid),
                Where("paid", "==", false)
              );

              const querySnapshot = await GetDocs(q).catch(function (error) {
                //console.log("Error getting document:", error);
                return "error";
              });

              var invID = "";
              var trackingNums = [];
              querySnapshot.forEach((doc) => {
                if (doc.exists) {
                  //console.log(doc.id);
                  var tempInv = doc.data();
                  processedPacks.map((item) => {
                    if (tempInv.trackingNums.includes(item.TrackingNumber)) {
                      invID = doc.id;
                      trackingNums = tempInv.trackingNums;
                    }
                  });
                }
              });

              //add new packs
              processedPacks.map((item) => {
                if (!invoiceUpdate.trackingNums.includes(item.TrackingNumber)) {
                  invoiceUpdate.trackingNums.push(item.TrackingNumber);
                }
              });

              ////////////////////////
              await updateInvoice(invoiceUpdate, payload, invID, trackingNums);
              return true;
            } else {
              let invoiceInfo = {
                invoiceNum,
                trackingNums: [],
                paid: paidInvoiceFinal,
                paidAmount: subtotal,
                total: subtotal,
                customerName: RequestParams.user_name,
                uid: processedPacks[0].Uid,
              };
              processedPacks.map((item, index) => {
                invoiceInfo.trackingNums.push(item.TrackingNumber);
              });

              ///////////////////////
              const q = Query(
                Collection(db, "Invoices"),
                Where("uid", "==", invoiceInfo.uid),
                Where("paid", "==", false)
              );

              const querySnapshot = await GetDocs(q).catch(function (error) {
                //console.log("Error getting document:", error);
                return "error";
              });

              var invID = "";
              var trackingNums = [];
              querySnapshot.forEach((doc) => {
                if (doc.exists) {
                  //console.log(doc.id);
                  var tempInv = doc.data();
                  processedPacks.map((item) => {
                    if (tempInv.trackingNums.includes(item.TrackingNumber)) {
                      invID = doc.id;
                      trackingNums = tempInv.trackingNums;
                    }
                  });
                }
              });

              ////////////////////////
              if (invID !== "" && invID !== null && invID !== undefined) {
                let invoiceUpdate = {
                  trackingNums: trackingNums,
                  paid: paidInvoiceFinal,
                  paidAmount: subtotal,
                  total: subtotal,
                  customerName: RequestParams.user_name,
                  dateUpdated: new Date().toString(),
                };

                //add new packs
                processedPacks.map((item) => {
                  if (
                    !invoiceUpdate.trackingNums.includes(item.TrackingNumber)
                  ) {
                    invoiceUpdate.trackingNums.push(item.TrackingNumber);
                  }
                });

                await updateInvoice(
                  invoiceUpdate,
                  payload,
                  invID,
                  trackingNums
                );
              } else {
                await addInvoice(invoiceInfo, payload);
              }
              return true;
            }
          } else {
            //console.log("Unable to send add package email at this time.")
            return true;
          }
        })
        .catch(function (err) {
          console.log("Unable to send add package email at this time.");
          console.log(err);
          return true;
        });
    } else {
      return false;
    }
  };

  var storeMailBoxNumber = async function storeMailBoxNumber(number, uid) {
    var mailboxDetails = {
      Status: "O",
      Uid: uid,
    };
    var res = await mailBoxExist(number);

    if (!res) {
      try {
        await SetDoc(Doc(db, "MailBoxes", number), mailboxDetails);
        return true;
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }
  };

  var storeShippingAddress = async function storeShippingAddress(number, uid) {
    try {
      var addresses = {
        AirFreight: {
          addressLine1: "9585 NW 28th St,",
          addressLine2: "MGK-",
          addressLine3: "Coral Springs, Florida 33065",
          mailboxNumber: number,
        },
        SeaFreight: {
          addressLine1: "3750 Oakland Park Blvd",
          addressLine2: "Lastmile, MGK-",
          addressLine3: "Lauderdale Lakes, Fl 33311",
          mailboxNumber: number,
        },
      };
      await SetDoc(Doc(db, "ShippingAddress", uid), addresses);
      return addresses;
    } catch (error) {
      //console.error("Error writing Shipping Address: ", error);
      return "failedShipAddress";
    }
  };

  var mailBoxExist = async function mailBoxExist(number) {
    const docRef = Doc(db, "MailBoxes", number);
    const docSnap = await GetDoc(docRef);
    //console.log("querying mailboxes");

    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      return true;
    } else {
      // doc.data() will be undefined in this case
      //console.log("No such document!");
      return false;
    }
  };

  //get user mailbox for staff
  var getUserMailboxNumberForStaff =
    async function getUserMailboxNumberForStaff(userID) {
      //console.log("Logging Mailbox num");
      var mbNum = "";
      //console.log("querying mailboxes");
      const q = Query(Collection(db, "MailBoxes"), Where("Uid", "==", userID));

      const querySnapshot = await GetDocs(q).catch(function (error) {
        //console.log("Error getting document:", error);
        return "error";
      });
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          //console.log(doc.id);
          mbNum = doc.id;
        } else {
          // doc.data() will be undefined in this case
          //console.log("No such MailBoxNumber!");
          mbNum = "failed";
        }
      });
      return mbNum;
    };

  var getUserByMailboxNumberForStaff = async function getUserByMailboxNumber(
    MailBoxNumber
  ) {
    //console.log("querying mailboxes");
    const MailBoxesRef = Doc(db, "MailBoxes", MailBoxNumber);
    const docSnap = await GetDoc(MailBoxesRef);

    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      var Uid = docSnap.data();
      return Uid.Uid;
    } else {
      // doc.data() will be undefined in this case
      //console.log("No such MailBoxNumber!");
      return "failed";
    }
  };

  var getUserByMailboxNumber = async function getUserByMailboxNumber(
    MailBoxNumber
  ) {
    //console.log("querying mailboxes");
    const MailBoxesRef = Doc(db, "MailBoxes", MailBoxNumber);
    const docSnap = await GetDoc(MailBoxesRef);

    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      var Uid = docSnap.data();
      return Uid.Uid;
    } else {
      // doc.data() will be undefined in this case
      //console.log("No such MailBoxNumber!");
      return "failed";
    }
  };

  //update user info
  var updateUserInfo = async function updateUserInfo(
    uid,
    payloadf,
    currentState
  ) {
    //console.log("User id is: ");
    //console.log(uid);
    //console.log(payloadf);
    //console.log(currentState);
    //console.log("updating  user");
    //console.log("mbox b4 update", payloadf.mailbox_Num);
    var user = null;
    //console.log(payloadf);
    if (currentState !== null) {
      user = {
        contactNumber:
          currentState.contact !== null && currentState.contact !== undefined
            ? currentState.contact
            : payloadf.userInfo.contactNumber,
        email:
          currentState.email !== null && currentState.email !== undefined
            ? currentState.email
            : payloadf.userInfo.email,
        fullName:
          currentState.fullname !== null && currentState.fullname !== undefined
            ? currentState.fullname.toLowerCase()
            : payloadf.userInfo.fullName,
        verified: payloadf.userInfo.verified,
        verifiedemailsent: payloadf.userInfo.verifiedemailsent,
        addressLine1:
          currentState.address_line_1 !== null &&
          currentState.address_line_1 !== undefined
            ? currentState.address_line_1
            : payloadf.userInfo.addressLine1,
        addressLine2:
          currentState.address_line_2 !== null &&
          currentState.address_line_2 !== undefined
            ? currentState.address_line_2
            : payloadf.userInfo.addressLine2,
        city:
          currentState.city !== null && currentState.city !== undefined
            ? currentState.city
            : payloadf.userInfo.city,
        postalCode:
          currentState.postal_code !== null &&
          currentState.postal_code !== undefined
            ? currentState.postal_code
            : payloadf.userInfo.postalCode,
        stateOrparish:
          currentState.state_or_parish !== null &&
          currentState.state_or_parish !== undefined
            ? currentState.state_or_parish
            : payloadf.userInfo.stateOrparish,
        dateCreated:
          currentState.dateCreated !== null &&
          currentState.dateCreated !== undefined
            ? currentState.dateCreated
            : payloadf.userInfo.dateCreated,
        rPoints:
          payloadf.userInfo.rPoints !== null &&
          payloadf.userInfo.rPoints !== undefined &&
          payloadf.userInfo.rPoints !== ""
            ? payloadf.userInfo.rPoints
            : "0",
        mailbox_Num:
          payloadf.mailbox_Num !== null && payloadf.mailbox_Num !== undefined
            ? payloadf.mailbox_Num
            : "",
      };
    } else {
      //console.log("what is user info carrying in payloadf");
      //console.log(payloadf);
      user = {
        contactNumber:
          payloadf.userInfo.contactNumber != null &&
          payloadf.userInfo.contactNumber !== undefined
            ? payloadf.userInfo.contactNumber
            : "",
        email: payloadf.userInfo.email,
        fullName: payloadf.userInfo.fullName.toLowerCase(),
        verified: payloadf.userInfo.verified,
        verifiedemailsent: payloadf.userInfo.verifiedemailsent,
        addressLine1: payloadf.userInfo.addressLine1,
        addressLine2: payloadf.userInfo.addressLine2,
        city: payloadf.userInfo.city,
        postalCode: payloadf.userInfo.postalCode,
        stateOrparish: payloadf.userInfo.stateOrparish,
        dateCreated: payloadf.userInfo.dateCreated,
        rPoints:
          payloadf.userInfo.rPoints !== null &&
          payloadf.userInfo.rPoints !== undefined &&
          payloadf.userInfo.rPoints !== ""
            ? payloadf.userInfo.rPoints
            : "0",
        mailbox_Num:
          payloadf.mailbox_Num !== null && payloadf.mailbox_Num !== undefined
            ? payloadf.mailbox_Num
            : "",
      };
    } //console.log("User data is: ");
    //console.log(user);
    try {
      const updateUserRef = Doc(db, "Users", uid);
      await UpdateDoc(updateUserRef, user);
      payloadf.Rates = [];
      payloadf.RatesContd = [];

      dispatch({
        type: "fetch_userinfo",
        payload: payloadf,
      });
    } catch {
      //console.error("Error updating user info: ", error);
      return false;
    }
  };

  //update rates info
  var updateRates = async function updateRates(payloadf, currentState) {
    try {
      const rates = [
        { Cost: currentState.L1, Pound: "1 LB" },
        { Cost: currentState.L2, Pound: "2 LB" },
        { Cost: currentState.L3, Pound: "3 LB" },
        { Cost: currentState.L4, Pound: "4 LB" },
        { Cost: currentState.L5, Pound: "5 LB" },
        { Cost: currentState.L6, Pound: "6 LB" },
        { Cost: currentState.L7, Pound: "7 LB" },
        { Cost: currentState.L8, Pound: "8 LB" },
        { Cost: currentState.L9, Pound: "9 LB" },
        { Cost: currentState.L10, Pound: "10 LB" },
      ];

      rates.map(async (item, index) => {
        const updateUserRef = Doc(db, "Rates", `${index + 1}LB`);
        await UpdateDoc(updateUserRef, item);
        return null;
      });

      payloadf.Rates = rates;

      dispatch({
        type: "update_Rates",
        payload: payloadf,
      });
    } catch {
      //console.error("Error updating user info: ", error);
      return false;
    }
  };

  var updateRatesContd = async function updateRatesContd(
    payloadf,
    currentState
  ) {
    try {
      const ratesC = [
        { Cost: currentState.L1, Pound: "11 LB" },
        { Cost: currentState.L2, Pound: "12 LB" },
        { Cost: currentState.L3, Pound: "13 LB" },
        { Cost: currentState.L4, Pound: "14 LB" },
        { Cost: currentState.L5, Pound: "15 LB" },
        { Cost: currentState.L6, Pound: "16 LB" },
        { Cost: currentState.L7, Pound: "17 LB" },
        { Cost: currentState.L8, Pound: "18 LB" },
        { Cost: currentState.L9, Pound: "19 LB" },
        { Cost: currentState.L10, Pound: "20 LB" },
      ];

      ratesC.map(async (item, index) => {
        const updateUserRef = Doc(db, "RatesContd", `${index + 11}LB`);
        await UpdateDoc(updateUserRef, item);
        return null;
      });

      payloadf.RatesContd = ratesC;

      dispatch({
        type: "update_RatesContd",
        payload: payloadf,
      });
    } catch {
      //console.error("Error updating user info: ", error);
      return false;
    }
  };

  //get rates info
  var getRates = async function getRates(payloadf) {
    try {
      const q = Query(Collection(db, "Rates"));

      const querySnapshot = await GetDocs(q);
      const ratesArr = [];
      querySnapshot.forEach((doc) => {
        const res = doc.data();
        ratesArr.push({ ...res });
        //console.log("Single package id is:" + doc.id);
      });
      // console.log(ratesArr);
      payloadf.Rates = ratesArr;

      dispatch({
        type: "fetch_Rates",
        payload: payloadf,
      });
    } catch {
      //console.error("Error updating user info: ", error);
      return false;
    }
  };

  var getRatesContd = async function getRatesContd(payloadf) {
    try {
      const q = Query(Collection(db, "RatesContd"));

      const querySnapshot = await GetDocs(q);
      const ratesContdArr = [];
      querySnapshot.forEach((doc) => {
        const res = doc.data();
        ratesContdArr.push({ ...res });
        //console.log("Single package id is:" + doc.id);
      });
      // console.log(ratesContdArr);
      payloadf.RatesContd = ratesContdArr;

      dispatch({
        type: "fetch_Rates",
        payload: payloadf,
      });
    } catch {
      //console.error("Error updating user info: ", error);
      return false;
    }
  };

  var getAdditionalLB = async function getAdditionalLB(payloadf) {
    try {
      const q = Query(Collection(db, "AdditionalPoundsCost"));

      const querySnapshot = await GetDocs(q);
      const addratesArr = [];
      querySnapshot.forEach((doc) => {
        const res = doc.data();
        addratesArr.push({ ...res });
        //console.log("Single package id is:" + doc.id);
      });
      // console.log(ratesContdArr);
      payloadf.AddLB = addratesArr;

      dispatch({
        type: "fetch_Rates",
        payload: payloadf,
      });
    } catch {
      //console.error("Error updating user info: ", error);
      return false;
    }
  };

  //Generate MailboxNumber
  var generate = function generate(uid) {
    //console.log("inside generate method")
    try {
      var number = 0;
      number = Math.floor(Math.random() * 90000) + 10000; //console.log(number);
      try {
        var storedNumber = storeMailBoxNumber(number.toString(), uid);
        //console.log("result from store method: " + res);
        if (!storedNumber) {
          return "failed";
        } //console.log("returning number")

        return number.toString();
      } catch (error) {
        //console.log(error);
        return "failed";
      }
    } catch (err) {
      //console.log("UNABLE TO GENERATE NUM", err)
      return "failed";
    }
  }; //Check if user have a role

  var userHasRole = async function userHasRole(uid, payload, newUserPosition) {
    //console.log("User id is: ");
    //console.log(uid);
    //console.log("fetching user role");
    const docRef = Doc(db, "UsersInRoles", uid);
    const docSnap = await GetDoc(docRef);

    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data()); //console.log("user role exist");
      //console.log(doc.data());
      //console.log("what is inside payload");
      //console.log(payload);
      // Convert to City object
      var userRole = docSnap.data();
      //console.log("user in role res:");
      //console.log(userRole);
      if (userRole !== null) {
        const rolesRef = Doc(db, "Roles", userRole.roleId);
        const rolesSnap = await GetDoc(rolesRef);
        if (rolesSnap.exists()) {
          var res = rolesSnap.data();
          //console.log("Role Exists is?");
          //console.log(res);
          payload.userRolef = res.role;
        }
      }

      return payload;
    } else {
      //console.log("No such user role!")
      //console.log("creating new userinrole");
      var userInRole = null;
      if (newUserPosition === undefined) {
        userInRole = {
          roleId: process.env.REACT_APP_Customer_Role,
        };
      } else if (newUserPosition === "Staff") {
        userInRole = {
          roleId: process.env.REACT_APP_Staff_Role,
        };
      } else if (newUserPosition === "Admin") {
        userInRole = {
          roleId: process.env.REACT_APP_Admin_Role,
        };
      }
      try {
        await SetDoc(Doc(db, "UsersInRoles", uid), userInRole);
        payload.userRolef = "Customer";
      } catch {
        //console.log("What is in payload after creating role: ");
        //console.log(payload);
      }
      return payload;
    }
  };

  var fetchUserRole = async function fetchUserRole(uid, payload) {
    // console.log("User id is: ");
    // console.log(uid);
    // console.log("fetching user role");
    const userRef = Doc(db, "UsersInRoles", uid);
    const docSnap = await GetDoc(userRef);

    if (docSnap.exists()) {
      //console.log("Checking user result");
      // console.log("user role exist");
      // console.log(doc.data());
      // console.log("what is inside payload");
      // console.log(payload);
      //Convert to City object
      var userRole = docSnap.data();
      // console.log("user in role res:");
      // console.log(userRole);
      if (userRole !== null) {
        const roleRef = Doc(db, "Roles", userRole.roleId);
        const docSnap = await GetDoc(roleRef);

        if (docSnap.exists()) {
          var res = docSnap.data();
          payload.userRolef = res.role;
          dispatch({ type: "fetch_userinfo", payload: payload });
        }
      }
    }
  };

  //Create Schedule Delivery Record

  var scheduleDelivery = async function scheduleDelivery(
    packageZip,
    UserInfo,
    Mailbox_Num,
    uid
  ) {
    var packageDetails = {
      Schedule: {
        AdditionalInstractions: packageZip.additionalinstructions,
        Cost: packageZip.cost,
        DeliveryDate: packageZip.deliverydate,
        FullAddress: packageZip.fulladdress,
        Location: packageZip.location,
        PaymentType: packageZip.payment_type,
        TimeOfday: packageZip.timeofday,
        TrackingNumbers: packageZip.truck,
        HouseNum: packageZip.house_Num,
      },
      Status: "Pending",
      Uid: uid,
    };
    var tNumbers = "";

    for (var i = 0; i < packageZip.truck.length; ) {
      tNumbers = tNumbers + packageZip.truck[i] + "  ";
      i = i + 1;
    }

    var RequestParams = {
      user_email: UserInfo.email,
      from_name: UserInfo.fullName,
      d_time: packageZip.timeofday,
      d_area: packageZip.location,
      d_add: packageZip.fulladdress,
      d_date: packageZip.deliverydate,
      d_instruct: packageZip.additionalinstructions,
      p_num: UserInfo.contactNumber,
      t_nums: tNumbers,
      cost: packageZip.cost,
    };
    try {
      await AddDoc(Collection(db, "DeliverySchedule"), packageDetails);
      //console.log("Params going to sendScheduleDeliveryEmailMethod");
      //console.log(RequestParams);
      var emailRes = await sendScheduleDeliveryEmail(RequestParams)
        .then(function (emailSentRes) {
          if (emailSentRes) {
            return true;
          } else {
            //console.log("Unable to send schedule delivery email at this time.");
            return true;
          }
        })
        .catch(function (err) {
          //console.log("Unable to send schedule delivery email at this time.");
          //console.log(err);
          return true;
        });
      return emailRes;
    } catch (error) {
      //console.error("Error writing New Delivery Details: ", error);
      return false;
    }
  }; //Fetch packages already scheduled for delivery

  var checkIfPackageIsAlreadyScheduled =
    async function checkIfPackageIsAlreadyScheduled(packages, Uid) {
      //console.log("Inside check paack schedule for: " + Uid);
      var packArr = [];
      var alreadyShceduledArr = [];

      const q = Query(
        Collection(db, "DeliverySchedule"),
        Where("Uid", "==", Uid)
      );

      const querySnapshot = await GetDocs(q).catch(function (err) {
        //console.log(err);
        return "failed";
      });
      if (querySnapshot !== "failed") {
        querySnapshot.forEach((doc) => {
          //console.log("Snapshot data is: ");
          //console.log(doc.data());
          //functionss.logger.log("Packages-Uid: " + packageZip.mailbox_number,doc.data());
          var res = doc.data();
          packArr.push(res);
        });

        //console.log("Array contents");
        //console.log(packArr);
        //console.log("what is in packages? ");
        //console.log(packages);
        var internalArr = [];
        for (var i = 0; i < packArr.length; ) {
          var internalArr = packArr[i].Schedule.TrackingNumbers;
          //console.log("What is inside internal array?");
          //console.log(internalArr);
          for (var r = 0; r < internalArr.length; ) {
            for (var f = 0; f < packages.length; ) {
              //console.log("Track 1: " + packages[f].TrackingNumber + " Track2: " + internalArr[r] + " Status: " + packArr[i].Status);
              if (
                packages[f].TrackingNumber === internalArr[r] &&
                packArr[i].Status !== "Cancelled" &&
                packArr[i].Status !== "Delivered"
              ) {
                //console.log("tracking number found");
                alreadyShceduledArr.push(packages[f]);
              }
              f = f + 1;
            }
            r = r + 1;
          }

          i = i + 1;
        }
        //console.log(alreadyShceduledArr);
        return alreadyShceduledArr;
      } else {
        return "failed";
      }
    };

  //Send Emails
  var sendCostomerVerificationEmail =
    async function sendCostomerVerificationEmail(userEmail, userName, value) {
      //email enabled
      if (value.userInfo.verifiedemailsent !== true) {
        //console.log("Username b4 send email is:" + userName);
        if (
          value.airFreightAdd !== null &&
          value.airFreightAdd !== undefined &&
          value.seaFreightAdd !== null &&
          value.seaFreightAdd !== undefined
        ) {
          var RequestParams = {
            username:
              userName !== null && userName !== undefined ? userName : "",
            user_email:
              userEmail !== null && userEmail !== undefined ? userEmail : "",
            message:
              "https://us-central1-mgk-prod-deploy.cloudfunctions.net/verifyAccount/" +
              value.mailbox_Num,
            air_line1:
              value.airFreightAdd.addressLine1 !== null &&
              value.airFreightAdd.addressLine1 !== undefined
                ? value.airFreightAdd.addressLine1
                : "",
            air_line2:
              value.airFreightAdd.addressLine2 !== null &&
              value.airFreightAdd.addressLine2 !== undefined &&
              value.mailbox_Num !== null &&
              value.mailbox_Num !== undefined
                ? value.airFreightAdd.addressLine2 + value.mailbox_Num
                : "",
            air_city:
              value.airFreightAdd.city !== null &&
              value.airFreightAdd.city !== undefined
                ? value.airFreightAdd.city
                : "",
            air_state:
              value.airFreightAdd.state !== null &&
              value.airFreightAdd.state !== undefined
                ? value.airFreightAdd.state
                : "",
            air_zip:
              value.airFreightAdd.zipCode !== null &&
              value.airFreightAdd.zipCode !== undefined
                ? value.airFreightAdd.zipCode
                : "",
            sea_line1:
              value.seaFreightAdd.addressLine1 !== null &&
              value.seaFreightAdd.addressLine1 !== undefined
                ? value.seaFreightAdd.addressLine1
                : "",
            sea_line2:
              value.seaFreightAdd.addressLine2 !== null &&
              value.seaFreightAdd.addressLine2 !== undefined &&
              value.mailbox_Num !== null &&
              value.mailbox_Num !== undefined
                ? value.seaFreightAdd.addressLine2 + value.mailbox_Num
                : "",
            sea_city:
              value.seaFreightAdd.city !== null &&
              value.seaFreightAdd.city !== undefined
                ? value.seaFreightAdd.city
                : "",
            sea_state:
              value.seaFreightAdd.state !== null &&
              value.seaFreightAdd.state !== undefined
                ? value.seaFreightAdd.state
                : "",
            sea_zip:
              value.seaFreightAdd.zipCode !== null &&
              value.seaFreightAdd.zipCode !== undefined
                ? value.seaFreightAdd.zipCode
                : "",
          }; //console.log("Request params username is: " + RequestParams.username);

          await sendEmail(
            emailServiceId,
            emailVerifyTemplate,
            RequestParams,
            emailUserId
          )
            .then(async function (res) {
              //console.log("Send Email Success " + res);
              if (res) {
                value.userInfo.verifiedemailsent = true;
                console.log("aBout to call update user info ");

                await updateUserInfo(value.currentUser.uid, value, null); //dispatch({type: "sent_verify_email", payload: value});
              }
            })
            .catch(function (err) {
              //console.log("Send email error");
              //console.log(err);
            });
        } else {
          var RequestParams = {
            username:
              userName !== null && userName !== undefined ? userName : "",
            user_email:
              userEmail !== null && userEmail !== undefined ? userEmail : "",
            message:
              "https://us-central1-mgk-prod-deploy.cloudfunctions.net/verifyAccount/" +
              value.mailbox_Num,
            air_line1: "",
            air_line2: "",
            air_city: "",
            air_state: "",
            air_zip: "",
            sea_line1: "",
            sea_line2: "",
            sea_city: "",
            sea_state: "",
            sea_zip: "",
          }; //console.log("Request params username is: " + RequestParams.username);

          await sendEmail(
            emailServiceId,
            emailVerifyTemplate,
            RequestParams,
            emailUserId
          )
            .then(async function (res) {
              //console.log("Send Email Success " + res);
              if (res) {
                value.userInfo.verifiedemailsent = true; //console.log("aBout to call update user info ");

                await updateUserInfo(value.currentUser.uid, value, null); //dispatch({type: "sent_verify_email", payload: value});
              }
            })
            .catch(function (err) {
              //console.log("Send email error");
              //console.log(err);
            });
        }
      }

      return value; //disable after email is reenabled
      // value.userInfo.verifiedemailsent = true;
      // value.userInfo.verified = false;
      // //console.log("aBout to call update user info ");
      // await updateUserInfo(value.currentUser.uid, value, null);
    };

  var sendUserContactEmail = async function sendUserContactEmail(formVals) {
    // var RequestParams = {
    //   user_name: userName !== null ? userName : "",
    //   user_email: userEmail !== null? userEmail : "",
    //   message: "Please verify email address by clicking the link below"
    // }
    var fianlRes = await sendEmailForm(
      emailServiceId,
      emailContactTemplate,
      formVals,
      emailUserId
    )
      .then(function (res) {
        if (res) {
          return true;
        }
      })
      .catch(function (err) {
        //console.log("Send email error");
        //console.log(err);
        return false;
      });
    return fianlRes;
  };

  var sendNewPackageEmail = async function sendNewPackageEmail(formVals) {
    // console.log("Wtf is in formVals");
    // console.log(formVals);
    var RequestParams = {
      user_name: formVals.user_name,
      user_email: formVals.user_email,
      message:
        "your package with tracking number " +
        formVals.trackingNum +
        " from " +
        formVals.merchant +
        "  was updated to the status: " +
        formVals.status +
        ".",
    }; // var data2 = {event: 'staff add package',
    //                       value:{"What is in this package b4 email sent for user: " : "What is in this package b4 email sent for user", RequestParams: RequestParams}
    // };
    // var entry2 = log.entry(METADATA, data2);
    // log.write(entry2);
    // console.log("What is in this package b4 emails sent");
    // console.log(RequestParams);

    var fianlRes = await sendEmail(
      emailServiceId,
      emailNewPackageTemplate,
      RequestParams,
      emailUserId
    )
      .then(function (res) {
        if (res) {
          return true;
        }
      })
      .catch(function (err) {
        // var data3 = {event: 'staff add package',
        //                     value:{"Send email error for user: " : formVals.user_email, error: err}
        // };
        // var entry3 = log.entry(METADATA, data3);
        // log.write(entry3);
        // console.log("Send email error");
        // console.log(err);
        return false;
      });
    return fianlRes;
  };

  var sendScheduleDeliveryEmail = async function sendScheduleDeliveryEmail(
    formVals
  ) {
    //console.log("Wtf is in formVals");
    //console.log(formVals);
    var RequestParams = {
      user_email: formVals.user_email,
      from_name: formVals.from_name,
      d_time: formVals.d_time,
      date: formVals.d_date,
      d_area: formVals.d_area,
      d_add: formVals.d_add,
      d_instruct: formVals.d_instruct,
      p_num: formVals.p_num,
      t_nums: formVals.t_nums,
      cost: formVals.cost,
    }; // var data2 = {event: 'staff add package',
    //                       value:{"What is in this package b4 email sent for user: " : "What is in this package b4 email sent for user", RequestParams: RequestParams}
    // };
    // var entry2 = log.entry(METADATA, data2);
    // log.write(entry2);

    //console.log("What is in this package b4 emails sent");
    //console.log(RequestParams);
    var fianlRes = await sendEmail(
      emailServiceId,
      emailNewScheduleDeliveryTemplate,
      RequestParams,
      emailUserId
    )
      .then(function (res) {
        if (res) {
          return true;
        }
      })
      .catch(function (err) {
        // var data3 = {event: 'staff add package',
        //                     value:{"Send email error for user: " : formVals.user_email, error: err}
        // };
        // var entry3 = log.entry(METADATA, data3);
        // log.write(entry3);
        //console.log("Send email error");
        //console.log(err); // console.log(err);

        return false;
      });
    return fianlRes;
  };

  var sendPreAlertEmail = async function sendPreAlertEmail(formVals, filetype) {
    //console.log("Wtf is in formVals");
    //console.log(formVals);
    var RequestParams = {
      from_name: formVals.user_name,
      user_email: formVals.user_email,
      message:
        "A new pre alert was added by " +
        formVals.user_name +
        " with status of " +
        formVals.status +
        " and tracking number " +
        formVals.tracking_number +
        ".",
      content_pdf: undefined,
      content_svg: undefined,
      content_jpeg: undefined,
      content_png: undefined,
      tracking_number: formVals.tracking_number,
    };

    if (filetype.toLowerCase() === "application/pdf") {
      RequestParams.content_pdf = formVals.content;
    } else if (filetype.toLowerCase() === "image/png") {
      RequestParams.content_png = formVals.content;
    } else if (filetype.toLowerCase() === "image/svg+xml") {
      RequestParams.content_svg = formVals.content;
    } else if (filetype.toLowerCase() === "image/jpeg") {
      RequestParams.content_jpeg = formVals.content;
    } //console.log("What is in this package b4 emails sent");
    //console.log(RequestParams);

    var fianlRes = await sendEmail(
      emailServiceId,
      emailNewPreAlertTemplate,
      RequestParams,
      emailUserId
    )
      .then(function (res) {
        if (res) {
          return true;
        }
      })
      .catch(function (err) {
        //console.log("Send email error");
        //console.log(err);
        return false;
      });
    return fianlRes;
  };

  var uploadInvoiceEmail = async function uploadInvoiceEmail(
    formVals,
    filetype,
    mailbox_Num
  ) {
    //console.log("Wtf is in formVals");
    //console.log(formVals);
    var RequestParams = {
      from_name: formVals.user_name,
      user_email: formVals.user_email,
      message:
        "A new Invoice was uploaded by " +
        formVals.user_name +
        " for mailbox: MGK-" +
        mailbox_Num +
        " with tracking number " +
        formVals.tracking_number +
        " .",
      content_pdf: undefined,
      content_svg: undefined,
      content_jpeg: undefined,
      content_png: undefined,
      tracking_number: formVals.tracking_number,
    };

    if (filetype.toLowerCase() === "application/pdf") {
      RequestParams.content_pdf = formVals.content;
    } else if (filetype.toLowerCase() === "image/png") {
      RequestParams.content_png = formVals.content;
    } else if (filetype.toLowerCase() === "image/svg+xml") {
      RequestParams.content_svg = formVals.content;
    } else if (filetype.toLowerCase() === "image/jpeg") {
      RequestParams.content_jpeg = formVals.content;
    } //console.log("What is in this package b4 emails sent");
    //console.log(RequestParams);

    var fianlRes = await sendEmail(
      emailServiceId,
      emailNewInvoiceUploadTemplate,
      RequestParams,
      emailUserId
    )
      .then(function (res) {
        if (res) {
          return true;
        }
      })
      .catch(function (err) {
        //console.log("Send email error");
        //console.log(err);
        return false;
      });
    return fianlRes;
  };

  var sendInvoiceEmail = async function sendInvoiceEmail(formVals) {
    //console.log("Wtf is in formVals");
    //console.log(formVals);
    var RequestParams = {
      user_name: formVals.user_name,
      user_email: formVals.user_email,
      Mailbox_Num: formVals.Mailbox_Num,
      inv_rows: formVals.inv_rows,
      inv_total: formVals.inv_total,
      inv_date: formVals.inv_date,
      invoiceNum: formVals.invoiceNum,
    };

    // console.log("What is in this package b4 emails sent");
    // console.log(RequestParams);

    if (formVals.invoicePaid) {
      var fianlRes = await sendEmail(
        emailServiceId,
        emailPaidInvoiceDeleiveredTemplate,
        RequestParams,
        emailUserId
      )
        .then(function (res) {
          if (res) {
            return true;
          }
        })
        .catch(function (err) {
          console.log("Send email error");
          console.log(err);
          return false;
        });
      return fianlRes;
    } else {
      var fianlRes = await sendEmail(
        emailServiceId,
        emailInvoiceDeleiveredTemplate,
        RequestParams,
        emailUserId
      )
        .then(function (res) {
          if (res) {
            return true;
          }
        })
        .catch(function (err) {
          console.log("Send email error");
          console.log(err);
          return false;
        });
      return fianlRes;
    }
  };

  var fetchUserRefferalPoints = async function fetchUserRefferalPoints(
    uid,
    payload
  ) {
    try {
      const refferalPointsRef = Doc(db, "Users", uid);
      const docSnap = await GetDoc(refferalPointsRef);

      var reffArr = {};
      if (docSnap.exists()) {
        var res = docSnap.data();
        reffArr = res;
        if (reffArr.hasOwnProperty("rPoints") && reffArr.rPoints !== "") {
          payload.reffPoints = reffArr.rPoints;
        } else {
          payload.reffPoints = "0";
        }

        //console.log("dispatching");
        dispatch({
          type: "fetch_reffpoints_info",
          payload: payload,
        });
      }
    } catch (err) {
      //console.log("error while fetching reff points");
      //console.log(err);
    }
  };

  var fetchUserPointsHistory = async function fetchUserPointsHistory(
    uid,
    payload
  ) {
    try {
      const q = Query(
        Collection(db, "PointsTracking"),
        Where("Uid", "==", uid),
        Where("Action", "==", "Use Points")
      );

      const querySnapshot = await GetDocs(q);
      const pointsArr = [];
      querySnapshot.forEach((doc) => {
        const res = doc.data();
        pointsArr.push({ ...res });
        //console.log("Single package id is:" + doc.id);
      });

      //console.log(pointsArr);
      payload.pointsHistory = pointsArr;

      //console.log("dispatching");
      dispatch({
        type: "fetch_points_history",
        payload: payload,
      });
    } catch (err) {
      //console.log("error while fetching reff points");
      //console.log(err);
    }
  };

  var updateUserRefferalPoints = async function updateUserRefferalPoints(
    uid,
    usepoints,
    currentPoints,
    payload,
    currentPackage,
    Index,
    onePointIsValued
  ) {
    //TODOs:
    //Reduce package price by points being used
    //Add points tracking to indicate the mount used
    try {
      let pointsToMoney = parseInt(usepoints) * onePointIsValued;
      let currentCost = currentPackage.PackageInfo.FinalCost;
      let newCost = 0.0;
      if (
        isNaN(currentCost) &&
        currentCost !== undefined &&
        currentCost !== null
      ) {
        newCost = parseFloat(currentCost) - pointsToMoney;
      } else {
        newCost = currentCost - pointsToMoney;
      }
      currentPackage.PackageInfo.FinalCost = newCost;
      payload.rangeOfPackages[Index] = currentPackage;

      let calculatePoints = parseInt(currentPoints) - parseInt(usepoints);
      calculatePoints = calculatePoints < 0 ? 0 : calculatePoints;

      //Fetch reff points using uid
      const refferalPointsRef = Doc(db, "Users", uid);
      const refferalPointsSnap = await GetDoc(refferalPointsRef);
      var reffArr = {};
      let reffId = "";
      if (refferalPointsSnap.exists()) {
        var res = refferalPointsSnap.data();
        reffArr = res;
        reffId = refferalPointsSnap.id;
      } else {
        //console.log("No such document!");
        return false;
      }

      let updatedReffPoints = {
        ...reffArr,
        rPoints: calculatePoints.toString(),
      };
      let packageTnum = currentPackage.PackageInfo.TrackingNumber;

      //get packageID
      //Fetch package using Tracking number
      const q = Query(
        Collection(db, "PackagesNew"),
        Where("PackageInfo.TrackingNumber", "==", packageTnum)
      );

      const querySnapshot = await GetDocs(q);
      var packageId = undefined;
      querySnapshot.forEach((doc) => {
        packageId = doc.id;
        //currentPInfo = doc.data();
        //console.log("Single package id is:" + doc.id);
      });

      if (packageId === undefined) {
        return false;
      }

      const updatePackageRef = Doc(db, "PackagesNew", packageId);
      //Update Package Final Cost
      await UpdateDoc(updatePackageRef, currentPackage);

      //Update Points Tracking
      let RefferalPointsObj = {
        Action: "Use Points",
        DateCreated: new Date().toString(),
        NumberOfPoints: usepoints,
        TrackingNumber: packageTnum,
        Uid: currentPackage.Uid,
      };

      await AddDoc(Collection(db, "PointsTracking"), RefferalPointsObj);
      //Update refferal points
      const updatePointsRef = Doc(db, "Users", reffId);

      // Set the "capital" field of the city 'DC'
      await UpdateDoc(updatePointsRef, updatedReffPoints);
      payload.reffPoints = calculatePoints.toString();
      dispatch({
        type: "update_reffpoints_info",
        payload: payload,
      });
      return true;
    } catch (err) {
      //console.log("error while fetching reff points");
      console.log(err);
      return false;
    }
  };

  var calcUserRefferalPoints = async function calcUserRefferalPoints(uid) {
    //TODOs:

    try {
      //Fetch PointsTracking
      const q = Query(
        Collection(db, "PointsTracking"),
        Where("Uid", "==", uid),
        //Where("TrackingNumber", "==", "SPXSNM056704320514")
        Where("Action", "==", "Deleted")
      );

      const querySnapshot = await GetDocs(q);
      var TotalP = 0;
      const pArr = [];
      querySnapshot.forEach((doc) => {
        let pItem = doc.data();
        pArr.push(pItem);
        //currentPInfo = doc.data();
        console.log(doc.data());
      });
      console.log(pArr);
      if (pArr.length > 0) {
        pArr.map((item) => {
          if (item.Action === "Add Point" || item.Action === "Ref Point") {
            TotalP += parseInt(item.NumberOfPoints);
          } else {
            TotalP -= parseInt(item.NumberOfPoints);
          }

          return null;
        });
      }
      //Print points calc
      console.log(TotalP);
    } catch (err) {
      console.log(err);
    }
  };

  var updateStaffUser = async function updateStaffUser(
    currentState,
    payloadf,
    index,
    isStaff
  ) {
    // console.log("update User id is: ");
    // console.log(currentState);
    // console.log(currentState.uid);
    //console.log(payloadf);
    //console.log(currentState);
    //console.log("updating  user");
    var user = null;
    //console.log(payloadf)
    if (currentState !== null && isStaff) {
      user = {
        contactNumber:
          currentState.contactNumber !== null &&
          currentState.contactNumber !== undefined
            ? currentState.contactNumber?.trim()
            : payloadf.userInfo.contactNumber,
        email:
          currentState.email !== null && currentState.email !== undefined
            ? currentState.email?.trim()
            : payloadf.userInfo.email,
        fullName:
          currentState.fullName !== null && currentState.fullName !== undefined
            ? currentState.fullName.toLowerCase()?.trim()
            : payloadf.userInfo.fullName,
        enabled:
          currentState.enabled !== null && currentState.enabled !== undefined
            ? currentState.enabled
            : payloadf.enabled,
        verified: payloadf.userInfo.verified,
        verifiedemailsent: payloadf.userInfo.verifiedemailsent,
        addressLine1:
          currentState.addressLine1 !== null &&
          currentState.addressLine1 !== undefined
            ? currentState.addressLine1?.trim()
            : payloadf.userInfo.addressLine1,
        addressLine2:
          currentState.addressLine2 !== null &&
          currentState.addressLine2 !== undefined
            ? currentState.addressLine2?.trim()
            : payloadf.userInfo.addressLine2,
        city:
          currentState.city !== null && currentState.city !== undefined
            ? currentState.city?.trim()
            : payloadf.userInfo.city,
        postalCode:
          currentState.postalCode !== null &&
          currentState.postalCode !== undefined
            ? currentState.postalCode?.trim()
            : payloadf.userInfo.postalCode,
        stateOrparish:
          currentState.stateOrparish !== null &&
          currentState.stateOrparish !== undefined
            ? currentState.stateOrparish?.trim()
            : payloadf.userInfo.stateOrparish,
        dateCreated:
          currentState.dateCreated !== null &&
          currentState.dateCreated !== undefined
            ? currentState.dateCreated
            : payloadf.userInfo.dateCreated,
        rPoints:
          payloadf.userInfo.rPoints !== null &&
          payloadf.userInfo.rPoints !== undefined &&
          payloadf.userInfo.rPoints !== ""
            ? payloadf.userInfo.rPoints
            : "0",
        mailbox_Num:
          payloadf.mailbox_Num !== null && payloadf.mailbox_Num !== undefined
            ? ""
            : payloadf.mailbox_Num,
        Position:
          currentState.Position !== null && currentState.Position !== undefined
            ? currentState.Position?.trim()
            : "",
      };
    } else {
      user = {
        contactNumber:
          currentState.contact !== null && currentState.contact !== undefined
            ? currentState.contact
            : "",
        email:
          currentState.email !== null && currentState.email !== undefined
            ? currentState.email
            : "",
        fullName:
          currentState.fullName !== null && currentState.fullName !== undefined
            ? currentState.fullName.toLowerCase()
            : "",
        verified: currentState.verified,
        verifiedemailsent: currentState.verifiedemailsent,
        addressLine1:
          currentState.addressLine1 !== null &&
          currentState.addressLine1 !== undefined
            ? currentState.addressLine1
            : "",
        addressLine2:
          currentState.addressLine2 !== null &&
          currentState.addressLine2 !== undefined
            ? currentState.addressLine2
            : "",
        city:
          currentState.city !== null && currentState.city !== undefined
            ? currentState.city
            : "",
        postalCode:
          currentState.postalCode !== null &&
          currentState.postalCode !== undefined
            ? currentState.postalCode
            : "",
        stateOrparish:
          currentState.stateOrparish !== null &&
          currentState.stateOrparish !== undefined
            ? currentState.stateOrparish
            : "",
        dateCreated:
          currentState.dateCreated !== null &&
          currentState.dateCreated !== undefined
            ? currentState.dateCreated
            : payloadf.userInfo.dateCreated,
        rPoints:
          currentState.rPoints !== null &&
          currentState.rPoints !== undefined &&
          currentState.rPoints !== ""
            ? currentState.rPoints
            : "0",
        mailbox_Num:
          currentState.mailbox_Num !== null &&
          currentState.mailbox_Num !== undefined
            ? currentState.mailbox_Num
            : "",
      };
    }

    try {
      // console.log(user);
      var userID = "";
      /////////////////////////

      if (!isStaff) {
        const q = Query(
          Collection(db, "Users"),
          Where("mailbox_Num", "==", user.mailbox_Num)
        );

        const querySnapshot = await GetDocs(q);
        const uArr = [];
        querySnapshot.forEach((doc) => {
          let uID = doc.id;
          uArr.push(uID);
          // console.log(doc.id);
        });

        if (uArr.length > 0) {
          // console.log("The user found is:");
          // console.log(uArr[0]);
          userID = uArr[0];
        }

        // console.log(userID);
      }
      /////////////////
      const userUpdateRef = Doc(db, "Users", userID);
      await UpdateDoc(userUpdateRef, user);
      // console.log("user successfully updated!");
      if (isStaff) {
        payloadf.staffUsers[index].contactNumber = user.contactNumber;
        payloadf.staffUsers[index].email = user.email;
        payloadf.staffUsers[index].fullName = user.fullName;
        payloadf.staffUsers[index].addressLine1 = user.addressLine1;
        payloadf.staffUsers[index].addressLine2 = user.addressLine2;
        payloadf.staffUsers[index].city = user.city;
        payloadf.staffUsers[index].enabled = user.enabled;
        payloadf.staffUsers[index].postalCode = user.postalCode;
        payloadf.staffUsers[index].stateOrparish = user.stateOrparish;
        payloadf.staffUsers[index].rPoints =
          user.rPoints !== null &&
          user.rPoints !== undefined &&
          user.rPoints !== ""
            ? user.rPoints
            : "0";
        payloadf.staffUsers[index].Position =
          user.Position !== null && user.Position !== undefined
            ? user.Position
            : "Staff";

        dispatch({
          type: "fetch_users_staff",
          payload: payloadf,
        });

        return true;
      } else {
        // console.log(payloadf.users[index]);
        const tempPayload = payloadf;
        tempPayload.users[index].contactNumber = user.contactNumber;
        tempPayload.users[index].email = user.email;
        tempPayload.users[index].fullName = user.fullName;
        tempPayload.users[index].addressLine1 = user.addressLine1;
        tempPayload.users[index].addressLine2 = user.addressLine2;
        tempPayload.users[index].city = user.city;
        tempPayload.users[index].postalCode = user.postalCode;
        tempPayload.users[index].stateOrparish = user.stateOrparish;
        tempPayload.users[index].rPoints =
          user.rPoints !== null &&
          user.rPoints !== undefined &&
          user.rPoints !== ""
            ? user.rPoints
            : "0";

        dispatch({
          type: "fetch_users",
          payload: tempPayload,
        });

        return true;
      }
    } catch (error) {
      console.error("Error updating user info: ", error);
      return false;
    }
  };

  var addStaffUser = async function addStaffUser(currentState, payloadf) {
    console.log("add User id is: ");
    console.log(currentState.uid);
    //console.log(payloadf);
    //console.log(currentState);
    //console.log("updating  user");
    var user = null;
    //console.log(payloadf)
    if (currentState !== null) {
      user = {
        contactNumber:
          currentState.contactNumber !== null &&
          currentState.contactNumber?.trim() !== undefined
            ? currentState.contactNumber
            : "",
        email:
          currentState.email !== null && currentState.email !== undefined
            ? currentState.email?.trim()
            : "",
        fullName:
          currentState.fullName !== null && currentState.fullName !== undefined
            ? currentState.fullName.toLowerCase()?.trim()
            : "",
        verified: true,
        verifiedemailsent: true,
        enabled: true,
        addressLine1:
          currentState.addressLine1 !== null &&
          currentState.addressLine1 !== undefined
            ? currentState.addressLine1?.trim()
            : "",
        addressLine2:
          currentState.addressLine2 !== null &&
          currentState.addressLine2 !== undefined
            ? currentState.addressLine2?.trim()
            : "",
        city:
          currentState.city !== null && currentState.city !== undefined
            ? currentState.city?.trim()
            : "",
        postalCode:
          currentState.postalCode !== null &&
          currentState.postalCode !== undefined
            ? currentState.postalCode?.trim()
            : "",
        stateOrparish:
          currentState.stateOrparish !== null &&
          currentState.stateOrparish !== undefined
            ? currentState.stateOrparish?.trim()
            : "",
        dateCreated: timeStamp.fromDate(new Date()),
        rPoints:
          payloadf.userInfo.rPoints !== null &&
          payloadf.userInfo.rPoints !== undefined &&
          payloadf.userInfo.rPoints !== ""
            ? "0"
            : "0",
        mailbox_Num:
          payloadf.mailbox_Num !== null && payloadf.mailbox_Num !== undefined
            ? ""
            : "",
        Position:
          currentState.Position !== null && currentState.Position !== undefined
            ? currentState.Position?.trim()
            : "Staff",
      };
    }

    try {
      const signedUp = await staffSignup(currentState);
      await SetDoc(Doc(db, "Users", signedUp.uid), user);
      console.log("user successfully created!");
      payloadf.staffUsers.push(user);
      await userHasRole(signedUp.uid, payloadf, user.Position);
      dispatch({
        type: "fetch_userinfo",
        payload: payloadf,
      });
      return true;
    } catch (error) {
      console.error("Error creating staff user: ", error);
      return false;
    }
  };

  var [value, dispatch] = useReducer(authReducer, {
    currentUser,
    loggedIn,
    loading,
    airFreightAdd,
    seaFreightAdd,
    packages,
    points,
    reffPoints,
    readyPack,
    inTransPack,
    balance,
    userInfo,
    userRolef,
    mailbox_Num,
    singlePackage,
    singlePackageId,
    rangeOfPackages,
    packagesForDelivery,
    users,
    staffUsers,
    singleUser,
    consistentusers,
    FetchPackUnsubscribe,
    pointsHistory,
    invoiceDetails,
    Rates,
    RatesContd,
    AddLB,
    findUserForDashboard,
    fetchUserInfo,
    fetchUserInfoForSignUp,
    signup,
    login,
    logout,
    resetPassword,
    gLogin,
    fLogin,
    tLogin,
    fetchAddress,
    fetchPackages,
    mailBoxExist,
    storeMailBoxNumber,
    updateUserInfo,
    updateRates,
    updateRatesContd,
    getRates,
    getAdditionalLB,
    getRatesContd,
    sendCostomerVerificationEmail,
    sendUserContactEmail,
    addPackageStaff,
    createPreAlert,
    checkIfPackageExistStaff,
    editPackageStaff,
    clearPackageResultStaff,
    scheduleDelivery,
    fetchPackagesForDelivery,
    clearFetchPackagesForDilivery,
    fetchUsers,
    fetchStaffUsers,
    findUser,
    deletePackageStaff,
    findPackagesByDateRange,
    uploadInvoice,
    addInvoice,
    sendInvoice,
    generateInvoiceNumber,
    fetchUserRole,
    fetchUserRefferalPoints,
    updateUserRefferalPoints,
    calcUserRefferalPoints,
    fetchUserPointsHistory,
    addUserInfo,
    fetchShippersInLastSixMonths,
    addMonths,
    updateStaffUser,
    addStaffUser,
  });

  return (
    <AuthContext.Provider value={{ value }}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
