import { useState } from "react";
import SocialShareButtons from "../components/SocialShareButtons";
import { Alert } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";

export default function RefferalModal() {
  var { value } = useAuth();
  var [success, setSuccess] = useState("");
  var reflink = "";

  if (value.currentUser !== null && value.currentUser !== undefined) {
    reflink = `https://mgkexpress.com/ReferralSignUp/${value.currentUser.uid}`;
  }

  const Message = function () {
    return (
      <div className="sea-freight-info">
        <h6>
          Please note carefully how referral points are earned and when they can
          be used below:
        </h6>
        &bull; 1 point is earned each time the person that signs up with your
        referral link ships a package with MGK Express.
        <br />
        <br />
        &bull; Each point is worth $50.
        <br />
        <br />
        &bull; What are you waiting for? start earning points today!!!
        <br />
        <br />
      </div>
    );
  };
  return (
    <div
      className="modal fade"
      id="ViewReferralDetails"
      tabIndex="-1"
      aria-labelledby="SignUpFormLabel"
      aria-hidden="true"
    >
      {/* Heading */}
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {/* <h5 className="modal-title text-center" style={{textAlign: 'center !important', width: '100%'}}>
                            <span className="sub-title"></span>
                            <span>
                            <h2 className="title mt-2" style={{marginLeft: 'auto', marginRight: 'auto', color: "#3264f5"}} >User Details</h2>
                            </span>
                            
                        </h5> */}
            <div className="card" style={{ width: "100%", marginBottom: 0 }}>
              <div className="card-header">
                <h3
                  className="card-title"
                  style={{
                    color: "#007bff",
                    fontWeight: 700,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Referral Link
                </h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{
                      color: "rgb(200, 61, 115)",
                      fontSize: "2.5rem",
                      padding: "0px 20px",
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              {/* <!-- /.card-header --> */}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12" style={{ textAlign: "center" }}>
                    <div style={{ textAlign: "center" }}>
                      {success && <Alert variant="success">{success}</Alert>}
                      <input
                        id="reflink"
                        style={{ color: "rgb(94 34 206)" }}
                        type="text"
                        value={`https://mgkexpress.com/ReferralSignUp/${value.currentUser.uid}`}
                        readOnly
                      />
                      <h5 style={{ marginBottom: "15px" }}>
                        <br />
                        <button
                          style={{
                            backgroundColor: "#007bff",
                            borderColor: "#007bff",
                            color: "white",
                          }}
                          className="btn btn-large btn-rounded btn-hvr-pink modal_contact_btn"
                          id="signup_submit_btn"
                          type="submit"
                          onClick={() => {
                            navigator.clipboard.writeText(reflink);
                            setSuccess("Copied");
                            setTimeout(() => {
                              setSuccess("");
                            }, 4000);
                          }}
                        >
                          Copy Link
                          <div className="btn-hvr-setting">
                            <ul className="btn-hvr-setting-inner">
                              <li className="btn-hvr-effect"></li>
                              <li className="btn-hvr-effect"></li>
                              <li className="btn-hvr-effect"></li>
                              <li className="btn-hvr-effect"></li>
                            </ul>
                          </div>
                        </button>
                      </h5>
                    </div>
                    <p style={{ textAlign: "center" }}>OR</p>
                    <h5 style={{ textAlign: "center" }}>
                      Share On Social Media
                    </h5>
                    <br />
                    <SocialShareButtons
                      reflink={`https://mgkexpress.com/ReferralSignUp/${value.currentUser.uid}`}
                    />
                  </div>
                  {/* <!-- /.col --> */}
                </div>
                {/* <!-- /.row --> */}
              </div>
              {/* <!-- /.card-body --> */}
              <div
                className="card-footer bg-white p-0"
                style={{ textAlign: "center" }}
              >
                <Tippy content={<Message />}>
                  <div className="circle pulse">
                    <i className="fas fa-info-circle info-icon"></i>
                  </div>
                </Tippy>
              </div>
              {/* <!-- /.footer --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
