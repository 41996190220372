var Feild = function Feild({
  name,
  placeholder,
  onChange,
  value,
  type,
  required,
}) {
  if (required === "required") {
    if (type === "file") {
      return (
        <>
          <input
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="inputfile"
          />
          <label htmlFor={name}>
            <i
              className="fas fa-cloud-upload-alt"
              style={{ marginRight: "6%" }}
            ></i>
            Upload Invoice
          </label>
          <p style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            Only Pdf, Png, Jpg/Jpeg and Svg files are allowed.
          </p>
        </>
      );
    } else {
      return (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className="form-control"
          required
        />
      );
    }
  } else {
    if (type === "file") {
      return (
        <>
          <input
            type={type}
            id={name}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="inputfile"
          />
          <label htmlFor={name}>
            <i
              className="fas fa-cloud-upload-alt"
              style={{ marginRight: "6%" }}
            ></i>
            Upload Invoice
          </label>
          <p style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            Only Pdf, Png, Jpg/Jpeg and Svg files are allowed.
          </p>
        </>
      );
    } else if (type === "file2") {
      return (
        <>
          <input
            type={"file"}
            id={name}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="inputfile"
          />
          <label htmlFor={name}>
            <i
              className="fas fa-cloud-upload-alt"
              style={{ marginRight: "6%" }}
            ></i>
            Select File
          </label>
          <p style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            Only Excel file allowed.
          </p>
        </>
      );
    } else {
      return (
        <>
          <input
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="form-control"
          />
        </>
      );
    }
  }
};

export default Feild;
