import { useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import PackageStatus from "../components/PackageStatus";
import { Link } from "react-router-dom";
import Moment from "moment";

export default function DashboardPackagesTable() {
  const { value } = useAuth();
  const { packages, currentUser, fetchPackages } = value;
  const showItems = 5;

  // console.log("On Dashboard Packages");
  useEffect(function () {
    if (currentUser != null) {
      try {
        fetchPackages(currentUser.uid, value);
      } catch {
        //console.log("unable to fetch address");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkElement(element) {
    if (
      element.Status !== "Ready" &&
      element.Status !== "Delivered" &&
      element.Status !== "Invoice Uploaded"
    ) {
      return (
        <tr key={element.TrackingNumber}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <td>
            <Link
              to={`/UploadInvoice/${element.TrackingNumber}`}
              className="dashboard-invoice"
              data-toggle="tooltip"
              data-placement="top"
              title="Click Here To Upload Invoice"
            >
              {element.TrackingNumber}
            </Link>
          </td>
          <td>
            <Link
              to={`/UploadInvoice/${element.TrackingNumber}`}
              className="dashboard-invoice"
              data-toggle="tooltip"
              data-placement="top"
              title="Click Here To Upload Invoice"
            >
              <span
                data-toggle="modal"
                data-target="#ViewUserDetailsModal"
                id={element.TrackingNumber}
                name={element.TrackingNumber}
              >
                <i
                  id={element.TrackingNumber}
                  name={element.TrackingNumber}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Upload Invoice"
                  style={{
                    color: "#3264f5",
                    cursor: "pointer",
                    marginRight: "3%",
                    fontSize: "25px",
                  }}
                  className="fas fa-cloud-upload-alt"
                ></i>
              </span>
            </Link>
          </td>
          <td>{element.ItemName}</td>
          <td>
            <PackageStatus status={element.Status} />
          </td>
          <td>{Moment(element.OrderDate.toDate()).format("YYYY-MM-DD")}</td>
        </tr>
      );
    } else if (element.Status === "Invoice Uploaded") {
      return (
        <tr key={element.TrackingNumber}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <td>{element.TrackingNumber}</td>
          <td>
            <Link
              to={`/UploadInvoice/${element.TrackingNumber}`}
              className="dashboard-invoice"
              data-toggle="tooltip"
              data-placement="top"
              title="Invoice(s) Uploaded"
            >
              <span
                data-toggle="modal"
                data-target="#ViewUserDetailsModal"
                id={element.TrackingNumber}
                name={element.TrackingNumber}
              >
                <i
                  id={element.TrackingNumber}
                  name={element.TrackingNumber}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Upload Invoice"
                  style={{
                    color: "#3264f5",
                    cursor: "pointer",
                    marginRight: "3%",
                    fontSize: "25px",
                  }}
                  className="fa fa-paperclip"
                ></i>
              </span>
            </Link>
          </td>
          <td>{element.ItemName}</td>
          <td>
            <PackageStatus status={element.Status} />
          </td>
          <td>{Moment(element.OrderDate.toDate()).format("YYYY-MM-DD")}</td>
        </tr>
      );
    } else {
      return (
        <tr key={element.TrackingNumber}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <td>{element.TrackingNumber}</td>
          <td>N/A</td>
          <td>{element.ItemName}</td>
          <td>
            <PackageStatus status={element.Status} />
          </td>
          <td>{Moment(element.OrderDate.toDate()).format("YYYY-MM-DD")}</td>
        </tr>
      );
    }
  }

  var renderPackages = function renderPackages() {
    // console.log("Checking if no packages were found.");
    if (packages.length === 0) {
      // console.log("returning 0 packages");
      return (
        <tr>
          <td>
            <p style={{ paddingLeft: "5%" }}>
              There are no packages to display at this time.
            </p>
          </td>
        </tr>
      );
    }

    // let filteredPacks = packages.filter((item) => item.Status === "Ready");
    // packages.map((item) => {
    //   if (item.Status !== "Ready") {
    //     filteredPacks.push(item);
    //   }
    //   return;
    // });
    //console.log("packages were found.");
    packages.sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return Moment(b.OrderDate.toDate()) - Moment(a.OrderDate.toDate()); //.format("YYYY-MM-DD")
    });
    return packages.slice(0, showItems).map((element) => checkElement(element));
  };

  if (packages !== null && packages !== undefined) {
    return (
      <table className="table m-0">
        <thead>
          <tr>
            <th>Tracking Number</th>
            <th>Upload Invoice</th>
            <th>Item</th>
            <th>Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>{renderPackages()}</tbody>
      </table>
    );
  } else {
    return (
      <>
        <div className="spinnerContainer">
          <div className="spinner-border text-primary spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <style>
          {`
            .spinnerContainer {
                color: black;
                text-align: center; 
                position: absolute;
                width: 100% !important;
                height: 100%;
                z-index: 100;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,0.1);
            }

            .spinner {
                margin-top: 10vh;
            }
          `}
        </style>
      </>
    );
  }
}
