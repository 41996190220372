import { useRef } from "react";
import { useAuth } from "../Context/AuthContext";
export default function FindUserResult() {
  var Address = useRef("");
  var { value } = useAuth();
  var { users } = value;

  var renderUser = function renderUser() {
    if (users !== undefined) {
      console.log("What is in users");
      console.log(users);
      if (users.length === 0) {
        return (
          <p style={{ paddingLeft: "5%" }}>
            There are no results to display at this time.
          </p>
        );
      }

      if (users !== null && users !== undefined && users.length > 0) {
        var numRows = users.length;
        for (var i = 0; i < numRows; ) {
          Address =
            users[i].addressLine1 !== null &&
            users[i].addressLine1 !== undefined
              ? users[i].addressLine1
              : "";
          Address =
            users[i].addressLine2 !== null &&
            users[i].addressLine2 !== undefined
              ? Address + "," + users[i].addressLine2
              : Address;
          Address =
            users[i].city !== null && users[i].city !== undefined
              ? Address + "," + users[i].city
              : Address;
          Address =
            users[i].stateOrparish !== null &&
            users[i].stateOrparish !== undefined
              ? Address + "," + users[i].stateOrparish
              : Address;
          Address =
            users[i].postalCode !== null && users[i].postalCode !== undefined
              ? Address + "," + users[i].postalCode
              : Address;

          i = i + 1;
        }
      }
    }

    return users.map((element) => (
      <tr key={element.MailBoxNumber}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <td>{element.MailBoxNumber}</td>
        <td>{element.fullName}</td>
        <td>{element.email}</td>
        <td>{Address}</td>
        <td>{element.contactNumber}</td>
      </tr>
    ));
  };

  if (users != null) {
    return (
      <table className="table m-0">
        <thead>
          <tr>
            <th>Mailbox#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Address</th>
            <th>Contact#</th>
          </tr>
        </thead>
        <tbody>{renderUser()}</tbody>
      </table>
    );
  } else {
    return (
      <div
        style={{
          color: "#007bff",
          textAlign: "center",
          fontWeight: "bold",
          paddingTop: "5%",
        }}
      >
        <p>Search Result Will Display Here</p>
      </div>
    );
  }
}
