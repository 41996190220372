/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { Alert, Card } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";
import { Link, useParams } from "react-router-dom";
import Feild from "../components/Feild";

var initialValues = { file: "", content: "", tracking_number: "" };

export default function UploadInvoiceScreen() {
  //Initialize state
  var { value } = useAuth();
  var { currentUser, uploadInvoice, userInfo, mailbox_Num, packages } = value;
  var [error, setError] = useState("");
  var [success, setSuccess] = useState("");
  var [file_display, setFile_Display] = useState("");
  var [loading, setLoading] = useState(false);
  var ddl1 = useRef([]);
  let { id } = useParams();

  initialValues.tracking_number = id;

  var [state, setState] = useState(initialValues);

  var handleSubmit = async function handleSubmit(event) {
    event.preventDefault();
    let data = "";

    if (state.content !== null && state.content !== undefined) {
      if (state.content.type !== null || state.content.type !== undefined) {
        if (
          state.content.type === "application/pdf" ||
          state.content.type === "image/png" ||
          state.content.type === "image/svg+xml" ||
          state.content.type === "image/jpeg"
        ) {
          await getBase64(state.content, async function (result) {
            data = result;
            //console.log("data is: ");
            //console.log(data);
            //prevents default form refresh
            //console.log("I am inside fuction");
            try {
              setError("");
              setSuccess("");
              setLoading(true);
              var UploadInvoiceBtn = document.getElementById(
                "create_prealert_btn"
              );
              let packIndex = 0;

              //console.log(packages);
              packages.map((pack, index) => {
                if (pack.TrackingNumber === id) packIndex = index;
                return null;
              });

              await uploadInvoice(
                state,
                data,
                userInfo,
                mailbox_Num,
                value,
                packIndex
              )
                .then(function (res) {
                  if (res === true) {
                    setSuccess("Invoice uploaded successfully.");
                    setState(initialValues);
                    setFile_Display("");
                    if (UploadInvoiceBtn.disabled === true) {
                      //console.log("Enabling button");
                      UploadInvoiceBtn.disabled = false;
                    }
                    ddl1.current.clearAll();
                    setState(initialValues);
                    //console.log(dateFeildDesktop.value);
                  } else if (res === false) {
                    setError("Unable to upload invoice at this time");
                    if (UploadInvoiceBtn.disabled === true) {
                      //console.log("Enabling button");
                      UploadInvoiceBtn.disabled = false;
                    }
                  }
                })
                .catch(function (err) {
                  //console.log(err);
                });
            } catch (err) {
              console.log(err);
              setError("Unable to upload invoice at this time.");
              if (UploadInvoiceBtn.disabled === true) {
                //console.log("Enabling button");
                UploadInvoiceBtn.disabled = false;
              }
            }
          });
        } else {
          setError(
            "Please upload a valid invoice. Only Pdf, Png, Jpg/Jpeg and Svg files are allowed."
          );
        }
      } else {
        setError(
          "Please upload a valid invoice. Only Pdf, Png, Jpg/Jpeg and Svg files are allowed."
        );
      }
    } else {
      setError(
        "Please upload a valid invoice. Only Pdf, Png, Jpg/Jpeg and Svg files are allowed."
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (state.content !== "" && state.content.name !== file_display) {
      setFile_Display(state.content.name);
    }
  }, [state.content, file_display]);

  var getBase64 = async function getBase64(file, cb) {
    try {
      if (file !== "") {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          cb(reader.result);
        };
        reader.onerror = function (error) {
          //console.log('Error: ', error);
        };
      } else {
        return "";
      }
    } catch (err) {
      //console.log(err);
      setError("Unable to upload invoice at this time.");
    }
  };

  var onInputChange2 = function onInputChange2(event) {
    //console.log(event.target.value);
    let { value, name } = event.target;

    if (name === "file") {
      //console.log(event.target.files[0])
      setState({
        ...state,
        [name.toLowerCase()]: value,
        content: event.target.files[0],
      });
    } else {
      setState({ ...state, [name.toLowerCase()]: value });
    }
  };

  //console.log(state);
  //var date = new Date().toString();
  //console.log(date)
  return (
    <div
      className="content-wrapper"
      style={{ minHeight: "700px", height: "100vh" }}
    >
      <section className="content">
        <div
          className="container-fluid"
          style={{ marginTop: "8%", marginBottom: "5%" }}
        >
          <div className="card-body p-0">
            <div className="row">
              <Card className="viewPackage-div-style">
                <Card.Body>
                  <h2 className="text-center mb-4" style={{ color: "#7004bc" }}>
                    Invoice Upload
                  </h2>
                  {error && <Alert variant="danger">{error}</Alert>}
                  {success && <Alert variant="success">{success}</Alert>}
                  <form
                    onSubmit={handleSubmit}
                    className="contact-form text-center"
                    id="modal-contact-form-data6"
                  >
                    <div className="" id="content">
                      <Feild
                        name="file"
                        placeholder="Upload File"
                        value={state.file}
                        onChange={onInputChange2}
                        type="file"
                      />
                    </div>
                    <br />
                    {file_display && (
                      <div variant="success">
                        <i
                          className="fas fa-file"
                          style={{ color: "#7004bc", fontSize: "30px" }}
                        ></i>{" "}
                        {file_display}
                      </div>
                    )}
                    <br />
                    <button
                      disabled={loading}
                      className="btn btn-large btn-rounded btn-blue btn-hvr-pink modal_contact_btn"
                      id="create_prealert_btn"
                      type="submit"
                    >
                      SUBMIT
                      <div className="btn-hvr-setting">
                        <ul className="btn-hvr-setting-inner">
                          <li className="btn-hvr-effect"></li>
                          <li className="btn-hvr-effect"></li>
                          <li className="btn-hvr-effect"></li>
                          <li className="btn-hvr-effect"></li>
                        </ul>
                      </div>
                    </button>
                    <div className="w-100 text-center mt-2 form-link">
                      <Link to="/StaffDashboard">Cancel</Link>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
