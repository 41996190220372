/* eslint-disable no-unused-vars */

import { Component, Fragment } from "react";
//import "./App.css";
import "react-dates/initialize";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import MediaQuery from "react-responsive";
import isInclusivelyAfterDay from "./isInclusivelyAfterDay";

class DatePickerDefault2 extends Component {
  state = {
    date:
      this.props.stateDate !== undefined &&
      this.props.stateDate !== null &&
      this.props.stateDate !== ""
        ? this.props.stateDate
        : null,
    dateFormatted: null,
    focused: null,
    placeholder:
      this.props.placeholder !== undefined && this.props.placeholder !== null
        ? this.props.placeholder
        : "Select Date Received",
  };

  isToolate(day) {
    var hour = this.props.getCurrentDate();
    if (hour >= 14) {
      return !isInclusivelyAfterDay(day, moment().add("days", 1));
    } else {
      return !isInclusivelyAfterDay(day, moment());
    }
  }

  hundleDateChange(date) {
    // var addition = moment().add({days:1});
    // console.log(addition);
    //console.log("hundleDateChange", date);
    this.setState(() => ({
      date,
    }));
    if (date != null) {
      this.props.onInputChange5(date.format("Y-MM-D"));
      this.setState(() => ({
        dateFormatted: date.format("Y-MM-D"),
      }));
    }
  }

  componentDidMount(prevProps, prevState, snapshot) {
    //console.log("mount date: ", this.state.date);
    //console.log(this.props.stateDate);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.stateDate !== this.props.stateDate) {
      //console.log("update date: ", this.props.stateDate);
      this.setState({ ...this.state, date: this.props.stateDate });
    }
  }

  render() {
    return (
      <Fragment>
        <Mobile>
          <SingleDatePicker
            date={this.state.date}
            onDateChange={(date) => this.hundleDateChange(date)}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            numberOfMonths={1}
            orientation="horizontal"
            block={true}
            placeholder={this.state.placeholder}
            id="your_unique_id_mobile"
            hideKeyboardShortcutsPanel={true}
            required={true}
            openDirection="down"
            isDayBlocked={() => false}
            isOutsideRange={(day) =>
              !isInclusivelyAfterDay(day, moment().add("days", 30))
            }
          />
        </Mobile>

        <Default>
          <SingleDatePicker
            date={this.state.date}
            onDateChange={(date) => this.hundleDateChange(date)}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            numberOfMonths={2}
            orientation="horizontal"
            block={true}
            placeholder={this.state.placeholder}
            id="your_unique_id_desk"
            hideKeyboardShortcutsPanel={true}
            required={true}
            openDirection="down"
            isDayBlocked={(day) => false}
            isOutsideRange={(day) =>
              isInclusivelyAfterDay(day, moment().add("days", 30))
            }
          />
        </Default>
      </Fragment>
    );
  }
}
export default DatePickerDefault2;

export var Mobile = function Mobile(props) {
  return <MediaQuery {...props} maxWidth={767} />;
};
export var Default = function (props) {
  return <MediaQuery {...props} minWidth={768} />;
};
//export var Retina = function (props){ return <MediaQuery  {...props} minResolution="2dppx" />};
