import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Feild from "../components/Feild";
import { useAuth } from "../Context/AuthContext";
//import { useHistory } from 'react-router-dom';
import Select from "react-dropdown-select";
import { Alert } from "react-bootstrap";

var initialValues = { search_value: "", filter: "" };

export default function FindUserForm() {
  var [error, setError] = useState("");
  var [success, setSuccess] = useState("");
  var [loading, setLoading] = useState(false);
  var ddl1 = useRef([]);
  var { value } = useAuth();
  var { users, findUser } = value;

  var [state, setState] = useState(initialValues);

  var handleSubmit = async function handleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    // console.log(state);
    // console.log("I am inside fuction");
    if (state.search_value === "") {
      return setError("Please enter a valid search value.");
    } else if (state.filter === "") {
      return setError("Please select a search filter.");
    }
    try {
      setError("");
      setSuccess("");
      setLoading(true);
      await findUser(value, state.search_value, state.filter);
    } catch (err) {
      //console.log(err)
      setError("No user was found with " + state.search_value);
    }
    setLoading(false);
  };

  var onInputChange2 = function onInputChange2(event) {
    //console.log("What is happening? ");
    //console.log(event.target.value);
    let { checked, value, name } = event.target;
    //let { email, password} = state;
    if (name === "Email") {
      setState({ ...state, [name.toLowerCase()]: value });
    } else if (name === "Terms") {
      setState({ ...state, [name.toLowerCase()]: checked });
    } else {
      //console.log("Setting");
      setState({ ...state, [name.toLowerCase()]: value.toLowerCase() });
    }
  };

  useEffect(() => {
    // if(state.search_value !== "" && error === ''){
    //     console.log(users)

    // }

    if (state.search_value !== "") {
      // console.log("Inside check");
      // console.log(state)
      // console.log(users);
      if (users !== []) {
        if (users !== undefined)
          if (users.length > 0) {
            document.getElementById("search-user-form").reset();
            setSuccess("User Found");
          } else {
            setError("No user was found with that search value");
          }
      }
    }

    // console.log("outside ddl check");
    // console.log(state.filter);
    // console.log(error);
    if (state.filter !== "" && error === "") {
      // console.log("inside ddl check");
      // console.log(state.filter);
      // console.log(error);
      if (users !== undefined) {
        if (users.length !== 0) {
          ddl1.current.clearAll();
          setState(initialValues);
        }
      }
    } else {
      setSuccess("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, users]);

  var onInputChange3 = function onInputChange3(event) {
    /*console.log(event[0]);*/
    if (event[0] !== undefined) {
      let { value } = event[0];
      setState({ ...state, filter: value });
    }
  };

  var options2 = [
    { value: "Mailbox#", label: "Mailbox#" },
    { value: "Fullname", label: "Fullname" },
    // {value:"Firstname", label:"Firstname"},
    // {value:"Lastname", label:"Lastname"}
  ];

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <form
        onSubmit={handleSubmit}
        className="contact-form text-center"
        id="search-user-form"
      >
        <div className="form-group" id="tracking_number">
          <Feild
            name="search_value"
            placeholder="Enter Search Value"
            value={state.fullname}
            onChange={onInputChange2}
            type="text"
            required="required"
          />
        </div>
        <div className="form-group" id="courier">
          <Select
            clearOnSelect={true}
            ref={ddl1}
            name="filter"
            options={options2}
            onChange={onInputChange3}
            searchable={true}
            className={"form-control"}
            placeholder="Select Filter"
          />
        </div>
        <button
          disabled={loading}
          className="btn btn-large btn-rounded btn-purple btn-hvr-pink modal_contact_btn"
          id="updateprofile_submit_btn"
          type="submit"
        >
          Find User
          <div className="btn-hvr-setting">
            <ul className="btn-hvr-setting-inner">
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
            </ul>
          </div>
        </button>
        <div className="w-100 text-center mt-2 form-link">
          <Link to="/AdminDashboard">Cancel</Link>
        </div>
      </form>
    </>
  );
}
