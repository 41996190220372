/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Alert, Card } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import Feild from "../components/Feild";
import Spinner from "../components/Spinner";
/* TABS  */
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
/* TABS */
import { useHistory } from "react-router-dom";

// Tabs Functions //
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: "#007bff",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  label: {
    color: "#007bff",
  },
}));
// Tabs Functions

var initiatialVals = {
  L1: "",
  L2: "",
  L3: "",
  L4: "",
  L5: "",
  L6: "",
  L7: "",
  L8: "",
  L9: "",
  L10: "",
};

var initiatialVals2 = {
  L1: "",
  L2: "",
  L3: "",
  L4: "",
  L5: "",
  L6: "",
  L7: "",
  L8: "",
  L9: "",
  L10: "",
};

var UpdateRatesScreen = function UpdateRatesScreen() {
  //Initialize rates
  var { value } = useAuth();
  var {
    Rates,
    RatesContd,
    updateRates,
    updateRatesContd,
    getRates,
    getRatesContd,
  } = value;
  var [error, setError] = useState("");
  var [success, setSuccess] = useState("");
  var [loading, setLoading] = useState(false);
  var history = useHistory();

  var [rates, setRates] = useState(initiatialVals);
  const [ratesContd, setRatesContd] = useState(initiatialVals2);

  // Tabs
  const classes = useStyles();
  const [tab, setTab] = React.useState(2);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  // Tabs

  var handleSubmit = async function handleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside fuction");
    // if(rates.password !== rates.passwordconf){
    //     return setError('Passwords do not match');
    // }
    try {
      setError("");
      setSuccess("");
      setLoading(true);
      await updateRates(value, rates).then(function (res) {
        setSuccess("1 - 10 LB updated successfully.");
        setTimeout(() => {
          setSuccess("");
          setError("");
        }, 4000);

        setRates({ ...rates });
      });
    } catch (err) {
      console.log(err);
      setError("Failed to update 1 - 10 LB rates info.");
      setTimeout(() => {
        setSuccess("");
        setError("");
      }, 4000);
    }
    setLoading(false);
  };

  var handleSubmit2 = async function handleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside fuction");
    // if(rates.password !== rates.passwordconf){
    //     return setError('Passwords do not match');
    // }
    try {
      setError("");
      setSuccess("");
      setLoading(true);
      await updateRatesContd(value, ratesContd).then(function (res) {
        setSuccess("11 - 20 LB updated successfully.");
        setTimeout(() => {
          setSuccess("");
          setError("");
        }, 4000);

        setRatesContd({ ...ratesContd });
      });
    } catch (err) {
      console.log(err);
      setError("Failed to update 11 - 20 LB.");
      setTimeout(() => {
        setSuccess("");
        setError("");
      }, 4000);
    }
    setLoading(false);
  };

  var onInputChange2 = function onInputChange2(event) {
    //console.log(event.target.value);
    let { checked, value, name } = event.target;
    //let { email, password} = rates;
    let newRates = { ...rates };
    // console.log(name);
    // console.log(value);
    switch (name) {
      case "1 LB":
        newRates.L1 = value;
        break;
      case "2 LB":
        newRates.L2 = value;
        break;
      case "3 LB":
        newRates.L3 = value;
        break;
      case "4 LB":
        newRates.L4 = value;
        break;
      case "5 LB":
        newRates.L5 = value;
        break;
      case "6 LB":
        newRates.L6 = value;
        break;
      case "7 LB":
        newRates.L7 = value;
        break;
      case "8 LB":
        newRates.L8 = value;
        break;
      case "9 LB":
        newRates.L9 = value;
        break;
      default:
        newRates.L10 = value;
        break;
    }

    setRates((prevState) => {
      // console.log("State in callback2:", prevState);
      return newRates;
    });
  };

  var onInputChange3 = function onInputChange3(event) {
    //console.log(event.target.value);
    let { checked, value, name } = event.target;
    //let { email, password} = rates;
    let newRates = { ...ratesContd };
    // console.log(name);
    // console.log(value);
    switch (name) {
      case "11 LB":
        newRates.L1 = value;
        break;
      case "12 LB":
        newRates.L2 = value;
        break;
      case "13 LB":
        newRates.L3 = value;
        break;
      case "14 LB":
        newRates.L4 = value;
        break;
      case "15 LB":
        newRates.L5 = value;
        break;
      case "16 LB":
        newRates.L6 = value;
        break;
      case "17 LB":
        newRates.L7 = value;
        break;
      case "18 LB":
        newRates.L8 = value;
        break;
      case "19 LB":
        newRates.L9 = value;
        break;
      case "20 LB":
        newRates.L10 = value;
        break;
      default:
        break;
    }

    setRatesContd((prevState) => {
      // console.log("State in callback2:", prevState);
      return newRates;
    });
  };

  var location = history.location;
  var sidebarHeight = "100vh";
  if (location.pathname === "/UpdateProfile") {
    sidebarHeight = "160.5vh";
  }
  //console.log("location is: " + history.location);

  useEffect(() => {
    try {
      // console.log("rates");
      // console.log(Rates);
      if (Rates === undefined) {
        // console.log("im here");
        getRates(value);
      } else {
        if (rates.L1 === "") {
          let newRates = { ...rates };
          newRates.L10 = Rates[0].Cost;
          Rates.map((item) => {
            if (item.Pound !== "10 LB") {
              switch (item.Pound) {
                case "1 LB":
                  newRates.L1 = item.Cost;
                  break;
                case "2 LB":
                  newRates.L2 = item.Cost;
                  break;
                case "3 LB":
                  newRates.L3 = item.Cost;
                  break;
                case "4 LB":
                  newRates.L4 = item.Cost;
                  break;
                case "5 LB":
                  newRates.L5 = item.Cost;
                  break;
                case "6 LB":
                  newRates.L6 = item.Cost;
                  break;
                case "7 LB":
                  newRates.L7 = item.Cost;
                  break;
                case "8 LB":
                  newRates.L8 = item.Cost;
                  break;
                case "9 LB":
                  newRates.L9 = item.Cost;
                  break;
                default:
                  break;
              }
            }
            return null;
          });

          setRates((prevState) => {
            // console.log("State in callback useEffect:", prevState);
            return newRates;
          });
        }
      }

      if (RatesContd === undefined) {
        // console.log("im here2");
        getRatesContd(value);
      } else {
        if (ratesContd.L1 === "") {
          let newRates = { ...ratesContd };
          RatesContd.map((item) => {
            switch (item.Pound) {
              case "11 LB":
                newRates.L1 = item.Cost;
                break;
              case "12 LB":
                newRates.L2 = item.Cost;
                break;
              case "13 LB":
                newRates.L3 = item.Cost;
                break;
              case "14 LB":
                newRates.L4 = item.Cost;
                break;
              case "15 LB":
                newRates.L5 = item.Cost;
                break;
              case "16 LB":
                newRates.L6 = item.Cost;
                break;
              case "17 LB":
                newRates.L7 = item.Cost;
                break;
              case "18 LB":
                newRates.L8 = item.Cost;
                break;
              case "19 LB":
                newRates.L9 = item.Cost;
                break;
              case "20 LB":
                newRates.L10 = item.Cost;
                break;
              default:
                break;
            }

            return null;
          });

          setRatesContd((prevState) => {
            // console.log("State in callback useEffect:", prevState);
            return newRates;
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [Rates, RatesContd]);
  // console.log("rates are: ");
  // console.log(rates);
  return (
    <div
      className={`${classes.root} content-wrapper`}
      style={{ minHeight: "500px" }}
    >
      <section className="content" style={{ height: "151vh" }}>
        <div
          className="container-fluid mobile-card-margin"
          style={{ marginBottom: "5%" }}
        >
          <AppBar position="static">
            <Tabs
              value={tab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="" {...a11yProps(0)} />
              <Tab label="" {...a11yProps(1)} />
              <Tab label="1LB - 10LB" {...a11yProps(2)} />
              <Tab label="11LB - 20LB" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={tab} index={0}>
            <p></p>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <p></p>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <div className="card-body p-0">
              <div className="row">
                <Card className="update-form-style">
                  <form
                    onSubmit={handleSubmit}
                    className="contact-form text-center"
                    id="modal-contact-form-data1"
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="1 LB">
                            <label htmlFor="1 LB" className={classes.label}>
                              1 LB
                            </label>
                            {/* <Feild
                                name="1 LB"
                                placeholder="1 LB"
                                value={rates.L1}
                                onChange={onInputChange2}
                                type="text"
                                required="required"
                              /> */}
                            <input
                              type="text"
                              name="1 LB"
                              placeholder="1 LB"
                              onChange={onInputChange2}
                              value={rates.L1}
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="2 LB">
                            <label htmlFor="2 LB" className={classes.label}>
                              2 LB
                            </label>
                            <Feild
                              name="2 LB"
                              placeholder="2 LB"
                              value={rates.L2}
                              onChange={onInputChange2}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="3 LB">
                            <label htmlFor="3 LB" className={classes.label}>
                              3 LB
                            </label>
                            <Feild
                              name="3 LB"
                              placeholder="3 LB"
                              value={rates.L3}
                              onChange={onInputChange2}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="4 LB">
                            <label htmlFor="4 LB" className={classes.label}>
                              4 LB
                            </label>
                            <Feild
                              name="4 LB"
                              placeholder="4 LB"
                              value={rates.L4}
                              onChange={onInputChange2}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="5 LB">
                            <label htmlFor="5 LB" className={classes.label}>
                              5 LB
                            </label>
                            <Feild
                              name="5 LB"
                              placeholder="5 LB"
                              value={rates.L5}
                              onChange={onInputChange2}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="6 LB">
                            <label htmlFor="6 LB" className={classes.label}>
                              6 LB
                            </label>
                            <Feild
                              name="6 LB"
                              placeholder="6 LB"
                              value={rates.L6}
                              onChange={onInputChange2}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="7 LB">
                            <label htmlFor="7 LB" className={classes.label}>
                              7 LB
                            </label>
                            <Feild
                              name="7 LB"
                              placeholder="7 LB"
                              value={rates.L7}
                              onChange={onInputChange2}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="8 LB">
                            <label htmlFor="8 LB" className={classes.label}>
                              8 LB
                            </label>
                            <Feild
                              name="8 LB"
                              placeholder="8 LB"
                              value={rates.L8}
                              onChange={onInputChange2}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="9 LB">
                            <label htmlFor="9 LB" className={classes.label}>
                              9 LB
                            </label>
                            <Feild
                              name="9 LB"
                              placeholder="9 LB"
                              value={rates.L9}
                              onChange={onInputChange2}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="10 LB">
                            <label className={classes.label}>10 LB</label>
                            <Feild
                              name="10 LB"
                              placeholder="10 LB"
                              value={rates.L10}
                              onChange={onInputChange2}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          {error && <Alert variant="danger">{error}</Alert>}
                          {success && (
                            <Alert variant="success">{success}</Alert>
                          )}
                          <button
                            className="btn btn-large btn-rounded btn-blue btn-hvr-pink modal_contact_btn"
                            id="updateprofile_submit_btn"
                            type="submit"
                          >
                            Update
                            <div className="btn-hvr-setting">
                              <ul className="btn-hvr-setting-inner">
                                <li className="btn-hvr-effect"></li>
                                <li className="btn-hvr-effect"></li>
                                <li className="btn-hvr-effect"></li>
                                <li className="btn-hvr-effect"></li>
                              </ul>
                            </div>
                          </button>
                        </Paper>
                      </Grid>
                    </Grid>
                  </form>
                  <Card.Body></Card.Body>
                </Card>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <div className="card-body p-0">
              <div className="row">
                <Card className="update-form-style">
                  <form
                    onSubmit={handleSubmit2}
                    className="contact-form text-center"
                    id="modal-contact-form-data6"
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="11 LB">
                            <label htmlFor="11 LB" className={classes.label}>
                              11 LB
                            </label>
                            <Feild
                              name="11 LB"
                              placeholder="11 LB"
                              value={ratesContd.L1}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="12 LB">
                            <label htmlFor="12 LB" className={classes.label}>
                              12 LB
                            </label>
                            <Feild
                              name="12 LB"
                              placeholder="12 LB"
                              value={ratesContd.L2}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="13 LB">
                            <label htmlFor="13 LB" className={classes.label}>
                              13 LB
                            </label>
                            <Feild
                              name="13 LB"
                              placeholder="13 LB"
                              value={ratesContd.L3}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="14 LB">
                            <label htmlFor="14 LB" className={classes.label}>
                              4 LB
                            </label>
                            <Feild
                              name="14 LB"
                              placeholder="14 LB"
                              value={ratesContd.L4}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="15 LB">
                            <label htmlFor="15 LB" className={classes.label}>
                              15 LB
                            </label>
                            <Feild
                              name="15 LB"
                              placeholder="15 LB"
                              value={ratesContd.L5}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="16 LB">
                            <label htmlFor="16 LB" className={classes.label}>
                              16 LB
                            </label>
                            <Feild
                              name="16 LB"
                              placeholder="16 LB"
                              value={ratesContd.L6}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="17 LB">
                            <label htmlFor="17 LB" className={classes.label}>
                              17 LB
                            </label>
                            <Feild
                              name="17 LB"
                              placeholder="17 LB"
                              value={ratesContd.L7}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="18 LB">
                            <label htmlFor="18 LB" className={classes.label}>
                              18 LB
                            </label>
                            <Feild
                              name="18 LB"
                              placeholder="18 LB"
                              value={ratesContd.L8}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="19 LB">
                            <label htmlFor="19 LB" className={classes.label}>
                              19 LB
                            </label>
                            <Feild
                              name="19 LB"
                              placeholder="19 LB"
                              value={ratesContd.L9}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper}>
                          <div className="form-group" id="20 LB">
                            <label className={classes.label}>20 LB</label>
                            <Feild
                              name="20 LB"
                              placeholder="20 LB"
                              value={rates.L10}
                              onChange={onInputChange3}
                              type="text"
                              required="required"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          {error && <Alert variant="danger">{error}</Alert>}
                          {success && (
                            <Alert variant="success">{success}</Alert>
                          )}
                          <button
                            className="btn btn-large btn-rounded btn-blue btn-hvr-pink modal_contact_btn"
                            id="updateprofile_submit_btn"
                            type="submit"
                          >
                            Update
                            <div className="btn-hvr-setting">
                              <ul className="btn-hvr-setting-inner">
                                <li className="btn-hvr-effect"></li>
                                <li className="btn-hvr-effect"></li>
                                <li className="btn-hvr-effect"></li>
                                <li className="btn-hvr-effect"></li>
                              </ul>
                            </div>
                          </button>
                        </Paper>
                      </Grid>
                    </Grid>
                  </form>
                  <Card.Body></Card.Body>
                </Card>
              </div>
            </div>
          </TabPanel>
        </div>
      </section>
    </div>
  );
};

export default UpdateRatesScreen;
