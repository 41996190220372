/* eslint-disable jsx-a11y/heading-has-content */
import { useEffect } from 'react';
//import { Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../Context/AuthContext';
import { useHistory } from 'react-router-dom';
//import { Link } from "react-router-dom";


var StaffDashboardScreen = function StaffDashboardScreen(){
    //var [ error, setError ] = useState("");
    var { value }  = useAuth();
    var { loggedIn, userRolef  } = value;
    var history = useHistory();

    
    // eslint-disable-next-line no-lone-blocks
    {/* <Card>
                <Card.Body className="text-center">
                <p>This is the dashboard</p>
                {error && <Alert variant="danger">{error}</Alert> }
                <div><strong>Email: </strong>{currentUser.email}</div>
                <Button className="btn btn-primary" onClick={() => history.push("/UpdateProfile")}>Update Profile</Button>
                </Card.Body>
                <Button variant="link" onClick={handleLogout}>Log Out</Button>
            </Card> */}

    useEffect(function(){
        if(!loggedIn && userRolef !== "Staff"){
            //console.log("Dashboard LoggedIn Value" + loggedIn);
            history.push("/");
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
            {/* Content Wrapper. Contains page content */}
              <div className="content-wrapper" style={{height: "94vh"}}>
                {/* Content Header (Page header) */}
                <div className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0 text-dark"></h1>
                      </div>
                      {/* col */}
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        </ol>
                      </div>{/* col */} 
                    </div>{/* row */}
                  </div>{/* container-fluid */}
                </div>
                {/* content-header */}

                {/* Main content */}
                <section className="content" >
                  <div className="container-fluid">
                      <p>Welcome Mgk Staff</p>
                  </div>{/* <!--/. container-fluid --> */}
                </section>
                {/* <!-- /.content --> */}
              </div>
              {/* <!-- /.content-wrapper --> */}
        </>
    );
};

export default StaffDashboardScreen;