import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon } from "react-share";

export default function SocialShareButtons({ reflink }) {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <FacebookShareButton
          hashtag="MGKEXPRESS"
          quote="Hi, please sign up to MGKEXPRESS the fastest shipping company in the Caribbean using my referral link."
          url={reflink}
        >
          <FacebookIcon
            size={32}
            logofillcolor="white"
            round={true}
          ></FacebookIcon>
        </FacebookShareButton>
        &nbsp;&nbsp;
        <TwitterShareButton
          title="Hi, please sign up to MGKEXPRESS the fastest shipping company in the Caribbean using my referral link."
          url={reflink}
        >
          <TwitterIcon
            size={32}
            logofillcolor="white"
            round={true}
          ></TwitterIcon>
        </TwitterShareButton>
        &nbsp;&nbsp;
        <WhatsappShareButton
          title="Hi, please sign up to MGKEXPRESS the fastest shipping company in the Caribbean using my referral link."
          url={reflink}
        >
          <WhatsappIcon
            size={32}
            logofillcolor="white"
            round={true}
          ></WhatsappIcon>
        </WhatsappShareButton>
        &nbsp;&nbsp;
        <TelegramShareButton
          title="Hi, please sign up to MGKEXPRESS the fastest shipping company in the Caribbean using my referral link."
          url={reflink}
        >
          <TelegramIcon
            size={32}
            logofillcolor="white"
            round={true}
          ></TelegramIcon>
        </TelegramShareButton>
        &nbsp;&nbsp;
        <LinkedinShareButton
          title="MGKEXPRESS Referral"
          summary="Hi, please sign up to MGKEXPRESS the fastest shipping company in the Caribbean using my referral link."
          source={reflink}
          url={reflink}
        >
          <LinkedinIcon
            size={32}
            logofillcolor="white"
            round={true}
          ></LinkedinIcon>
        </LinkedinShareButton>
        &nbsp;&nbsp;
        <EmailShareButton
          url={reflink}
          subject="Mgkexpress Refferal Link"
          body="Hi, please sign up to MGKEXPRESS the fastest shipping company in the Caribbean using my referral link."
        >
          <EmailIcon size={32} logofillcolor="white" round={true}></EmailIcon>
        </EmailShareButton>
      </div>
    </>
  );
}
