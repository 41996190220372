import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAuth } from "../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "inherit",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const RatesTable = () => {
  const classes = useStyles();
  var { value } = useAuth();
  var { AddLB, Rates, RatesContd, getRates, getRatesContd, getAdditionalLB } =
    value;

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    try {
      if (Rates == undefined) {
        getRates(value);
      }

      if (RatesContd == undefined) {
        getRatesContd(value);
      }

      if (AddLB == undefined) {
        getAdditionalLB(value);
      }
    } catch (err) {
      console.log(err);
    }
  });

  return (
    <div className="team-item">
      <h6 style={{ color: "white" }}>
        Obtain your US mailing address when you sign up.
      </h6>
      <br />
      <div className={classes.root}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>1lb - 5lb</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <table
              className="table table-sm"
              style={{
                color: "white",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <thead>
                <tr>
                  <th scope="col">Pound(s)</th>
                  <th scope="col">Cost</th>
                </tr>
              </thead>
              <tbody>
                {Rates !== undefined ? (
                  Rates.slice(0, 6).map((item) => {
                    if (item.Pound !== "10 LB") {
                      return (
                        <tr key={item.Pound}>
                          <td>{item.Pound}</td>
                          <td>{item.Cost}</td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr key="Loading1">
                    <td>Loading</td>
                    <td>Loading</td>
                  </tr>
                )}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>6lb - 10lb</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <table
              className="table table-sm"
              style={{
                color: "white",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <thead>
                <tr>
                  <th scope="col">Pound(s)</th>
                  <th scope="col">Cost</th>
                </tr>
              </thead>
              <tbody>
                {Rates !== undefined ? (
                  Rates.slice(6, 10).map((item) => {
                    if (item.Pound !== "10 LB") {
                      return (
                        <tr key={item.Pound}>
                          <td>{item.Pound}</td>
                          <td>{item.Cost}</td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr key="Loading2">
                    <td>Loading</td>
                    <td>Loading</td>
                  </tr>
                )}
                {Rates !== undefined ? (
                  <tr>
                    <td>{Rates[0].Pound}</td>
                    <td>{Rates[0].Cost}</td>
                  </tr>
                ) : (
                  <tr key="Loading3">
                    <td>Loading</td>
                    <td>Loading</td>
                  </tr>
                )}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>11lb - 15lb</Typography>
          </AccordionSummary>
          <AccordionDetails
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <table
              className="table table-sm"
              style={{
                color: "white",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <thead>
                <tr>
                  <th scope="col">Pound(s)</th>
                  <th scope="col">Cost</th>
                </tr>
              </thead>
              <tbody>
                {RatesContd !== undefined ? (
                  RatesContd.slice(0, 5).map((item) => {
                    if (item.Pound !== "10 LB") {
                      return (
                        <tr key={item.Pound}>
                          <td>{item.Pound}</td>
                          <td>{item.Cost}</td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr key="Loading4">
                    <td>Loading</td>
                    <td>Loading</td>
                  </tr>
                )}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>16lb - 20lb</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <table
              className="table table-sm"
              style={{
                color: "white",
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <thead>
                <tr>
                  <th scope="col">Pound(s)</th>
                  <th scope="col">Cost</th>
                </tr>
              </thead>
              <tbody>
                {RatesContd !== undefined ? (
                  RatesContd.slice(5, 10).map((item) => {
                    if (item.Pound !== "10 LB") {
                      return (
                        <tr key={item.Pound}>
                          <td>{item.Pound}</td>
                          <td>{item.Cost}</td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <p>Loading</p>
                )}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      </div>
      <br />
      <h5 style={{ color: "white" }}>{`Each additional LB is US$${
        AddLB !== undefined ? AddLB[0].Cost : "Loading"
      }`}</h5>
      <h5 style={{ color: "white" }}>
        <b>Customs Duty</b>
        <br />
        Customs Duty charges may apply to packages valued at more than $100.
      </h5>
      <h5 style={{ color: "white" }}>
        NB: The Importer is responsible for becoming informed with the most
        recent Customs Regulations in relation to the rules, procedures, import
        requirements, levies, and taxes that may be applicable to packages being
        imported into Jamaica.
      </h5>
      {/* <h5 style={{ color: "white" }}>
        NB: Original 1LB Rate is $750, Promotional 1LB Rate from June 1, 2023 -
        September 1, 2023 is $550.
      </h5> */}
      {/* <h5 style={{ color: "white" }}>
                    Each package is subject to a processing fee of $200
                  </h5> */}
      {/* <h5 style={{ color: "white" }}>
                  Sea Freight: Contact Us For a Quote
                </h5> */}
      <style>
        {`
              .MuiPaper-root {
                background-color: inherit;
                color: white;
              }  

              .MuiAccordionSummary-expandIcon {
                color: white;
              }
            `}
      </style>
    </div>
  );
};

export default RatesTable;
