/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
//import PackageStatus from "../components/PackageStatus";
import { MDBDataTableV5, MDBDataTable } from "mdbreact";
import CustomDatatable from "../components/CustomDatatable";

const columns = [
  {
    name: "MailBox_Num",
    label: "MailBox #",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Name",
    label: "Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Address",
    label: "Address",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Contact_Number",
    label: "Contact #",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "rPoints",
    label: "Ref Points",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export default function ConsistentUsersScreen() {
  var { value } = useAuth();
  var { currentUser, fetchShippersInLastSixMonths, consistentusers } = value;

  useEffect(function () {
    //console.log("Whats inside users")
    //console.log(users);
    if (currentUser !== null) {
      if (consistentusers === null || consistentusers === undefined) {
        try {
          fetchShippersInLastSixMonths(value);
        } catch {
          //console.log("unable to fetch address");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="content-wrapper "
      style={{ backgroundColor: "#f4f6f9", minHeight: "500px", height: "94vh" }}
    >
      <section className="content">
        <div className="container-fluid container-margin-vpackages">
          <h2 className="text-center mb-4" style={{ color: "#007bff" }}>
            Users For Last 6 Months
          </h2>
          <CustomDatatable
            users={consistentusers}
            columns={columns}
            isManageStaff={false}
          />
        </div>
      </section>
    </div>
  );
}
