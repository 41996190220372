//import { object } from 'firebase-functions/lib/providers/storage';
//old
//import firebase from "firebase/compat/app";
//import "firebase/compat/auth";
//import "firebase/compat/firestore";
//import "firebase/compat/analytics";
import 'firebase/compat/functions';
//import "firebase/compat/app-check";
//v9
import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  updateDoc,
  query,
  onSnapshot,
  where,
  deleteDoc,
} from 'firebase/firestore';
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { FacebookAuthProvider } from 'firebase/auth';
import { TwitterAuthProvider } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

require('dotenv').config();

const app = initializeApp({
  databaseURL: process.env.REACT_APP_database_url,
  apiKey: process.env.REACT_APP_api_key,
  authDomain: process.env.REACT_APP_auth_domain,
  projectId: process.env.REACT_APP_project_id,
  storageBucket: process.env.REACT_APP_storage_bucket,
  messagingSenderId: process.env.REACT_APP_messaging_sender_id,
  appId: process.env.REACT_APP_app_id,
  measurementId: process.env.REACT_APP_measurement_id,
});

//console.log(process.env.REACT_APP_AppCheck);
//process.env.REACT_APP_AppCheck === undefined
//? ""
//: process.env.REACT_APP_AppCheck
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_AppCheck),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

// Initialize Performance Monitoring and get a reference to the service
getPerformance(app);
const analyticsMock = {
  logEvent: () => {},
  setCurrentScreen: () => {},
  setUserId: () => {},
};

export const auth = getAuth(app);
export const socialAuth = auth;
export const googleAuthProvider = new GoogleAuthProvider();
export const SignInWithPopup = signInWithPopup;
export const SignInWithEmailAndPassword = signInWithEmailAndPassword;
export const SignOut = signOut;
export const SendPasswordResetEmail = sendPasswordResetEmail;
export const CreateUserWithEmailAndPassword = createUserWithEmailAndPassword;
export const facebookAuthProvider = new FacebookAuthProvider();
export const twitterAuthProvider = new TwitterAuthProvider();
export const db = getFirestore(app);
export const Collection = collection;
export const GetDocs = getDocs;
export const GetDoc = getDoc;
export const SetDoc = setDoc;
export const AddDoc = addDoc;
export const UpdateDoc = updateDoc;
export const Doc = doc;
export const Query = query;
export const Where = where;
export const DeleteDoc = deleteDoc;
export const OnSnapshot = onSnapshot;
let ana = !isSupported ? analyticsMock : getAnalytics(app);
export const analytics = ana;
export const timeStamp = Timestamp;
export const functionss = getFunctions();
//export default app;
