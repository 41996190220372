import RatesTable from "../components/RatesTable";

export default function RatesScreen() {
  return (
    <>
      <div
        data-testid="rates-screen"
        className="content-wrapper gradient-bg1"
        style={{ backgroundColor: "#f4f6f9" }}
      >
        {/* Counters Start */}
        <section className="" id="portfolio">
          <div
            className="container"
            style={{ textAlign: "center", paddingBottom: "10%" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div
                  className="heading-area mx-570 mb-3"
                  style={{ textAlign: "center" }}
                >
                  <h2 className="title" style={{ color: "white" }}>
                    Our Rates
                  </h2>
                </div>
              </div>
            </div>
            {/* Row */}
            <div className="row wow fadeInUp">
              <div className="col-md-12">
                <RatesTable />
              </div>
            </div>
          </div>
          {/* Counters End */}
        </section>
      </div>
    </>
  );
}
