/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Alert } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";
import { useHistory } from "react-router-dom";
import Feild from "../components/Feild";

//Import Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

var initialValues = {
  email: "",
  password: "",
  passwordconf: "",
  contact: "",
  fullname: "",
};

var SignUp = function SignUp() {
  var { value } = useAuth();
  var { signup, gLogin, fLogin, tLogin } = value;
  var { fetchUserInfoForSignUp, fetchUserInfo } = value;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  var history = useHistory();

  var [state, setState] = useState(initialValues);

  var handleSubmit = async function handleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside fuction");
    if (state.password !== state.passwordconf) {
      return setError("Passwords do not match");
    }
    try {
      setError("");
      setLoading(true);
      await signup(state, value).then(async function (res1) {
        //console.log(res1);
        if (res1 != null) {
          if (
            res1 !== "The email address is already in use by another account."
          ) {
            await fetchUserDetailsSignUp(res1).then(function (res) {
              if (res) {
                //console.log("About to close signup modal.");
                closeModal();
                //setLoggedIn(true);
                //console.log("About to navigate to dashboard.");
                //console.log(userRolef);
                history.push("/Dashboard");
              } else {
                setError("Unable to signup at this time");
              }
            });
          } else {
            setError("The email address is already in use by another account.");
          }
        } else {
          setError("Unable to signup at this time.");
        }
      });
    } catch {
      setError("Failed to sign up");
    }
    setLoading(false);
  };

  var onInputChange2 = function onInputChange2(event) {
    //console.log(event.target.value);
    let { checked, value, name } = event.target;
    //let { email, password} = state;
    if (name === "Email") {
      setState({ ...state, [name.toLowerCase()]: value });
    } else if (name === "Terms") {
      setState({ ...state, [name.toLowerCase()]: checked });
    } else {
      setState({ ...state, [name.toLowerCase()]: value });
    }
  };

  var handleGoogleSubmit = async function handleGoogleSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside Google Submit fuction");
    try {
      setError("");
      setLoading(true);
      await gLogin(value).then(async function (res1) {
        if (res1 != null) {
          await fetchUserDetails(res1).then(function (res) {
            if (res) {
              //console.log("About to close signup modal.");
              setLoading(false);
              closeModal();
              //setLoggedIn(true);
              //console.log("About to navigate to dashboard.");
              history.push("/Dashboard");
            } else {
              setError("Unable to signup at this time");
              setLoading(false);
            }
          });
        } else {
          setError("Unable to signup at this time.");
          setLoading(false);
        }
      });
    } catch {
      setError("Failed to signup");
      setLoading(false);
    }
  };

  var handleFacebookSubmit = async function handleFacebookSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside Facebook Submit fuction");
    try {
      setError("");
      setLoading(true);
      await fLogin(value).then(async function (res1) {
        if (res1 != null) {
          await fetchUserDetails(res1).then(function (res) {
            if (res) {
              //console.log("About to close signup modal.");
              closeModal();
              //setLoggedIn(true);
              //console.log("About to navigate to dashboard.");
              history.push("/Dashboard");
            } else {
              setError("Unable to signup at this time");
            }
          });
        } else {
          setError("Unable to signup at this time.");
        }
      });
    } catch {
      setError("Failed to signup");
    }
    setLoading(false);
  };

  var handleTwitterSubmit = async function handleTwitterSubmit(event) {
    event.preventDefault();
    //prevents default form refresh
    //console.log("I am inside Twitter Submit fuction");
    try {
      setError("");
      setLoading(true);
      await tLogin(value).then(async function (res1) {
        if (res1 != null) {
          await fetchUserDetails(res1).then(function (res) {
            if (res) {
              //console.log("About to close signup modal.");
              closeModal();
              //setLoggedIn(true);
              //console.log("About to navigate to dashboard.");
              history.push("/Dashboard");
            } else {
              setError("Unable to signup at this time");
            }
          });
        } else {
          setError("Unable to signup at this time.");
        }
      });
    } catch {
      setError("Failed to signup");
    }
    setLoading(false);
  };

  var switchModal = function switchModal() {
    try {
      //console.log("switchModdal")
      window.$("#SignUpFormModal").modal("hide");
      window.$("body").removeClass("modal-open");
      window.$(".modal-backdrop").remove();
      window.$("#LoginFormModal").modal("show");
    } catch {
      //console.log("failed to switch to signup modal");
    }
  };

  var closeModal = function closeModal() {
    // try{

    // }catch {
    //     console.log("failed to switch to signup modal");
    // }
    //console.log("switchModdal")
    window.$("#SignUpFormModal").modal("hide");
    window.$("body").removeClass("modal-open");
    window.$(".modal-backdrop").remove();
  };

  var fetchUserDetailsSignUp = async function fetchUserDetailsSignUp(payload) {
    //console.log("Is current user null");
    //console.log(value);
    if (payload.currentUser !== null && payload.currentUser !== undefined) {
      if (
        payload.currentUser.uid !== null &&
        payload.currentUser.uid !== undefined
      ) {
        //console.log("Fetching user info");
        //console.log(state);
        await fetchUserInfoForSignUp(payload.currentUser.uid, payload, state);
        return true;
      }
    }
    return false;
  };

  var fetchUserDetails = async function fetchUserDetails(payload) {
    //console.log("Is current user null");
    //console.log(value);
    if (payload.currentUser !== null && payload.currentUser !== undefined) {
      if (
        payload.currentUser.uid !== null &&
        payload.currentUser.uid !== undefined
      ) {
        //console.log("Fetching user info");
        //console.log(state);
        return await fetchUserInfo(payload.currentUser.uid, payload, null);
      }
    }
    return false;
  };

  const handleChange = function handleChange(event) {
    setshowPassword(!showPassword);
  };

  //console.log(currentUser);
  //console.log(state);

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <form
        onSubmit={handleSubmit}
        className="contact-form text-center"
        id="modal-contact-form-data3"
      >
        <div className="form-group" id="email">
          <Feild
            name="email"
            placeholder="Email"
            value={state.email}
            onChange={onInputChange2}
            type="email"
            required="required"
          />
        </div>
        <div className="form-group" id="fullname">
          <Feild
            name="fullname"
            placeholder="Full Name"
            value={state.fullname}
            onChange={onInputChange2}
            type="text"
            required="required"
          />
        </div>
        <div className="form-group" id="contact">
          <Feild
            name="contact"
            placeholder="876-123-1234"
            value={state.contact}
            onChange={onInputChange2}
            type="text"
            required="required"
          />
        </div>
        <div className="form-group" id="password">
          <Feild
            name="password"
            placeholder="Password"
            value={state.password}
            autoComplete="off"
            onChange={onInputChange2}
            type={showPassword ? "text" : "password"}
            required="required"
          />
        </div>
        <div className="form-group" id="password-confirm">
          <Feild
            name="passwordconf"
            placeholder="Password Confirm"
            value={state.passwordconf}
            autoComplete="off"
            onChange={onInputChange2}
            type={showPassword ? "text" : "password"}
            required="required"
          />
        </div>
        <div
          className="form-check"
          style={{ textAlign: "initial" }}
          id="showpassword"
        >
          <input
            type="checkbox"
            className="form-check-input login-check"
            id="exampleCheck1"
            value={showPassword}
            onClick={(e) => handleChange(e)}
          />
          <label className="form-check-label ml-2" htmlFor="exampleCheck1">
            Click the box to show password
          </label>
        </div>
        <p className="w-100 text-center mt-2 form-link">
          Already have an account? <a onClick={switchModal}>Login</a>
        </p>
        <button
          disabled={loading}
          className="btn btn-large btn-rounded btn-blue btn-hvr-pink modal_contact_btn"
          id="signup_submit_btn"
          type="submit"
        >
          Sign Up
          <div className="btn-hvr-setting">
            <ul className="btn-hvr-setting-inner">
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
              <li className="btn-hvr-effect"></li>
            </ul>
          </div>
        </button>
        <p className="w-100 text-center mt-2">---Social Sign Up---</p>
        <p className="w-100 text-center mt-2">
          <FontAwesomeIcon
            icon={faGoogle}
            style={styles.gIcon}
            onClick={handleGoogleSubmit}
          />
          <FontAwesomeIcon
            icon={faFacebook}
            style={styles.fIcon}
            onClick={handleFacebookSubmit}
          />
          <FontAwesomeIcon
            icon={faTwitter}
            style={styles.tIcon}
            onClick={handleTwitterSubmit}
          />
        </p>
      </form>
    </>
  );
};

var styles = {
  gIcon: {
    color: "#dd4b39",
    cursor: "pointer",
    fontSize: "20px",
  },
  fIcon: {
    color: "#4267B2",
    cursor: "pointer",
    fontSize: "20px",
    marginRight: "5%",
    marginLeft: "5%",
  },
  tIcon: {
    color: "#1DA1F2",
    cursor: "pointer",
    fontSize: "20px",
  },
};
export default SignUp;
