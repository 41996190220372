import PackageCardDisplay from "../components/PackageCardDisplay";
import { useAuth } from "../Context/AuthContext";

export default function ReadyCardDisplay() {
  var { value } = useAuth();
  var { readyPack } = value;

  return (
    <>
      {/* <PackageCardDisplay color="dash-card" text="Ready" number={readyPack === undefined? "Loading" : readyPack} icon="fas fa-check-circle" />  */}
      <PackageCardDisplay
        color="#007bff"
        text="Ready"
        number={readyPack === undefined ? "Loading" : readyPack}
        icon="fas fa-check-circle"
        centering="ReadyCardbox-content"
      />
    </>
  );
}
